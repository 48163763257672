import { Box, Button, Flex, Icon, ListItem, Text, UnorderedList, useColorMode } from "@chakra-ui/react"
import { LeccionInt } from "../../../../interfaces/CursosInt";
import { ComponenteLeccionEnum, PreviewTipoEnum } from "../../../../shared/utils/types/LeccionTipoEnum";
import { EntregablesInt } from "../../../../interfaces/Entregables";
import { PDFViewer } from "../PreviewItems/PDFViewer";
import { Video } from "../PreviewItems/Video";
import { IframeViewer } from "../PreviewItems/IframeViewer";
import { ImageViewer } from "../PreviewItems/ImageViewer";
import { MarkdownViewer } from "../PreviewItems/Markdown";
import { EntregableEstadoEnum, TipoEntregaEnum } from "../../../../shared/utils/types/EntregableEstadoEnum";
import { PiExam, PiSparkleBold } from "react-icons/pi";
import { useState } from "react";
import { ResolverAutocorregible } from "./components/ResolverAutocorregible";
import { motion, AnimatePresence } from "framer-motion";

interface Props {
    leccion: LeccionInt | undefined;
    entregable: EntregablesInt | null;
    realizarCorreccion: (respuesta: string) => Promise<void>;
    componentSubtype: ComponenteLeccionEnum | undefined;
    loading: boolean;
}

export const AutocorregibleRealizado = ({
    leccion,
    entregable,
    realizarCorreccion,
    componentSubtype,
    loading
}: Props) => {
    const { colorMode } = useColorMode();
    const [nuevoIntento, setNuevoIntento] = useState<boolean>(false);

    const parseFeedbackToArray = (input: string) => {
        const feedbackArray = input
            .slice(1, -1)
            .split(/","/)
            .map((item) => item.replace(/^"/, "").replace(/"$/, "").trim());

        return feedbackArray;
    };

    return (
        <AnimatePresence mode="wait">
            <Flex
                as={motion.div}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 } as any}
                p="40px"
                border="1px solid"
                borderColor={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
                direction="column"
                rounded="20px"
                gap="25px"
                bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                justifyContent="center"
            >
                <Flex
                    direction="column"
                    mb="6px"
                    gap="5px"
                >
                    <Text
                        color="font"
                        fontSize="21px"
                        fontStyle="normal"
                        fontWeight="700"
                    >
                        {leccion?.nombre}
                    </Text>

                    <Flex
                        gap="5px"
                        alignItems="center"
                    >
                        <Icon
                            as={PiSparkleBold}
                            color="white"
                            boxSize="18px"
                        />

                        <Text
                            color="white"
                            fontSize="15px"
                            fontWeight="400"
                        >
                            Autocorregible
                        </Text>
                    </Flex>
                </Flex>

                <Box
                    h="1px"
                    bg={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
                    w="100%"
                />

                {leccion?.contenido &&
                    <>
                        <Flex
                            w="100%"
                        >
                            {(componentSubtype === ComponenteLeccionEnum.PDF_VIEWER && leccion?.contenido) &&
                                <PDFViewer
                                    leccion={leccion}
                                    tipo={PreviewTipoEnum.ENTREGA}
                                />
                            }

                            {(componentSubtype === ComponenteLeccionEnum.VIDEO && leccion?.contenido) &&
                                <Video
                                    leccion={leccion}
                                    tipo={PreviewTipoEnum.ENTREGA}
                                />
                            }

                            {(componentSubtype === ComponenteLeccionEnum.IFRAME && leccion?.contenido) &&
                                <IframeViewer
                                    leccion={leccion}
                                    tipo={PreviewTipoEnum.ENTREGA}
                                />
                            }

                            {(componentSubtype === ComponenteLeccionEnum.IMAGEN && leccion?.contenido) &&
                                <ImageViewer
                                    leccion={leccion}
                                    tipo={PreviewTipoEnum.ENTREGA}
                                />
                            }

                            {(componentSubtype === ComponenteLeccionEnum.MARKDOWN_PREVIEW && leccion?.contenido) &&
                                <MarkdownViewer
                                    leccion={leccion}
                                    tipo={PreviewTipoEnum.ENTREGA}
                                />
                            }
                        </Flex>

                        <Box
                            h="1px"
                            bg={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
                            w="100%"
                        />
                    </>
                }

                {!nuevoIntento ? (
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.3 }}
                    >
                        <Flex
                            display={entregable?.correccion ? "flex" : "none"}
                            direction="column"
                            gap="30px"
                            borderRadius="15px"
                        >
                            <Flex
                                direction="column"
                            >
                                <Text
                                    fontSize="14px"
                                    fontWeight="400"
                                    color="font"
                                    mb="20px"
                                >
                                    Corrección
                                </Text>

                                <UnorderedList
                                    spacing={3}
                                    fontSize="16px"
                                    p="0"
                                >
                                    {parseFeedbackToArray(entregable?.correccion?.texto || "")?.map((evaluacion: string, index: number) => (
                                        <Flex
                                            key={index}
                                            borderRadius="4px"
                                            border="1px solid var(--Neutral-Colors-Gray-09, #27272A)"
                                            bg="var(--Neutral-Colors-Gray-10, #18181B)"
                                            p="16px"
                                            fontSize="14px"
                                        >
                                            {evaluacion}
                                        </Flex>
                                    ))}
                                </UnorderedList>
                            </Flex>

                            <Flex
                                direction="row"
                                gap="80px"
                                align="center"
                                px="25px"
                                borderRadius="10px"
                                mx="auto"
                                position="relative"
                                my="30px"
                            >
                                <Box
                                    position="absolute"
                                    borderRadius="86px"
                                    opacity={0.1}
                                    background="linear-gradient(90deg, #C082FF 0%, #7967FF 100%)"
                                    filter="blur(75px)"
                                    width="376px"
                                    height="195px"
                                    zIndex={0}
                                />

                                <Flex
                                    direction="column"
                                    gap="20px"
                                    alignItems="center"
                                    position="relative"
                                    zIndex={1}
                                >
                                    <Text
                                        fontSize="16px"
                                        color="font"
                                        fontWeight="400"
                                    >
                                        Estado
                                    </Text>

                                    <Text
                                        px="20px"
                                        py="10px"
                                        rounded="8px"
                                        color={
                                            entregable?.estado === EntregableEstadoEnum.PENDIENTE_CORRECCION ? "warning" :
                                                entregable?.estado === EntregableEstadoEnum.ERROR ? "#FD395B" :
                                                    entregable?.estado === EntregableEstadoEnum.CORRECTO ? "#11FEE5" : "#515561"
                                        }
                                        fontSize="18px"
                                        fontWeight="600"
                                    >
                                        {
                                            entregable?.estado === EntregableEstadoEnum.PENDIENTE_CORRECCION ? "Pendiente de Corrección" :
                                                entregable?.estado === EntregableEstadoEnum.ERROR ? "Suspendido" :
                                                    entregable?.estado === EntregableEstadoEnum.CORRECTO ? "Superado" : "---"
                                        }
                                    </Text>
                                </Flex>

                                <Flex
                                    direction="column"
                                    align="center"
                                    gap="20px"
                                    position="relative"
                                    zIndex={1}
                                >
                                    <Text
                                        fontSize="16px"
                                        color="font"
                                        fontWeight="400"
                                    >
                                        Puntuación obtenida
                                    </Text>

                                    <Text
                                        textAlign="center"
                                        w="fit-content"
                                        rounded="full"
                                        bg="#19191F"
                                        px="50px"
                                        py="10px"
                                        fontSize="24px"
                                        fontWeight="700"
                                        color={
                                            (entregable?.correccion?.puntuacion ?? 0) < 75 
                                                ? "#FD395B" 
                                                : "#11FEE5"
                                        }
                                        position="relative"
                                        _before={{
                                            content: '""',
                                            position: "absolute",
                                            top: "-1px",
                                            left: "-1px",
                                            right: "-1px",
                                            bottom: "-1px",
                                            borderRadius: "full",
                                            padding: "1px",
                                            background: 
                                                (entregable?.correccion?.puntuacion ?? 0) < 75
                                                    ? "linear-gradient(90deg, #FD395B, #7967FF)"
                                                    : "linear-gradient(90deg, #11FEE5, #7967FF)",
                                            WebkitMask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                                            WebkitMaskComposite: "xor",
                                            maskComposite: "exclude",
                                        }}
                                    >
                                        {entregable?.correccion?.puntuacion}%
                                    </Text>
                                </Flex>
                            </Flex>
                        </Flex>

                        <Flex
                            w="100%"
                            justifyContent="center"
                            display={entregable?.estado === EntregableEstadoEnum.ERROR ? "flex" : "none"}
                        >
                            <Button
                                py="15px"
                                px="24px"
                                mt="20px"
                                borderRadius="10px"
                                h="fit-content"
                                w="fit-content"
                                bg={colorMode === "dark" ? "main" : "secondary"}
                                color="purewhite"
                                fontSize="16px"
                                fontWeight="500"
                                _disabled={{ bg: "#A5A8B3", cursor: "not-allowed" }}
                                onClick={async (e: React.MouseEvent) => {
                                    e.stopPropagation();

                                    setNuevoIntento(true);
                                }}
                                bgGradient="linear(90deg, #A12DFF 0%, #7967FF 100%)"
                            >
                                Volver a intentar
                            </Button>
                        </Flex>
                    </motion.div>
                ) : (
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.3 }}
                    >
                        <ResolverAutocorregible
                            tipo={TipoEntregaEnum.REENTREGA}
                            leccion={leccion}
                            loading={loading}
                            realizarCorreccion={realizarCorreccion}
                            setNuevoIntento={setNuevoIntento}
                        />
                    </motion.div>
                )}
            </Flex>
        </AnimatePresence>
    )
}