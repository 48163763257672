import { Button, Flex, Image, Text, useBreakpointValue, useColorMode } from "@chakra-ui/react";
import { CursosInt, LeccionInt } from "../../../../interfaces/CursosInt";
import { ProgressBar } from "../../../../shared/components/Progress/ProgressBar";
import { MatriculasInt } from "../../../../interfaces/MatriculasInt";
import { EndpointTypes } from "../../../../shared/utils/types/EndpointTypes";
import { useDataId } from "../../../../shared/hooks/useDataId";
import { useData } from "../../../../shared/hooks/useData";
import ImagePlaceholder from "../../../../assets/img/BgCarrusel.png";
import MarkdownPreview from '@uiw/react-markdown-preview';
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { onContinueLeccion } from "../../../../shared/utils/functions/validateProgress";
import { truncate } from "lodash";

interface Props {
    curso: CursosInt;
    matricula: MatriculasInt;
}

export const CarruselCard = ({
    curso,
    matricula,
}: Props) => {
    const { colorMode } = useColorMode();
    const navigate = useNavigate()
    const isMobile = useBreakpointValue({ base: true, sm: false });

    const { data: progreso } = useData({
        endpoint: EndpointTypes.PROGRESOS,
        query: {
            curso: matricula?.grupo?.cursoId!,
            matricula: matricula?.id!
        },
        ignoreRequest: !matricula
    })

    const { data: infoCurso } = useDataId({
        endpoint: EndpointTypes.CURSOS,
        id: matricula?.grupo?.curso?.slug,
        ignoreRequest: !matricula
    })

    const modulo = onContinueLeccion(progreso?.data[0]?.progresoLecciones, infoCurso)?.modulo;
    const leccion = modulo?.lecciones?.find((leccion: LeccionInt) =>
        leccion.slug === onContinueLeccion(progreso?.data[0]?.progresoLecciones, infoCurso).leccion?.slug
    );

    const totalProgreso = (progreso?.data[0]?.leccionesCompletadas / infoCurso?.meta?.totalLecciones) * 100

    return (
        <Flex
            p={{ base: '20px', sm: "40px" }}
            bg="black"
            position="relative"
            _before={{
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: isMobile ? "linear-gradient(to top,rgba(0, 0, 0, 1) 10%,rgba(0, 0, 0, 0.9) 20%,rgba(0, 0, 0, 0.7) 35%,rgba(0, 0, 0, 0.5) 50%,rgba(0, 0, 0, 0.3) 65%,rgba(0, 0, 0, 0.1) 80%,rgba(0, 0, 0, 0) 100%)" : "linear-gradient(90deg, rgba(0,0,0,1) 20%, rgba(0,0,0,0.7) 30%, rgba(0,0,0,0) 60%)",
                zIndex: 1,
                rounded: "14px",
            }}
            maxH="342px"
            rounded="16px"
            w="100%"
        >
            <Flex
                justify='center'
                direction="column"
                px={{base: "20px", sm: "20px"}}
                gap="8px"
                w={{base: "100%", sm: "463px"}}
                position="relative"
                zIndex="2"
                rounded="16px"
            >
                <Text
                    fontSize="24px"
                    fontWeight="400"
                    color={"purewhite"}
                    whiteSpace="nowrap"
                >
                    {curso?.nombre}
                </Text>

                <Flex
                    direction={{base:'column', sm:'row'}}
                    justify='space-between' 
                    align={{base:'initial' ,sm: 'center'}}
                    gap='10px'
                >
                    <Text
                        fontSize="12px"
                        whiteSpace="nowrap"
                        color={"purewhite"}
                    >
                        {curso?.duracion} horas
                    </Text>

                    <ProgressBar
                        progress={parseInt((totalProgreso || 0)?.toFixed(0))}
                        bg={colorMode === "dark" ? "rgba(255, 255, 255, 0.1)" : "rgba(255, 255, 255, 0.4)"}
                        bgProgress="main"
                        height="6px"
                    />
                </Flex>

                <MarkdownPreview
                    source={curso?.descripcion ?
                        curso?.descripcion?.includes("strong")
                            ? truncate(curso?.descripcion, { length: 260 })
                            : truncate(curso?.descripcion, { length: isMobile ? 180 : 210 })
                        : ""
                    }
                    style={{
                        fontSize: '16px',
                        fontWeight: "400",
                        color: "#fff",
                        lineHeight: "140%",
                        width: '100%',
                        height: '300px',
                        backgroundColor: "transparent",
                        marginTop: "10px"
                    }}
                    wrapperElement={{
                        "data-color-mode": colorMode === "dark" ? "dark" : "light"
                    }}
                />

                <Button
                    opacity={(!leccion || !modulo) ? 0 : 1}
                    fontSize="16px"
                    p='16px'
                    fontWeight="500"
                    color="purewhite"
                    bg="main"
                    _hover={{ bg: "main" }}
                    mt="20px"
                    rounded="10px"
                    cursor={(!leccion || !modulo) ? "default" : "pointer"}
                    onClick={() => {
                        if (!leccion || !modulo) return;
                        navigate(`/aprendizaje/${matricula?.grupo?.curso?.slug}/lecciones/${leccion?.slug}?grupo=${matricula?.grupo?.slug}`)
                    }}
                >
                    Continuar Curso
                </Button>
            </Flex>

            <Flex
                position="absolute"
                top="0"
                right="0"
                w="100%"
                h="100%"
            >
                <Image
                    src={curso?.imagen?.url ? curso?.imagen?.url : ImagePlaceholder}
                    alt={curso?.nombre}
                    w="full"
                    h="full"
                    objectFit="cover"
                    rounded="16px"
                />
            </Flex>
        </Flex>
    )
}
