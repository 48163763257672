import { Flex, Skeleton } from "@chakra-ui/react"

interface Props {
    totalCards?: number
}

export const ExamenesSekeleton = ({
    totalCards = 8
}: Props) => {
    const total = Array.from({ length: totalCards }, (_, i) => i)

    return (
        <Flex
            px={{base: "10px", sm:"20px"}}
            pb="40px"
            gap={{base: "50px", sm: "30px"}}
            flexWrap="wrap"
            h="fit-content"
        >
            {total?.map((item: number) => (
                <Skeleton
                    key={item}
                    w={{base: "100%", sm: "520px"}}
                    h="240px"
                    borderRadius="10px"
                />
            ))}
        </Flex>
    )
}