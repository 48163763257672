import { Flex, Icon, Image, Text, useColorMode, useDisclosure, useBreakpointValue } from '@chakra-ui/react'
import { BsClock } from 'react-icons/bs';
import avatarDefault from "../../../assets/img/avatarDefault.png"
import { format } from '../../../shared/utils/functions/formatDate';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../../../shared/context/user.context';
import { ConversationChatMobile } from './ConversationChatMobile';

interface Props {
    conversacion: any;
}

export default function ChatSelectorItem({ conversacion }: Props) {
    const { user } = useAuthContext()
    const { colorMode } = useColorMode()
    const params = useParams()
    const navigate = useNavigate()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const openDrawerChat = useBreakpointValue({ base: true, md: false });

    const openChat = () => {
        if (!params["*"] || params["*"] !== conversacion?.id || (openDrawerChat && params["*"] === conversacion?.id)) {
            navigate(`/mensajeria/${conversacion?.id}`);
            if (openDrawerChat) {
                onOpen();
            }
        }
    }

    return (
        <Flex
            p="24px"
            align="start"
            borderTop="1px solid"
            borderBottom="1px solid"
            borderColor={colorMode === "dark" ? "border_variant" : "border"}
            gap="12px"
            cursor="pointer"
            onClick={openChat}
            bg={params["*"] && params["*"] === conversacion?.id ? colorMode === "dark" ? "rgba(234, 236, 255, 0.1)" : "#EAECFF" : ""}
            borderLeft="2px solid"
            borderLeftColor={params["*"] && params["*"] === conversacion?.id ? "main" : "transparent"}
            transition="all 0.3s"
            _hover={{
                background: colorMode === "dark" ? "rgba(234, 236, 255, 0.1)" : "#EAECFF"
            }}
            h="130px"
            w="full"
        >
            <Flex
                w="32px"
                minW="32px"
                h="32px"
            >
                <Image
                    objectFit="cover"
                    src={
                        user?.id === conversacion?.receptorId
                            ? conversacion?.emisor?.avatar?.url || avatarDefault
                            : user?.id === conversacion?.emisorId
                                ? conversacion?.receptor?.avatar?.url || avatarDefault
                                : avatarDefault
                    }
                    rounded="5px"
                />
            </Flex>

            <Flex
                direction="column"
                gap="5px"
            >
                <Text
                    fontSize="14px"
                    fontWeight="400"
                    color="font"
                >
                    {
                        user?.id === conversacion?.receptorId
                            ? conversacion?.emisor?.nombre + " " + conversacion?.emisor?.apellidos
                            : user?.id === conversacion?.emisorId
                                ? conversacion?.receptor?.nombre + " " + conversacion?.receptor?.apellidos
                                : null
                    }
                </Text>

                <Text
                    fontSize="14px"
                    fontWeight="400"
                    color="font_grey"
                    w='full'
                    noOfLines={1}
                >
                    {conversacion?.asunto}
                </Text>

                <Flex
                    gap="8px"
                    align="center"
                    mt="12px"
                >
                    <Icon
                        as={BsClock}
                        boxSize="14px"
                        color="font_grey"
                    />
                    <Text
                        fontSize="11px"
                        fontWeight="400"
                        color="font_grey"
                    >
                        {
                            format({
                                date: conversacion?.updatedAt,
                                customFormat: "dd MMM yyyy"
                            })
                        }
                    </Text>
                </Flex>

            </Flex>
            
            <ConversationChatMobile
                isOpen={isOpen}
                onClose={onClose}
                id={conversacion?.id}
            />
        </Flex>
    )
}
