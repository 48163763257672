import { Box, Button, Flex, Icon, ListItem, Text, Textarea, UnorderedList, useColorMode, useToast, Spinner } from "@chakra-ui/react"
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { TipoEntregaEnum } from "../../../../../shared/utils/types/EntregableEstadoEnum";
import { LeccionInt } from "../../../../../interfaces/CursosInt";
import { CodeiumEditor } from "@codeium/react-code-editor";
import { FaCss3 } from "react-icons/fa";
import { FaHtml5 } from "react-icons/fa";
import { FaPython } from "react-icons/fa";
import { FaJs } from "react-icons/fa";
import { PiSparkleBold } from "react-icons/pi";
import { motion, AnimatePresence } from "framer-motion";

export enum LenguajesEnum {
    JavaScript = "javascript",
    Python = "python",
    HTML = "html",
    CSS = "css",
}

interface Props {
    tipo: TipoEntregaEnum
    leccion: LeccionInt | undefined;
    loading: boolean;
    realizarCorreccion: (respuesta: string) => Promise<void>;
    setNuevoIntento?: Dispatch<SetStateAction<boolean>>;
}

export const ResolverAutocorregible = ({
    tipo,
    leccion,
    loading,
    realizarCorreccion,
    setNuevoIntento = () => { }
}: Props) => {
    const { colorMode } = useColorMode();
    const [lenguaje, setLenguaje] = useState<LenguajesEnum>(LenguajesEnum.JavaScript);
    const [respuesta, setRespuesta] = useState<string>(
        leccion?.AI === "texto" ? "" : "console.log('Hello, world!');"
    );
    const [correccion, setCorreccion] = useState<{
        evaluacion: string[],
        puntuacion: number,
    }>();

    return (
        <AnimatePresence mode="wait">
            <Flex
                direction="column"
                maxW="calc(100vw - 565px)"
                minH="300px"
                alignItems="center"
                as={motion.div}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 } as any}
            >
                {loading ? (
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.3 }}
                        style={{
                            height: "500px",
                        }}
                    >
                        <Flex
                            direction="column"
                            align="center"
                            justify="center"
                            h="1000px"
                            w="100%"
                            position="absolute"
                            top="0"
                            left="0"
                            zIndex="overlay"
                            mt="130px"
                        >
                            <Box
                                position="absolute"
                                width="300px"
                                height="300px"
                                background="radial-gradient(circle, rgba(122, 103, 255, 0.15) 0%, transparent 50%)"
                                filter="blur(30px)"
                                opacity="0.1"
                            />

                            <Box
                                position="absolute"
                                width="176px"
                                height="195px"
                                borderRadius="86px"
                                opacity="0.4"
                                background="linear-gradient(90deg, #C082FF 0%, #7967FF 100%)"
                                filter="blur(75px)"
                                ml="340px"
                            />

                            <Flex
                                direction="column"
                                align="center"
                                gap="25px"
                                ml="340px"
                            >
                                <Spinner
                                    thickness="3px"
                                    speed="0.8s"
                                    emptyColor="whiteAlpha.100"
                                    color="#7967FF"
                                    size="xl"
                                    width="50px"
                                    height="50px"
                                />
                                <Text
                                    color="white"
                                    fontSize="16px"
                                    fontWeight="400"
                                >
                                    Analizando tu solución... Esto llevará solo unos segundos ✨
                                </Text>
                            </Flex>
                        </Flex>
                    </motion.div>
                ) : (
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.3 }}
                        style={{
                            width: "100%",
                        }}
                    >
                        <Flex
                            direction="column"
                            fontSize="13px"
                            fontWeight="400"
                            color="font"
                            gap="10px"
                            mr="auto"
                        >
                            <Text
                                display={tipo === TipoEntregaEnum.ENUNCIADO ? "flex" : "none"}
                                fontSize="14px"
                                fontWeight="500"
                                color="font"
                            >
                                Entrega del ejercicio:
                            </Text>

                            <Text
                                display={tipo === TipoEntregaEnum.REENTREGA ? "block" : "none"}
                                fontSize="14px"
                                fontWeight="500"
                                color="font"
                            >
                                Nueva entrega:
                            </Text>
                        </Flex>

                        <Flex
                            direction="column"
                            maxW="calc(100vw - 565px)"
                            w="full"
                        >
                            {leccion?.AI === "codigo" &&
                                <Flex
                                    w="105%"
                                    direction="column"
                                >
                                    <Flex
                                        bg="#222222"
                                        mt="20px"
                                    >
                                        {Object.values(LenguajesEnum).map((lang) => (
                                            <Button
                                                key={lang}
                                                onClick={() => setLenguaje(lang)}
                                                bg={lenguaje === lang ? "#303030" : "transparent"}
                                                color={lenguaje === lang ? "purewhite" : "#D1D1D1"}
                                                h="fit-content"
                                                w="fit-content"
                                                p="10px 16px"
                                                rounded="0"
                                                fontSize="14px"
                                                fontWeight="500"
                                                leftIcon={
                                                    <Icon
                                                        as={lang === LenguajesEnum.JavaScript ? FaJs : lang === LenguajesEnum.Python ? FaPython : lang === LenguajesEnum.HTML ? FaHtml5 : lang === LenguajesEnum.CSS ? FaCss3 : FaJs}
                                                        color={lenguaje === lang ? (lang === LenguajesEnum.JavaScript ? "yellow.500" : lang === LenguajesEnum.Python ? "green.500" : lang === LenguajesEnum.HTML ? "orange.500" : lang === LenguajesEnum.CSS ? "blue.500" : "yellow.500") : "#D1D1D1"}
                                                    />
                                                }
                                            >
                                                {lang}
                                            </Button>
                                        ))}
                                    </Flex>

                                    <CodeiumEditor
                                        language={lenguaje}
                                        theme="vs-dark"
                                        onChange={(code: string | undefined) => setRespuesta(code || "")}
                                        value={respuesta}
                                        containerStyle={{
                                            paddingTop: "20px",
                                            backgroundColor: "#1E1E1E",
                                        }}
                                    />
                                </Flex>
                            }

                            {leccion?.AI === "texto" &&
                                <Textarea
                                    value={respuesta}
                                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setRespuesta(e.target.value)}
                                    minHeight="300px"
                                    resize="none"
                                    mt="20px"
                                />
                            }

                            <Flex
                                display={correccion ? "flex" : "none"}
                                direction="column"
                                gap="30px"
                                mt="40px"
                                p="30px"
                                borderRadius="15px"
                            >
                                <Box>
                                    <Text
                                        fontSize="24px"
                                        fontWeight="600"
                                        color="secondary"
                                        mb="20px"
                                    >
                                        Corrección
                                    </Text>

                                    <UnorderedList
                                        spacing={3}
                                        fontSize="16px"
                                        pl="20px"
                                    >
                                        {correccion?.evaluacion?.map((evaluacion: string, index: number) => (
                                            <ListItem key={index}>
                                                {evaluacion}
                                            </ListItem>
                                        ))}
                                    </UnorderedList>
                                </Box>

                                <Flex
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    bg="white"
                                    p="25px"
                                    borderRadius="10px"
                                >
                                    <Text
                                        fontSize="20px"
                                        fontWeight="600"
                                        color="secondary"
                                        mb="10px"
                                    >
                                        Puntuación obtenida
                                    </Text>

                                    <Text
                                        fontSize="36px"
                                        fontWeight="700"
                                        color={correccion?.puntuacion && correccion?.puntuacion < 75 ? "red.500" : "green.500"}
                                    >
                                        {correccion?.puntuacion}%
                                    </Text>
                                </Flex>
                            </Flex>
                        </Flex>

                        <Flex
                            w="100%"
                            justifyContent="center"
                            mt="20px"
                            ml="50px"
                        >
                            <Box
                                width="299px"
                                height="86px"
                                borderRadius="86px"
                                opacity="0.2"
                                background="linear-gradient(90deg, #C082FF 0%, #7967FF 100%)"
                                filter="blur(35px)"
                                mr="-280px"
                            />
                            <Button
                                py="15px"
                                px="24px"
                                h="fit-content"
                                w="fit-content"
                                mt="30px"
                                bgGradient="linear(90deg, #A12DFF 0%, #7967FF 100%)"
                                borderRadius="10px"
                                color="white"
                                fontSize="16px"
                                fontWeight="500"
                                isLoading={loading}
                                transition="all 0.2s ease"
                                _hover={{
                                    boxShadow: "0 4px 15px rgba(121, 103, 255, 0.3)",
                                }}
                                _disabled={{ bg: "#A5A8B3", cursor: "not-allowed" }}
                                onClick={async (e: React.MouseEvent) => {
                                    e.stopPropagation();
                                    await realizarCorreccion(respuesta);
                                    setNuevoIntento(false);
                                }}
                                leftIcon={<Icon as={PiSparkleBold} color="white" />}
                            >
                                Corrección Inteligente
                            </Button>
                        </Flex>
                    </motion.div>
                )}
            </Flex>
        </AnimatePresence>
    )
}
