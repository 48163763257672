import { Box, Button, Flex, Icon, Text, useColorMode } from "@chakra-ui/react";
import { LeccionInt } from "../../../../interfaces/CursosInt";
import { useOutWindowContext } from "../../../../shared/context/outWindow.context";
import { useEffect } from "react";
import { LeccionTipoEnum } from "../../../../shared/utils/types/LeccionTipoEnum";
import { capitalizeFirst } from "../../../../shared/utils/functions/textTransform";
import { HiOutlineLink } from "react-icons/hi";

interface Props {
    leccion: LeccionInt | null;
}

export const UrlViewer = ({ leccion }: Props) => {
    const { colorMode } = useColorMode();
    const { outWindow } = useOutWindowContext();

    const exitFullScreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    };

    useEffect(() => {
        if (outWindow)
            exitFullScreen();
    }, [outWindow])

    const isValidUrl = (string: string) => {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    };

    const hostname = leccion?.contenido && isValidUrl(leccion.contenido)
        ? new URL(leccion.contenido).hostname
        : '';

    return (
        <Flex
            h="calc(100vh - 193px)"
            w="100%"
        >
            <Flex
                p={{base: "20px", sm: "40px"}}
                border="1px solid"
                borderColor={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
                direction="column"
                rounded="20px"
                gap="40px"
                bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                h="fit-content"
                w="100%"
            >
                <Flex
                    direction="column"
                >
                    <Text
                        color="font"
                        fontSize="24px"
                        fontStyle="normal"
                        fontWeight="700"
                    >
                        {leccion?.nombre}
                    </Text>

                    <Flex
                        gap="10px"
                        alignItems="center"
                    >
                        <Icon
                            as={HiOutlineLink}
                            color="#A5A8B3"
                            boxSize="20px"
                        />

                        <Text
                            color="#A5A8B3"
                            fontSize="15px"
                            fontWeight="400"
                            lineHeight="160%"
                            letterSpacing="0.075px"
                        >
                            {leccion?.tipo === LeccionTipoEnum?.URL ? "Recurso" : "Lección"}
                        </Text>
                    </Flex>
                </Flex>

                <Flex
                    direction="column"
                    gap="20px"
                >
                    <Text
                        fontSize="15px"
                        fontWeight="400"
                        color="font"
                        lineHeight="140%"
                    >
                        Aquí encontrarás los recursos necesarios para las lecciones o el curso. Por favor, haz clic en el botón de abajo para descargarlos.
                    </Text>

                    <Flex
                        direction="column"
                    >
                        <Text
                            fontSize="14px"
                            fontWeight="700"
                            color="font"
                        >
                            {hostname ? capitalizeFirst(hostname) : 'URL no válida'}
                        </Text>

                        <Text
                            fontSize="14px"
                            fontWeight="400"
                            color="blue.500"
                            textDecoration="underline"
                            wordBreak="break-word"
                        >
                            {leccion?.contenido}
                        </Text>
                    </Flex>

                    <Button
                        px="45px"
                        py="15px"
                        h="fit-content"
                        w="fit-content"
                        gap="10px"
                        rounded="5px"
                        fontSize="14px"
                        fontWeight={"500"}
                        color="purewhite"
                        bg={colorMode === "dark" ? "transparent" : "secondary"}
                        border={colorMode === "dark" ? "1px solid" : "none"}
                        borderColor={colorMode === "dark" ? "purewhite" : undefined}
                        _hover={{ bg: colorMode === "dark" ? "main_variant" : "main_variant" }}
                        isDisabled={!leccion?.contenido || leccion?.contenido === "" || !isValidUrl(leccion.contenido)}
                        onClick={() => {
                            if (leccion?.contenido && isValidUrl(leccion.contenido))
                                window.open(leccion.contenido, "_blank")
                        }}
                    >
                        Ir al recurso
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    );
};

