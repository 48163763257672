import { Flex, Text, useColorMode } from "@chakra-ui/react";
import MarkdownPreview from '@uiw/react-markdown-preview';
import { truncate } from "lodash";
import { useNavigate } from "react-router-dom";
import { DateTime } from 'luxon';

interface Props {
    pregunta: any;
}

export default function CardPregunta({ pregunta }: Props) {
    const { colorMode } = useColorMode()
    const navigate = useNavigate();

    const handleClick = (preguntaId: string | undefined) => {
        if (preguntaId) {
            navigate(`/foro/${preguntaId}`);
        }
    }

    return (
        <Flex
            bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
            p="20px"
            rounded="10px"
            border="1px solid"
            borderColor={colorMode === "dark" ? "border_variant" : "border"}
            direction="column"
            gap="20px"
            maxW={{base: "full", sm:"385px"}}
            transition="all 0.5s"
            _hover={{
                transform: "scale(1.02)",
            }}
            cursor="pointer"
            onClick={() => handleClick(pregunta?.id)}
        >
            <Flex
                direction="column"
                gap="10px"
            >
                <Text
                    fontSize="18px"
                    fontWeight="600"
                    color="font"
                >
                    {pregunta?.titulo}
                </Text>

                <MarkdownPreview
                    source={truncate(pregunta?.contenido, { length: 40 })}
                    style={{
                        fontSize: "15px",
                        fontWeight: "400",
                        color: colorMode === "dark" ? "#FFFFFF" : "#515561",
                        lineHeight: "140%",
                        width: '100%',
                        maxWidth: '100%',
                        backgroundColor: "transparent"
                    }}
                    wrapperElement={{ "data-color-mode": colorMode === "dark" ? "dark" : "light" }}
                />

            </Flex>

            <Flex
                direction="column"
                gap="20px"
                mt="auto"
            >
                <Flex
                    gap="5px"
                >
                    <Text
                        fontSize="12px"
                        fontWeight="700"
                        color={colorMode === "dark" ? "#FFFFFF" : "main"}
                        bg={colorMode === "dark" ? "main" : "main_variant"}
                        p="6px 12px"
                        rounded="5px"
                        w="fit-content"
                    >
                        {pregunta?.tema?.grupo?.curso?.nombre}
                    </Text>
                </Flex>

                <Flex
                    w="full"
                    justifyContent="space-between"
                >
                    <Text
                        fontSize="12px"
                        color="font_grey"
                    >
                        Creada por {pregunta?.user?.nombre}
                    </Text>

                    <Text
                        fontSize="12px"
                        color="font_grey"
                    >
                        {DateTime.fromISO(pregunta?.createdAt!).toRelative({ locale: 'es' })}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    )
}
