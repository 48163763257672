import { Drawer, DrawerBody, DrawerContent, DrawerOverlay, Icon, useDisclosure, Flex, DrawerHeader, useColorMode } from "@chakra-ui/react"
import { BiSearch } from "react-icons/bi";
import { IoCloseSharp } from "react-icons/io5";
import { CustomSearch } from "../../Customs/CustomSearch";

function DrawerCustomSearch() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { colorMode } = useColorMode()

    return (
        <Flex
            display={{ base: 'flex', sm: 'none' }}
        >
            <Icon
                boxSize="24px"
                color="font"
                onClick={(e: React.MouseEvent) => {
                    e.stopPropagation()
                    onOpen()
                }}
                cursor="pointer"
                as={BiSearch}
                _active={{ transform: "scale(0.9)" }}
            />

            <Drawer
                placement={'top'}
                onClose={onClose}
                isOpen={isOpen}
                size={'full'}
            >
                <DrawerOverlay />
                <DrawerContent 
                    bg="transparent"
                >
                    <DrawerHeader 
                        bg={colorMode === "dark" ? "#2d3748" : "purewhite"}
                        px="20px"
                    >
                        <Flex
                            gap="10px"
                            align='center'
                            justify='center'
                            mt='10px'
                        >
                            <Icon
                                onClick={onClose}
                                boxSize="24px"
                                color="font"
                                cursor="pointer"
                                _hover={{ color: "dark_grey" }}
                                as={IoCloseSharp}
                            />
                            <CustomSearch
                                placeholder={"Busca en todo el contenido de tus cursos y foro"}
                            />
                        </Flex>
                    </DrawerHeader>
                    <DrawerBody bg="transparent">
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Flex>
    )
}

export default DrawerCustomSearch