import {
  Flex,
  Icon,
  Text,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import { BiChevronDown, BiFile } from "react-icons/bi";
import { BsDownload } from "react-icons/bs";
import { format } from "../../../../../shared/utils/functions/formatDate";
import { EntregablesInt } from "../../../../../interfaces/Entregables";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { HistorialEntregas } from "./HistorialEntregas";

interface Props {
  entregable: EntregablesInt | null;
  entregables: EntregablesInt[];
}

export const EntregaCorrecto = ({ entregable, entregables }: Props) => {
  const { colorMode } = useColorMode();
  const [detailsOpen, setDetailsOpen] = useState<boolean>(
    entregable?.correccion
      ? entregable?.correccion?.puntuacion < 100
        ? false
        : true
      : true
  );

  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Flex direction="column" w="100%" gap="10px">
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontSize="18px" fontWeight="700" color="font">
          Entrega realizada:
        </Text>

        <Icon
          as={BiChevronDown}
          boxSize="30px"
          color="font"
          cursor="pointer"
          transform={detailsOpen ? "rotate(180deg)" : "rotate(0deg)"}
          transition="transform 0.3s ease"
          _active={{ transform: "scale(0.9)" }}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();

            setDetailsOpen((prev: boolean) => !prev);
          }}
        />
      </Flex>

      <AnimatePresence>
        {detailsOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{
              height: { duration: 0.4, ease: "easeInOut" },
              opacity: { duration: 0.25, ease: "easeInOut" },
            }}
            style={{ overflow: "hidden" }}
          >
            <Flex direction="column" gap="25px">
              <Flex
                direction="column"
                fontSize="13px"
                fontWeight="400"
                color="font"
                gap="10px"
              >
                <Text>
                  ¡Felicidades! Has aprobado este ejercicio con éxito. Tu
                  calificación se encuentra entre 75 y 100 puntos, lo que indica
                  un rendimiento satisfactorio o excelente.
                </Text>

                <Text>
                  Puedes descargar y revisar el archivo que has entregado
                  haciendo clic en el botón de descarga. La fecha de entrega se
                  muestra debajo del archivo.
                </Text>

                <Text>
                  Si tu calificación no alcanzo los 100 puntos y deseas
                  mejorarla, tienes la opción de realizar una nueva entrega para
                  aumentar tu puntuación. Recuerda que tu mejor calificación
                  será la que se registre finalmente.
                </Text>
              </Flex>

              <Flex
                display={{ base: "none", sm: "flex" }}
                direction="column"
                p="15px"
                bg={colorMode === "dark" ? "bg_dark_light" : "#F8F8F9"}
                rounded="15px"
              >
                <Flex
                  w="100%"
                  rounded="15px"
                  position="relative"
                  direction="column"
                  gap="10px"
                >
                  <Flex
                    h="80px"
                    p="20px"
                    border="1px solid"
                    borderColor={
                      colorMode === "dark" ? "border_variant" : "#E6E6EA"
                    }
                    rounded="10px"
                    justifyContent="space-between"
                  >
                    <Flex alignItems="center" gap="10px">
                      <Icon
                        as={BiFile}
                        boxSize="24px"
                        color={colorMode === "dark" ? "purewhite" : "secondary"}
                      />

                      <Flex direction="column" gap="4px">
                        <Text
                          fontSize="14px"
                          fontWeight="600"
                          lineHeight="140%"
                        >
                          {entregable?.adjunto?.name}
                        </Text>

                        <Text
                          color="#8C909C"
                          fontSize="13px"
                          fontWeight="500"
                          lineHeight="16px"
                          letterSpacing={"-0.78px"}
                        >
                          {entregable?.adjunto?.size} KB
                        </Text>
                      </Flex>
                    </Flex>

                    <Flex
                      p="6px"
                      justifyContent="center"
                      alignItems="center"
                      w="fit-content"
                      h="fit-content"
                      bg="purewhite"
                      rounded="8px"
                      cursor="pointer"
                      _active={{ transform: "scale(0.9)" }}
                      onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();

                        if (entregable?.adjunto?.url) {
                          window.open(entregable.adjunto.url, "_blank");
                        }
                      }}
                    >
                      <Icon as={BsDownload} color="#8C909C" boxSize="24px" />
                    </Flex>
                  </Flex>

                  <Flex direction="column">
                    <Text
                      fontSize="12px"
                      fontWeight="400"
                      lineHeight="160%"
                      color="#8C909C"
                    >
                      Fecha de entrega:{" "}
                      <Text as="span" fontWeight="700" color="font">
                        {format({
                          date: entregable?.fechaEntrega,
                          customFormat: "dd/MM/yyyy",
                        })}
                      </Text>
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>

            <Flex
              mt="25px"
              justifyContent="end"
              display={entregables && entregables?.length > 0 ? "flex" : "none"}
            >
              <Text
                w="fit-content"
                rounded="8px"
                py="5px"
                px="15px"
                bg="main"
                fontSize="13px"
                fontWeight="400"
                color="purewhite"
                cursor="pointer"
                onClick={onOpen}
              >
                Ver historial de entregas
              </Text>
            </Flex>
          </motion.div>
        )}
      </AnimatePresence>

      <HistorialEntregas
        entregables={entregables}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Flex>
  );
};
