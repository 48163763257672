import { Box, Flex, Icon, Text, useColorMode } from "@chakra-ui/react"
import { HiOutlineClipboard } from "react-icons/hi"
import { LeccionInt } from "../../../../interfaces/CursosInt"
import { ComponenteLeccionEnum, PreviewTipoEnum } from "../../../../shared/utils/types/LeccionTipoEnum";
import { Dispatch, SetStateAction } from "react";
import { PDFViewer } from "../PreviewItems/PDFViewer";
import { Video } from "../PreviewItems/Video";
import { IframeViewer } from "../PreviewItems/IframeViewer";
import { ImageViewer } from "../PreviewItems/ImageViewer";
import { MarkdownViewer } from "../PreviewItems/Markdown";
import { TipoEntregaEnum } from "../../../../shared/utils/types/EntregableEstadoEnum";
import { PiSparkleBold } from "react-icons/pi";
import { ResolverAutocorregible } from "./components/ResolverAutocorregible";

interface Props {
    leccion: LeccionInt | undefined;
    realizarCorreccion: (respuesta: string) => Promise<void>;
    componentSubtype: ComponenteLeccionEnum | undefined;
    loading: boolean;
}

export const AutocorregibleEnunciado = ({
    leccion,
    realizarCorreccion,
    componentSubtype,
    loading
}: Props) => {
    const { colorMode } = useColorMode();

    return (
        <Flex
            p="40px"
            border="1px solid"
            borderColor={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
            direction="column"
            rounded="20px"
            gap="25px"
            bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
            justifyContent="center"
        >
            <Flex
                direction="column"
                mb="6px"
                gap="10px"
            >
                <Text
                    color="font"
                    fontSize="21px"
                    fontStyle="normal"
                    fontWeight="700"
                >
                    {leccion?.nombre}
                </Text>

                <Flex
                    gap="5px"
                    alignItems="center"
                >
                    <Icon
                        as={PiSparkleBold}
                        color="white"
                        boxSize="18px"
                    />

                    <Text
                        color="white"
                        fontSize="15px"
                        fontWeight="400"
                    >
                        Autocorregible
                    </Text>
                </Flex>
            </Flex>

            <Box h="1px" bg="#E6E6EA" w="100%" />

            {leccion?.contenido &&
                <>
                    <Flex
                        w="100%"
                    >
                        {(componentSubtype === ComponenteLeccionEnum.PDF_VIEWER && leccion?.contenido) &&
                            <PDFViewer
                                leccion={leccion}
                                tipo={PreviewTipoEnum.ENTREGA}
                            />
                        }

                        {(componentSubtype === ComponenteLeccionEnum.VIDEO && leccion?.contenido) &&
                            <Video
                                leccion={leccion}
                                tipo={PreviewTipoEnum.ENTREGA}
                            />
                        }

                        {(componentSubtype === ComponenteLeccionEnum.IFRAME && leccion?.contenido) &&
                            <IframeViewer
                                leccion={leccion}
                                tipo={PreviewTipoEnum.ENTREGA}
                            />
                        }

                        {(componentSubtype === ComponenteLeccionEnum.IMAGEN && leccion?.contenido) &&
                            <ImageViewer
                                leccion={leccion}
                                tipo={PreviewTipoEnum.ENTREGA}
                            />
                        }

                        {(componentSubtype === ComponenteLeccionEnum.MARKDOWN_PREVIEW && leccion?.contenido) &&
                            <MarkdownViewer
                                leccion={leccion}
                                tipo={PreviewTipoEnum.ENTREGA}
                            />
                        }
                    </Flex>

                    <Box h="1px" bg="#E6E6EA" w="100%" />
                </>
            }

            <ResolverAutocorregible
                tipo={TipoEntregaEnum.ENUNCIADO}
                leccion={leccion}
                loading={loading}
                realizarCorreccion={realizarCorreccion}
            />
        </Flex>
    )
}