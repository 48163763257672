import { Button, Flex, Icon, Spinner, Text, useBreakpointValue, useColorMode, useToast } from '@chakra-ui/react'
import { BiDownload, BiMenu } from 'react-icons/bi'
import { HiOutlineClock } from 'react-icons/hi'
import { CursosInt } from '../../../../interfaces/CursosInt'
import MarkdownPreview from '@uiw/react-markdown-preview';
import { MatriculasInt } from '../../../../interfaces/MatriculasInt';
import { handleErrors } from '../../../../shared/utils/functions/handleErrors';
import { toastNotify } from '../../../../shared/utils/functions/toastNotify';
import { StatusEnumTypes } from '../../../../shared/utils/types/StatusEnumTypes';
import { getGuiaDidactica } from '../../../../shared/middlewares/users.middleware';
import { UserRolEnum } from '../../../../shared/utils/types/UserRolEnum';

interface Props {
    curso: CursosInt;
    matricula: MatriculasInt | undefined;
}

export default function InfoCurso({ curso, matricula }: Props) {
    const toast = useToast();
    const { colorMode } = useColorMode();
    const handleGuiaDidactica = () => {
        if (!matricula?.id) return;

        toastNotify(
            toast,
            StatusEnumTypes.INFO,
            <Flex
                alignItems="center"
                gap="15px"
            >
                <Text
                    fontWeight="500"
                >
                    Generando Guía Didáctica
                </Text>

                <Spinner />
            </Flex>
        )

        getGuiaDidactica(matricula?.id)
            .then((response: any) => {
                toast.closeAll()

                toastNotify(
                    toast,
                    StatusEnumTypes.SUCCESS,
                    <Flex
                        alignItems="center"
                        gap="15px"
                    >
                        <Text
                            fontWeight="500"
                        >
                            Guía Didáctica generada correctamente
                        </Text>

                        <Button
                            h="fit-content"
                            w="fit-content"
                            p="5px 15px"
                            fontSize="13px"
                            fontWeight="500px"
                            border="1px solid"
                            borderColor="purewhite"
                            color="purewhite"
                            leftIcon={<Icon as={BiDownload} boxSize="20px" />}
                            bg="inherit"
                            rounded="5px"
                            _hover={{ bg: "inherit" }}
                            _active={{ transform: "scale(0.9)" }}
                            onClick={() => {
                                window.open(response?.data?.fichero, '_blank')
                                toast.closeAll()
                            }}
                        >
                            Descargar
                        </Button>
                    </Flex>
                )
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))

                toast.closeAll()
            })
    }

    return (
        <Flex
            direction="column"
            gap="25px"
        >
            <Flex
                direction="column"
                gap="10px"
            >
                <Text
                    fontSize="14px"
                    fontWeight="600"
                    color="font"
                >
                    CURSO
                </Text>

                <Text
                    fontSize="24px"
                    fontWeight="700"
                    color="font"
                >
                    {curso?.nombre}
                </Text>

                <Flex
                    gap="12px"
                    align="center"
                >
                    <Flex
                        gap="5px"
                        align="center"
                    >
                        <Icon
                            as={BiMenu}
                            boxSize="20px"
                            color="font"
                        />

                        <Text
                            fontSize="14px"
                            fontWeight="400"
                            color="font_grey"
                        >
                            {curso?.modulos?.length} módulos
                        </Text>
                    </Flex>

                    <Flex
                        gap="5px"
                        align="center"
                    >
                        <Icon
                            as={HiOutlineClock}
                            boxSize="20px"
                            color="font"
                        />

                        <Text
                            fontSize="14px"
                            fontWeight="400"
                            color="font_grey"
                        >
                            {curso?.duracion} h
                        </Text>
                    </Flex>
                </Flex>
            </Flex>

            <MarkdownPreview
                source={curso?.descripcion || ''}
                style={{
                    overflow: 'visible',
                    textOverflow: 'unset',
                    display: 'block',
                    WebkitLineClamp: 'unset',
                    WebkitBoxOrient: 'unset',
                    fontSize: '15px',
                    fontWeight: "400",
                    color: colorMode === "dark" ? "#FFF" : "#121625",
                    lineHeight: "140%",
                    width: '100%',
                    maxWidth: '100%',
                    backgroundColor: "transparent"
                }}
                wrapperElement={{ "data-color-mode": "light" }}
            />

            <Flex
                gap="25px"
                direction={{base:'column', sm: 'initial'}}
                display={{base:'none', md: 'flex'}}
            >
                {curso?.tutorizado &&
                    <Button
                        bg="main"
                        color="purewhite"
                        fontSize="14px"
                        rightIcon={
                            <Icon
                                as={BiDownload}
                                boxSize="20px"
                                color="purewhite"
                            />
                        }
                        onClick={() => {
                            if (matricula?.tutor?.cv?.url) {
                                window.open(matricula?.tutor?.cv?.url, '_blank');
                                toast.closeAll();
                            } else {
                                toastNotify(toast, StatusEnumTypes.ERROR, "El CV del tutor no está disponible.");
                            }
                        }}
                    >
                        Descargar CV del tutor
                    </Button>
                }

                <Button
                    bg="main"
                    color="purewhite"
                    fontSize="14px"
                    rightIcon={
                        <Icon
                            as={BiDownload}
                            boxSize="20px"
                            color="purewhite"
                        />
                    }
                    onClick={handleGuiaDidactica}
                >
                    Ver guía didáctica
                </Button>
            </Flex>
        </Flex >
    )
}
