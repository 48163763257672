import { Button, Drawer, DrawerBody, DrawerContent, DrawerOverlay, Flex, Text, Skeleton, useColorMode, useToast, Icon } from "@chakra-ui/react"
import React, { useEffect, useRef, useState } from "react"
import { createMessage, readMessage } from "../../../shared/middlewares/messages.middleware";
import { useDataId } from "../../../shared/hooks/useDataId";
import { EndpointTypes } from "../../../shared/utils/types/EndpointTypes";
import { useAuthContext } from "../../../shared/context/user.context";
import { EstadoMensaje } from "../../../shared/utils/types/MensajeriaEnum";
import { UserRolEnum } from "../../../shared/utils/types/UserRolEnum";
import { toastNotify } from "../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../shared/utils/types/StatusEnumTypes";
import ChatConversationItem from "./ChatConversationItem";
import { MensajesInt } from "../../../interfaces/MessagesInt";
import { Formik, Form as FormikForm } from "formik";
import { FormTextEditor } from "../../../shared/components/FormElements/FormTextEditor";
import { BiSend } from "react-icons/bi";

interface Props {
  onClose: () => void;
  isOpen: boolean
  id: string
}

function ConversationChatMobile({ onClose, isOpen, id }: Props) {
  const { colorMode } = useColorMode()
  const toast = useToast();
  const { user } = useAuthContext();
  const [loading, setLoading] = useState<boolean>(false)
  const btnRef = React.useRef<HTMLButtonElement | null>(null)

  const { data: conversacion, Refresh: refreshMensaje } = useDataId({
    endpoint: EndpointTypes.MENSAJERIA_CONVERSACION,
    id: id!,
  });

  const { data: matricula, Refresh: refreshMatricula } = useDataId({
    endpoint: EndpointTypes.MATRICULAS,
    id: conversacion?.matriculaId,
  });

  const initialValues = {
    texto: null
  };

  const submitForm = async (values: { texto: string | null }) => {
    setLoading(true)

    const receptor = conversacion?.receptorId !== user?.id ? conversacion?.receptorId : conversacion?.emisorId;

    const newMessage = {
      conversacionId: conversacion?.id,
      emisorId: user?.id,
      receptorId: receptor,
      texto: values?.texto,
      estado: EstadoMensaje.NO_LEIDO
    }

    createMessage(newMessage)
      .then(() => {
        user?.role?.nombre === UserRolEnum.TUTOR && onReadMessage()
        refreshMensaje();
      })
      .catch(() => toastNotify(toast, StatusEnumTypes.ERROR, "Error al enviar mensaje"))
      .finally(() => setLoading(false))

  };

  const onReadMessage = () => {
    conversacion?.mensajes?.filter((mensaje: any) => mensaje?.receptorId === user?.id).map(async (mensaje: any) => {
      if (mensaje?.estado === EstadoMensaje.NO_LEIDO) {
        await readMessage(mensaje?.id).catch((error) => console.log(error))
      }
    })
  }

  useEffect(() => {
    UserRolEnum.ALUMNO === user?.role?.nombre &&
      onReadMessage()
    refreshMensaje()
  }, [])

  const mensajesEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    setTimeout(() => {
      mensajesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  useEffect(() => {
    if (conversacion?.mensajes) {
      scrollToBottom();
    }
  }, [conversacion?.mensajes]);

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
        size="full"
      >
        <DrawerOverlay />
        <DrawerContent
          bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
        >

          <DrawerBody
            px='0px'
            py='0px'
          >
            <Flex
              direction="column"
              bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
              borderLeft="0"
              w="100%"
              h="100%"
            >
              <Button
                variant='outline'
                ml="10px"
                mt="10px"
                width="80px"
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation()
                  onClose()
                }}
              >
                Volver
              </Button>
              <Flex
                direction="column"
                gap="7px"
                p={{ base: "10px", md: "24px" }}
                borderBottom="1px solid"
                borderBottomColor={colorMode === "dark" ? "border_variant" : "border"}
              >
                {!conversacion?.mensajes ?
                  <Skeleton
                    w="200px"
                    h="55px"
                    rounded="8px"
                  />
                  :
                  <>
                    <Text
                      fontSize="20px"
                      fontWeight="600"
                      color="font"
                    >
                      {conversacion?.asunto}
                    </Text>

                    <Flex
                      justify="space-between"
                    >
                      <Text
                        fontSize="12px"
                        fontWeight="400"
                        color="font"
                      >
                        {conversacion?.leccion?.nombre}
                      </Text>

                      <Text
                        fontSize="12px"
                        fontWeight="400"
                        color="font"
                      >
                        {matricula?.grupo?.curso?.nombre}
                      </Text>
                    </Flex>
                  </>
                }

              </Flex>

              <Flex
                direction="column"
                overflowY="auto"
              >
                {!conversacion?.mensajes ?
                  Array.from({ length: 4 }, (_, i) => i).map((_, index: number) => (
                    <Skeleton
                      key={index}
                      h="120px"
                      w="full"
                      mb="5px"
                    />
                  ))
                  :
                  conversacion?.mensajes?.map((mensaje: MensajesInt, index: number) => {
                    return (
                      <ChatConversationItem
                        key={index}
                        mensaje={mensaje}
                      />
                    );
                  })
                }
                <div ref={mensajesEndRef} />
              </Flex>

              <Flex
                p={{ base: "10px", md: "24px" }}
                direction="column"
                mt="auto"
                w="100%"
              >
                <Formik
                  onSubmit={(values, { resetForm }) => {
                    submitForm(values);
                    resetForm();
                  }}
                  initialValues={initialValues}
                >
                  {(formik) => {
                    const { handleSubmit } = formik;

                    return (
                      <Flex
                        direction="column"
                        align="start"
                        w="full"
                      >
                        <FormikForm
                          onSubmit={handleSubmit}
                          style={{
                            width: "100%"
                          }}
                        >
                          <FormTextEditor
                            height="200px"
                            name='texto'
                            onChange={formik.setFieldValue}
                            placeholder="Escribe una respuesta"
                            styles={{
                              border: "1px solid",
                              borderRadius: "8px",
                              padding: "0",
                              fontSize: "15px",
                              fontWeight: "400",
                              lineHeight: "150%",
                              minHeight: "200px",
                              borderColor: colorMode === "dark" ? "#576776" : "#E2E8F0",
                              color: colorMode === "dark" ? "#FFFFFF" : "#000000",
                              backgroundColor: colorMode === "dark" ? "bg_dark_light" : "purewhite",
                            }}
                          />

                          <Flex
                            w="100%"
                            alignItems="center"
                            justifyContent="flex-end"
                            gap="10px"
                          >

                            <Button
                              bg="main"
                              color="purewhite"
                              fontSize="16px"
                              fontWeight="700"
                              rightIcon={<Icon as={BiSend} />}
                              p="12px 16px"
                              w="fit-content"
                              type='submit'
                              isLoading={loading}
                            >
                              Enviar Mensaje
                            </Button>
                          </Flex>
                        </FormikForm>
                      </Flex>
                    );
                  }}
                </Formik>
              </Flex>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export { ConversationChatMobile }