import { Button, Flex, Icon, Input, InputGroup, InputLeftElement, Text, Box, useColorMode, VStack, useBreakpointValue } from '@chakra-ui/react'
import { BiChevronDown, BiFilter, BiSearch, BiSortDown } from 'react-icons/bi'
import { Dispatch, SetStateAction, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

interface Props {
    query: {
        sortBy?: string;
        order?: string;
        titulo?: string;
    };
    setQuery: Dispatch<SetStateAction<{
        sortBy?: string;
        order?: string;
        titulo?: string;
    }>>;
}

export default function Filter({
    query,
    setQuery
}: Props) {
    const [isOpen, setIsOpen] = useState(false)
    const { colorMode } = useColorMode()
    const isMobile = useBreakpointValue({ base: true, sm: false });

    return (
        <Flex
            w="full"
            gap="14px"
            align="center"
            flexDirection="row"
        >
            <InputGroup
                bg={colorMode === "dark" ? "bg_dark_light" : "#F0F0F3"}
                rounded="8px"
                h="fit-content"
                gap="10px"

                borderColor="border"
                w="100%"
            >
                <InputLeftElement>
                    <Icon as={BiSearch} color="font_grey" boxSize="20px" />
                </InputLeftElement>

                <Input
                    placeholder='Buscar pregunta'
                    name='titulo'
                    color={colorMode === "dark" ? "#FFFFFF" : "#000000"}
                    borderColor={colorMode === "dark" ? "border_variant" : "border"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const { value } = e?.target

                        const newQuery = { ...query, titulo: query?.titulo }

                        if (!value || value === "") {
                            delete newQuery.titulo
                        } else {
                            newQuery.titulo = value
                        }

                        setQuery(newQuery)
                    }}
                />
            </InputGroup>

            <Flex
                gap="10px"
            >
                <Box position="relative">
                    <Button
                        fontSize="15px"
                        fontWeight="400"
                        color={"font"}
                        bg={colorMode === "dark" ? "bg_dark_light" : "#F0F0F3"}
                        rounded="16px"
                        border="1px solid"
                        borderColor={colorMode === "dark" ? "border_variant" : "main"}
                        p="8px"
                        h="fit-content"
                        w="fit-content"
                        minW={{base: "fit-content", md: "160px"}}
                        rightIcon={<Icon as={BiChevronDown} />}
                        leftIcon={<Icon as={BiSortDown} />}
                        onClick={() => setIsOpen(!isOpen)}
                        _hover={{}}
                        _active={{ transform: "scale(0.98)" }}
                        transition={"all 0.3"}
                    >
                        {isMobile ? "" : "Ordenar por"}
                    </Button>

                    <AnimatePresence>
                        {isOpen && (
                            <motion.div
                                initial={{ opacity: 0, height: 0, width: 0 }}
                                animate={{ opacity: 1, height: 'auto', width: 'auto' }}
                                exit={{ opacity: 0, height: 0, width: 0 }}
                                transition={{ duration: 0.3 }}
                                style={{
                                    position: 'absolute',
                                    top: "120%",
                                    right: "10%",
                                    zIndex: 10,
                                    backgroundColor: colorMode === "dark" ? "bg_dark_light" : "purewhite",
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                    overflow: 'hidden',
                                }}
                            >
                                <Flex
                                    direction="column"
                                    px="10px"
                                    py="15px"
                                    gap="5px"
                                >
                                    <Button
                                        bg={query?.order === "desc" ? "main" : "transparent"}
                                        h="fit-content"
                                        py="10px"
                                        px="25px"
                                        w="100%"
                                        fontSize="16px"
                                        fontWeight="700"
                                        color={query?.order === "desc" ? "purewhite" : "font"}
                                        onClick={(e: React.MouseEvent) => {
                                            e.stopPropagation()

                                            const newQuery = { ...query, order: "desc" }

                                            setQuery(newQuery)
                                            setIsOpen(false)
                                        }}
                                        isDisabled={query?.order === "desc"}
                                        _disabled={{ cursor: "not-allowed", bg: "main" }}
                                        _hover={query?.order === "desc" ? {} : undefined}
                                    >
                                        Más reciente
                                    </Button>

                                    <Button
                                        bg={query?.order === "asc" ? "main" : "transparent"}
                                        h="fit-content"
                                        py="10px"
                                        px="25px"
                                        w="100%"
                                        fontSize="16px"
                                        fontWeight="700"
                                        color={query?.order === "asc" ? "purewhite" : "font"}
                                        onClick={(e: React.MouseEvent) => {
                                            e.stopPropagation()

                                            const newQuery = { ...query, order: "asc" }

                                            setQuery(newQuery)
                                            setIsOpen(false)
                                        }}
                                        isDisabled={query?.order === "asc"}
                                        _disabled={{ cursor: "not-allowed", bg: "main" }}
                                        _hover={query?.order === "asc" ? {} : undefined}
                                    >
                                        Más antigua
                                    </Button>
                                </Flex>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </Box>
            </Flex>
        </Flex>
    )
}
