import { useBreakpointValue, useColorMode, useDisclosure } from "@chakra-ui/react";
import { CursosInt } from "../../../../interfaces/CursosInt"
import { useEffect, useState } from "react";
import AccordionDesktop from "./AccordionDesktop";
import AccordionMobile from "./AccordionMobile";

interface Props {
    curso: CursosInt;
}

export const AccordionContenedor = ({
    curso,
}: Props) => {
    const { colorMode } = useColorMode();
    const isMobile = useBreakpointValue({ base: true, md: false });
    const [inTop, setInTop] = useState<boolean>(false);
    const [contenidoOpen, setContenidoOpen] = useState<boolean>(!isMobile)
    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        setContenidoOpen(!isMobile);
    }, [isMobile]);

    return !isMobile ? (
        <AccordionDesktop
            contenidoOpen={contenidoOpen}
            setContenidoOpen={setContenidoOpen}
            colorMode={colorMode}
            inTop={inTop}
            curso={curso}
            setInTop={setInTop}
        />
    ) : (
        <AccordionMobile
            contenidoOpen={contenidoOpen}
            setContenidoOpen={setContenidoOpen}
            colorMode={colorMode}
            inTop={inTop}
            curso={curso}
            setInTop={setInTop}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            isMobile={isMobile}
        />
    )

}