import { Icon, Drawer, DrawerOverlay, DrawerContent, DrawerHeader, Flex, DrawerBody, DrawerFooter, Image } from '@chakra-ui/react';
import { IoMdClose } from 'react-icons/io';
import { RxHamburgerMenu } from 'react-icons/rx';
import { MenuLinks } from './MenuLinks';
import { UserActions } from './UserActions';

interface Props {
    icono: string
    user: any
    rutas: any
    isOpen: boolean
    onOpen: () => void
    onClose: () => void
    btnRef: React.RefObject<HTMLButtonElement>
    activePath: (text: string) => boolean
}

export default function SidebarMobile({ icono, user, rutas, isOpen, onOpen, onClose, btnRef, activePath }: Props) {
    return (
        <>
            <Icon
                position="absolute"
                ml="10px"
                mt="22px"
                cursor="pointer"
                _hover={{ color: "dark_grey" }}
                as={RxHamburgerMenu}
                boxSize="24px"
                color="font"
                _active={{ transform: "scale(0.9)" }}
                onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    onOpen();
                }}
            />

            <Drawer
                size={{ base: "full", sm: "sm" }}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader
                        display="flex"
                        alignContent="center"
                        justifyContent="space-between"
                        borderBottomWidth="1px"
                        px="10px"
                        py="10px"
                    >
                        <Flex justify="space-between" align="center" w="100%">
                            <Icon
                                cursor="pointer"
                                _hover={{ color: "dark_grey" }}
                                as={IoMdClose}
                                boxSize="35px"
                                color="font"
                                _active={{ transform: "scale(0.9)" }}
                                onClick={(e: React.MouseEvent) => {
                                    e.stopPropagation();
                                    onClose();
                                }}
                            />
                            <Image
                                src={icono}
                                objectFit="contain"
                                w="35px"
                                h="35px"
                            />
                        </Flex>
                    </DrawerHeader>

                    <DrawerBody
                        pl="0px"
                        pr="10px"
                    >
                        <Flex
                            direction="column"
                            align="center"
                            justify="center"
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation();
                                onClose();
                            }}
                        >
                            <MenuLinks user={user} rutas={rutas} activePath={activePath} />

                        </Flex>
                    </DrawerBody>
                    <DrawerFooter
                        px="10px"
                        py="0px"
                        justifyContent="flex-start"
                    >
                        <UserActions onCloseDrawer={onClose} fontSize='18px' />
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}
