import { Flex, Image, Text, useColorMode } from "@chakra-ui/react";
import avatarDefault from "../../../assets/img/avatarDefault.png"
import MarkdownPreview from '@uiw/react-markdown-preview';
import { MensajesInt } from "../../../interfaces/MessagesInt";
import { useAuthContext } from "../../../shared/context/user.context";
import { format } from "../../../shared/utils/functions/formatDate";

interface Props {
    mensaje: MensajesInt | null;
}

export default function ChatConversationItem({ mensaje }: Props) {
    const { user } = useAuthContext();
    const { colorMode } = useColorMode()
    const isAlumno = user?.id !== mensaje?.emisorId;
    
    return (
        <Flex
            p={{ base: "10px", md: "30px" }}
            direction="column"
            bg={isAlumno ? colorMode === "dark" ? "rgba(247, 247, 247, 0.1)" : "#F7F7F7" : ""}
        >
            <Flex
                gap="12px"
            >
                <Flex
                    w="26px"
                    h="26px"
                >
                    <Image
                        src={mensaje?.emisor?.avatar?.url ? mensaje?.emisor?.avatar?.url : avatarDefault}
                        objectFit="cover"
                        rounded="5px"
                    />
                </Flex>

                <Flex
                    direction="column"
                    gap="12px"
                >
                    <Text
                        fontSize="16px"
                        fontWeight="500"
                        color="font"
                    >
                        {mensaje?.emisor?.nombreCompleto}
                    </Text>

                    <MarkdownPreview
                        source={mensaje?.texto || "Error. El mensaje no ha debido de mandarse correctamente."}
                        style={{
                            fontSize: '14px',
                            fontWeight: '400',
                            color: colorMode === "dark" ? "#FFFFFF" : "#121625",
                            width: '100%',
                            maxWidth: '100%',
                            backgroundColor: "transparent"
                        }}
                        wrapperElement={{ "data-color-mode": colorMode === "dark" ? "dark" : "light" }}
                    />
                </Flex>

                <Text
                    ml="auto"
                    fontSize="12px"
                    color="font_gray"
                >
                    {format({ date: mensaje?.createdAt?.toString(), isDateTime: true, customFormat: "dd/MM HH:mm"})}
                </Text>
            </Flex>

        </Flex>
    )
}
