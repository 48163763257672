import { Icon, Drawer, DrawerOverlay, DrawerContent, DrawerHeader, Flex, DrawerBody, Text } from "@chakra-ui/react";
import { BiArrowFromRight } from "react-icons/bi";
import { AccordionContenido } from "./AccordionContenido";
import { CursosInt } from "../../../../interfaces/CursosInt";

interface Props {
    contenidoOpen: boolean
    setContenidoOpen: (contenidoOpen: boolean) => void
    colorMode: string
    inTop: boolean
    curso: CursosInt
    setInTop: (inTop: boolean) => void
    isOpen: boolean
    onOpen: () => void
    onClose: () => void
    isMobile: boolean
}

export default function AccordionMobile({ contenidoOpen, setContenidoOpen, colorMode, inTop, curso, setInTop, isOpen, onOpen, onClose, isMobile }: Props) {
    return (
        <>
            <Icon
                position="absolute"
                color={contenidoOpen ? "#A5A8B3" : colorMode === "dark" ? "purewhite" : "secondary"}
                as={BiArrowFromRight}
                boxSize="28px"
                cursor="pointer"
                transition="all 0.5s"
                transform={contenidoOpen ? 'rotate(0deg)' : 'rotate(-180deg)'}
                mt="10px"
                ml="10px"
                onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    setContenidoOpen(!contenidoOpen);
                    onOpen();
                }}
            />
            <Drawer
                placement={'left'}
                onClose={onClose}
                isOpen={isOpen}
                size={isMobile ? "full" : "md"}
            >
                <DrawerOverlay />
                <DrawerContent
                    bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                >
                    <DrawerHeader
                        py="10px"
                        px="0px"
                    >
                        <Flex
                            px="10px"
                            h="60px"
                            alignItems="center"
                            justifyContent="space-between"
                            transition="box-shadow 0.3s"
                            boxShadow={inTop ? '0px 4px 18px rgba(18, 22, 37, 0.1)' : 'none'}
                            gap={{ base: "50px", md: "40px" }}
                        >
                            <Text
                                fontSize={"14px"}
                                fontWeight="700"
                                color={"font"}
                                transition="all 0.2s"
                                opacity={contenidoOpen ? 1 : 0}
                            >
                                Contenido
                            </Text>

                            <Icon
                                color={contenidoOpen ? "#A5A8B3" : colorMode === "dark" ? "purewhite" : "secondary"}
                                as={BiArrowFromRight}
                                boxSize="28px"
                                cursor="pointer"
                                transition="all 0.5s"
                                transform={contenidoOpen ? 'rotate(0deg)' : 'rotate(-180deg)'}
                                onClick={(e: React.MouseEvent) => {
                                    e.stopPropagation();
                                    setContenidoOpen(!contenidoOpen);
                                    onClose();
                                }}
                            />
                        </Flex>
                    </DrawerHeader>
                    <DrawerBody
                        px="0px"
                        py="0px"
                    >
                        <AccordionContenido curso={curso} contenidoOpen={contenidoOpen} setContenidoOpen={setContenidoOpen} setInTop={setInTop} onClose={onClose} />
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}
