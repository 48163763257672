import { Button, Flex } from "@chakra-ui/react";
import InfoPregunta from "../../components/InfoPregunta";
import { Topbar } from "../../../../shared/components/Topbar/Topbar";
import RespuestasPregunta from "../../components/RespuestasPregunta";
import { useNavigate, useParams } from "react-router-dom";
import { useDataId } from "../../../../shared/hooks/useDataId";
import { EndpointTypes } from "../../../../shared/utils/types/EndpointTypes";
import { useData } from "../../../../shared/hooks/useData";
import { useState } from "react";

export default function Pregunta() {
    const { preguntaId } = useParams()
    const navigate = useNavigate()
    const [query, setQuery] = useState<{
        sortBy?: string;
        order?: string;
    }>({
        sortBy: "createdAt",
        order: "desc"
    })

    const { data: pregunta } = useDataId({
        endpoint: EndpointTypes.PREGUNTAS,
        id: preguntaId
    })

    const { data: respuestas, Refresh } = useData({
        endpoint: EndpointTypes.RESPUESTAS,
        query: {
            ...query,
            pregunta: preguntaId!
        }
    })

    return (
        <Topbar
            buttonBack="/foro"
            searchBar
        >
            <Flex
                direction="column"
                m={{base: "10px", md: "40px"}}
                gap={{base: "20px", md: "50px"}}
                w="100%"
            >
                <Flex
                    display={{base: "flex", md: "none"}}
                >
                    <Button
                        justifyItems='start'
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation()
                            navigate("/foro");
                            Refresh();
                        }}
                    >
                        Volver
                    </Button>
                </Flex>

                <InfoPregunta
                    pregunta={pregunta}
                />

                <RespuestasPregunta
                    respuestas={respuestas?.data}
                    query={query}
                    setQuery={setQuery}
                    Refresh={Refresh}
                />
            </Flex>
        </Topbar>
    )
}
