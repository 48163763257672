import { Flex, Text, useToast, useColorMode } from "@chakra-ui/react";
import { FormLogin } from "./views/FormLogin";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import { MagicLogin } from "./views/MagicLogin";
import { useEffect } from "react";
import { useAuthContext } from "../../shared/context/user.context";
import { loadContext } from "../../shared/middlewares/token.middleware";
import { UserInt } from "../../interfaces/UserInt";

export const Login = () => {
    const { colorMode } = useColorMode()
    const { logout, setUser } = useAuthContext()
    const toast = useToast()
    const navigate = useNavigate()
    const [search, setSearch] = useSearchParams();

    useEffect(() => {
        if (search.has("loginById") && search.has("token")) {
            logout("Sesión anterior finalizada", navigate, toast)

            const token = search.get("token")
            const isLoginById = search.get("loginById")

            if (isLoginById === "true") handleLoginById(token as string)

            return
        }
    }, [])

    const handleLoginById = (token: string) => {
        loadContext(token).then((response: any) => {
            const user = response?.data?.data;

            const perfilUser: UserInt = {
                auth: true,
                tracking: false,
                id: user?.id,
                email: user?.email,
                username: user?.username ? user.username : '',
                avatar: {
                    url: user?.avatar?.url ? user.avatar?.url : '',
                },
                nombre: user?.nombre,
                apellidos: user?.apellidos,
                localidad: user?.localidad ? user.localidad : '',
                telefono: user?.telefono ? user.telefono : '',
                dni: user?.dni ? user.dni : '',
                role: {
                    nombre: user?.role?.nombre ? user?.role?.nombre : ''
                },
                empresa: user?.empresa ? user?.empresa : {},
                horarioLaboral: user?.horarioLaboral ? user?.horarioLaboral : null,
                tutorFreelance: user?.tutorFreelance ? user?.tutorFreelance : null,
                campusFundae: user?.campusFundae ? user?.campusFundae : null,
                campusTutorizado: user?.campusTutorizado ? user?.campusTutorizado : null,
                campusAI: true,
                campusRegistro: true,
            }

            localStorage.setItem('token', token)
            localStorage.setItem('perfil', JSON.stringify(perfilUser))

            setUser(perfilUser);

            let curso = null
            let leccion = null

            if (search.has("curso")) curso = search.get("curso")
            if (search.has("leccion")) leccion = search.get("leccion")

            setSearch({})

            if (curso && !leccion) {
                navigate(`/aprendizaje/${curso}`)
            } else if (curso && leccion) {
                navigate(`/aprendizaje/${curso}/lecciones/${leccion}`)
            } else {
                navigate('/perfil/cuenta')
            }
        })
    }

    return (
        <Flex
            justify="center"
            align="center"
            direction="column"
            w="100%"
            bg={colorMode === "dark" ? "bg_dark" : "purewhite"}
            h="100vh"
        >
            <Flex
            mt='auto'
            >
                <Routes>
                    <Route
                        path="/*"
                        element={<MagicLogin />}
                    />

                    <Route
                        path="/manual"
                        element={<FormLogin />}
                    />
                </Routes>
            </Flex>


            <Flex
                gap="20px"
                align="center"
                justify="center"
                mt='auto'
                mb='20px'
            >
                <Text
                    color={colorMode === "dark" ? "purewhite" : "#696969"}
                    fontSize="14px"
                    fontWeight="400"
                >
                    Política de privacidad
                </Text>

                <Text
                    color={colorMode === "dark" ? "purewhite" : "#696969"}
                    fontSize="14px"
                    fontWeight="400"
                >
                    Contáctanos
                </Text>
            </Flex>
        </Flex>
    );
};