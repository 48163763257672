import { Tabs, TabList, TabIndicator, Tab, Flex, useColorMode } from '@chakra-ui/react';
import { Link as RouterLink } from "react-router-dom";

interface TabItem {
    label: string;
    path: string;
    active: boolean;
    allowed: boolean;
}

interface Props {
    tabs: TabItem[];
}

const TabProfileMobile = ({ tabs }: Props) => {

    const { colorMode } = useColorMode()

    return (
        <Flex
            w="100%"
            display={{ base: "flex", md: "none" }}
            bg={colorMode === "dark" ? "bg_dark" : "purewhite"}
            px="10px"
        >
            <Tabs w="100%" position='relative' variant='unstyled'>
                <TabList w="100%" display="flex" justifyContent={{ base: "space-between", sm: "center" }}>
                    {tabs?.map((t: TabItem, index: number) => (
                        t?.allowed &&
                        <Tab
                            key={index}
                            as={RouterLink}
                            to={t?.path}
                            fontWeight="600"
                            fontSize="18px"
                            letterSpacing="-0.16px"
                            _focus={{ boxShadow: "none" }}
                        >
                            {t?.label}
                        </Tab>
                    ))}
                </TabList>
                <TabIndicator mt='-1.5px' height='2px' bg={"main"} borderRadius='1px' />
            </Tabs>
        </Flex>
    )
}

export { TabProfileMobile }