import { Box, Flex, Button, Text, Image, Icon, Link, useToast, useColorMode, useBreakpointValue } from '@chakra-ui/react'
import logoProcademy from '../../../assets/img/LogoProcademyBlack.png'
import { useEffect, useState } from 'react';
import { useTenantInfo } from '../../../shared/hooks/useTenantInfo';
import { useNavigate } from 'react-router-dom';
import { iconoTenant } from '../../../shared/middlewares/tenant.middleware';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from "yup"
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import { toastNotify } from '../../../shared/utils/functions/toastNotify';
import { StatusEnumTypes } from '../../../shared/utils/types/StatusEnumTypes';
import { handleErrors } from '../../../shared/utils/functions/handleErrors';
import { recoveryPassword } from '../../../shared/middlewares/token.middleware';
import { Link as RouterLink } from "react-router-dom"
import { MdOutlinePassword } from "react-icons/md";

export const SolicitudRecovery = () => {
    const navigate = useNavigate();
    const { handleSetInfo, handleGetInfo } = useTenantInfo();
    const toast = useToast();
    const icono = handleGetInfo({ key: "icono" });
    const nombre = handleGetInfo({ key: "nombre" });
    const colores = handleGetInfo({ key: "colores" });
    const { colorMode } = useColorMode();
    const [loading, setLoading] = useState<boolean>(true)
    const [loadingWidth, setLoadingWidth] = useState("0%")
    const [step, setStep] = useState<"idle" | "loading" | "loaded">("idle")
    const minWidthValue = useBreakpointValue({ base: "320px", sm: "420px" });

    useEffect(() => {
        setLoading(true)

        iconoTenant()
            .then(async (response) => {
                if (response?.icono && response?.icono !== icono)
                    handleSetInfo({ key: "icono", value: response?.icono })
                if (response?.nombre && response?.nombre !== nombre)
                    handleSetInfo({ key: "nombre", value: response?.nombre })
                if (response?.colores && response?.colores !== colores)
                    handleSetInfo({ key: "colores", value: response?.colores })
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false))
    }, [])

    useEffect(() => {
        if (!loading) return;

        let width = 0;
        const interval = setInterval(() => {
            width += 1;
            setLoadingWidth(`${width}%`);

            if (width >= 90) {
                clearInterval(interval);
            }
        }, 50);

        return () => clearInterval(interval);
    }, [loading]);

    useEffect(() => {
        if (loading) return;

        setLoadingWidth("100%");

        setTimeout(() => {
            setLoadingWidth("0%");
        }, 500);
    }, [loading]);

    const initialValues = {
        email: null,
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email("El email debe ser valido")
            .required("El campo email debe estar completo"),
    });

    const submitForm = async (values: any) => {
        recoveryPassword(values.email)
            .then(() => setStep("loaded"))
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))

                setStep("idle")
            })
    };


    return (
        <Flex
            direction="column"
            align="center"
            textAlign='center'
            justify='center'
            gap="30px"
            px="20px"
        >
            <Box
                pos={"fixed"}
                top="0"
                bg="#8694FF"
                h="3px"
                w={loadingWidth}
            />

            {loading ?
                <Flex
                    h="65px"
                    rounded="6px"
                />
                :
                (
                    (icono && nombre) ?
                        <Flex
                            align="center"
                            justify='center'
                            gap="5px"
                        >
                            <Image
                                src={icono}
                                alt='img/logo'
                                objectFit="contain"
                                w="65px"
                                h="65px"
                            />

                            <Text
                                textTransform="capitalize"
                                fontFamily="Poppins"
                                fontSize="22px"
                                color="font"
                                fontWeight="800"
                            >
                                {nombre}
                            </Text>
                        </Flex>
                        :
                        <Image
                            src={logoProcademy}
                            alt='img/logo'
                            objectFit="contain"
                            w="200px"
                            p="0 3%"
                        />
                )
            }

            <Flex
                direction="column"
                align='center'
                justify='center'
                textAlign='center'
            >
                <Text
                    fontFamily="Poppins"
                    fontWeight="800"
                    fontSize={{base: "24px", sm: "44px"}}
                    color="font"
                >
                    Recuperar contraseña
                </Text>

                <Text
                    mt="20px"
                    fontWeight="400"
                    lineHeight="normal"
                    fontSize="14px"
                    color="font"
                >
                    Introduce la dirección de correo electrónico asociada a tu cuenta para recibir instrucciones.
                </Text>
            </Flex>

            {(!loading && step !== "loaded") &&
                <Formik
                    onSubmit={submitForm}
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {(formik) => {
                        const { handleSubmit } = formik;

                        return (
                            <FormikForm
                                onSubmit={handleSubmit}
                                style={{ minWidth: minWidthValue }}
                            >
                                <Flex
                                    direction="column"
                                    alignItems="center"
                                >
                                    <FormInput
                                        name='email'
                                        placeholder="Email"
                                        ui={{
                                            styleInput: {
                                                border: colorMode === "dark" ? "1px solid #576776" : "1px solid #E6E6EA",
                                                bg: colorMode === "dark" ? "bg_dark_light" : "purewhite",
                                                color: colorMode === "dark" ? "purewhite" : "font",
                                            },
                                        }}
                                    />

                                    <Button
                                        mt="10px"
                                        type='submit'
                                        background={colores?.main ? colores?.main : 'main'}
                                        color="#FFFFFF"
                                        fontSize={{ base: '15px', sm: '18px' }}
                                        fontWeight="800"
                                        rounded="12px"
                                        w="100%"
                                        h="fit-content"
                                        py="11px"
                                        px="25px"
                                        isLoading={step === "loading"}
                                    >
                                        Enviar correo de recuperación
                                    </Button>

                                    <Flex
                                        mt="25px"
                                        rounded="10px"
                                        py="15px"
                                        px="15px"
                                        bg={colorMode === "dark" ? "#545763" : "#EFEFEF"}
                                        maxW="430px"
                                        gap="10px"
                                    >
                                        <Icon
                                            as={MdOutlinePassword}
                                            boxSize="15px"
                                            color={colores?.main ? colores?.main : 'main'}
                                        />

                                        <Text
                                            color={colorMode === "dark" ? "purewhite" : "#545763"}
                                            fontSize="14px"
                                            fontWeight="400"
                                            fontStyle="normal"
                                            lineHeight="normal"
                                            textAlign='left'
                                        >
                                            Te enviaremos un enlace por correo electrónico para que puedas restablecer tu contraseña. Si recuerdas tu contraseña, puedes{" "}
                                            <Link
                                                as={RouterLink}
                                                to="/login"
                                                color={colorMode === "dark" ? "purewhite" : "#545763"}
                                                fontWeight="700"
                                                cursor="pointer"
                                                textDecor='underline'
                                            >
                                                volver a la página de inicio de sesión
                                            </Link>
                                            .
                                        </Text>
                                    </Flex>
                                </Flex>
                            </FormikForm>
                        );
                    }}
                </Formik>
            }

            {step === "loaded" &&
                <Text
                    fontSize="15px"
                    fontWeight="500"
                    lineHeight="normal"
                    color="font"
                    textAlign="center"
                >
                    Se han enviado las instrucciones de recuperación a tu correo electrónico. En caso de no recibirlas, puedes{" "}
                    <Text
                        as="span"
                        color="font"
                        fontWeight="800"
                        cursor="pointer"
                        _hover={{ textDecoration: "underline" }}
                        textDecor='underline'
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation()

                            setStep("idle")
                        }}
                    >
                        solicitar un nuevo enlace de recuperación
                    </Text>
                    .
                </Text>
            }
        </Flex>
    )
}