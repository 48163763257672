import { Box, Button, Flex, Icon, Image, Spinner, Text, useColorMode, useDisclosure } from "@chakra-ui/react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { UserActions } from "../Sidebar/components/UserActions";
import { HiOutlineBell, HiOutlineCalendar } from "react-icons/hi";
import { CustomSearch } from "../Customs/CustomSearch";
import { UserRolEnum } from "../../utils/types/UserRolEnum";
import { useAuthContext } from "../../context/user.context";
import NotificacionesDrawer from "../Drawers/NotificacionesDrawer";
import CalendarioDrawer from "../Drawers/CalendarioDrawer";
import { useEffect, useState } from "react";
import { useChannel } from "ably/react";
import { useTenantInfo } from "../../hooks/useTenantInfo";
import DrawerCustomSearch from "./components/DrawerCustomSearch";

export enum GroupTypes {

}

interface Props {
    children: JSX.Element;
    title?: string;
    buttonBack?: string | -1;
    searchBar?: boolean
}

export const Topbar = ({ children, title, buttonBack, searchBar = false }: Props) => {
    const { user } = useAuthContext()
    const navigate = useNavigate();
    const { handleGetInfo } = useTenantInfo();
    const icono = handleGetInfo({ key: "icono" });

    const {
        onOpen: onOpenNotificaciones,
        isOpen: isOpenNotificaciones,
        onClose: onCloseNotificaciones
    } = useDisclosure()
    const {
        onOpen: onOpenCalendario,
        isOpen: isOpenCalendario,
        onClose: onCloseCalendario
    } = useDisclosure()
    const { colorMode } = useColorMode()

    useChannel(`beta-campus-ui`, () => {
        sessionStorage.setItem("hasUpdate", JSON.stringify({ path: location.pathname, newVersion: true }))
    });

    useEffect(() => {
        const hasUpdate = JSON.parse(sessionStorage.getItem("hasUpdate") || "{}")

        if (hasUpdate?.newVersion && hasUpdate?.path !== location.pathname) {
            sessionStorage.removeItem("hasUpdate")
            window.location.reload()
        }
    }, [location.pathname]);

    return (
        <Box>
            <Flex
                alignItems="center"
                justifyContent="space-between"
                gap="20px"
                h="70px"
                px={{base: "10px", md: "30px"}}
                py={{base: "10px", md: "30px"}}
                w='100%'
                bg={colorMode === "dark" ? "bg_dark" : "purewhite"}
            >
                <Flex
                    display={{ base: 'flex', md: 'none' }}
                    ml='40px'
                    alignItems="center"
                    gap="30px"
                    w='35px'
                    h='35px'
                    justifyContent={{ base: "center", md: "flex-start" }}
                >
                    <Image
                        src={icono}
                        objectFit={'contain'}
                        w='40px'
                        h='40px'
                    />

                </Flex>

                <Flex
                    display={{ base: 'none', md: 'flex' }}
                    alignItems="center"
                    gap="30px"
                    justifyContent={{ base: "center", md: "flex-start" }}
                >
                    {buttonBack &&
                        <Button
                            bg={colorMode === "dark" ? "transparent" : "#E6E6EA"}
                            color={colorMode === "dark" ? "purewhite" : "font"}
                            border="1px solid"
                            borderColor={colorMode === "dark" ? "main" : "#E6E6EA"}
                            h="fit-content"
                            w="fit-content"
                            px="16px"
                            py="10px"
                            fontSize="16px"
                            fontWeight="700"
                            lineHeight="22px"
                            letterSpacing="-0.408px"
                            leftIcon={
                                <Icon as={BiArrowBack} boxSize="24px" />
                            }
                            onClick={() => {
                                if (buttonBack === -1) navigate(-1)
                                else navigate(buttonBack)
                            }}
                        >
                            Volver
                        </Button>
                    }

                    {title &&
                        <Text
                            color="font"
                            fontSize="20px"
                            fontWeight="400"
                            ml={{ base: '60px', md: '0px' }}
                        >
                            {title}
                        </Text>
                    }
                </Flex>

                {(searchBar && user?.role?.nombre === UserRolEnum.ALUMNO) &&
                        <Flex
                            display={{ base: 'none', sm: 'flex' }}
                            boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)"
                            w="40%"
                            bg={colorMode === "dark" ? "bg_dark" : "purewhite"}
                        >
                            <Box
                                w='100%'
                            >
                                <CustomSearch
                                    placeholder={"Busca en todo el contenido de tus cursos y foro"}
                                />
                            </Box>
                        </Flex>
                    }

                <Flex
                    gap="16px"
                    alignItems="center"
                    justify='space-around'
                >
                    <DrawerCustomSearch />

                    <Icon
                        cursor="pointer"
                        _hover={{ color: "dark_grey" }}
                        as={HiOutlineCalendar}
                        boxSize="24px"
                        color="font"
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation()
                            onOpenCalendario()
                        }}
                    />

                    <Icon
                        cursor="pointer"
                        _hover={{ color: "dark_grey" }}
                        as={HiOutlineBell}
                        boxSize="24px"
                        color="font"
                        _active={{ transform: "scale(0.9)" }}
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation()
                            onOpenNotificaciones()
                        }}
                    />
                </Flex>
            </Flex>

            <Flex
                overflow={{base: "none", sm: "auto"}}
                h="calc(100vh - 70px)"
                css={{
                    '&::-webkit-scrollbar': {
                        width: '5px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: "light_grey",
                        borderRadius: '20px',
                    },
                }}
            >
                {children}
            </Flex>

            <NotificacionesDrawer
                isOpen={isOpenNotificaciones}
                onClose={onCloseNotificaciones}
            />

            <CalendarioDrawer
                isOpen={isOpenCalendario}
                onClose={onCloseCalendario}
            />
        </Box>
    );
};