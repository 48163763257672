import { Flex } from "@chakra-ui/react"
import { Topbar } from "../../../../shared/components/Topbar/Topbar"
import { useAuthContext } from "../../../../shared/context/user.context"
import { UserRolEnum } from "../../../../shared/utils/types/UserRolEnum"
import { AprendizajeCursos } from "./Items/AprendizajeCursos"
import { AprendizajeMatriculas } from "./Items/AprendizajeMatriculas"


export default function Aprendizaje() {
  const { user } = useAuthContext()

  return (
    <Topbar
      title="Cursos"
      searchBar
    >
      <Flex
        p="40px"
        px={{base: "10px", md: "30px"}}
        pt="20px"
        w="full"
      >
        {(user?.role?.nombre === UserRolEnum.TUTOR || user?.role?.nombre === UserRolEnum.ADMIN)
          ? <AprendizajeCursos />
          : <AprendizajeMatriculas />
        }
      </Flex>
    </Topbar>
  );
}
