import { Button, Flex, Icon, Image, Skeleton, Text, useColorMode, useDisclosure, useToast } from "@chakra-ui/react"
import { Topbar } from "../../../../shared/components/Topbar/Topbar"
import { EndpointTypes } from "../../../../shared/utils/types/EndpointTypes"
import { UserRolEnum } from "../../../../shared/utils/types/UserRolEnum"
import { useData } from "../../../../shared/hooks/useData"
import { Route, Routes, useNavigate, useParams } from "react-router-dom"
import { useAuthContext } from "../../../../shared/context/user.context"
import { useEffect, useState } from "react"
import { handleUnauthorized } from "../../../../shared/utils/functions/handleErrors"
import { MatriculasInt } from "../../../../interfaces/MatriculasInt"
import { ListadoNotas } from "../../components/ListadoNotas"
import { DetalleNota } from "../../components/DetalleNota"
import SelectorCurso from "../../../Entregables/components/SelectorCurso"
import { BiPlus } from "react-icons/bi"
import ModalNotas from "../../components/ModalNotas"
import notasVacioDark from "../../../../assets/img/noData-Dark2.png"
import notasVacio from "../../../../assets/img/noData-Light.png"
import CardCreacion from "../../../../shared/components/Cards/CardCreacion"

export const Notas = () => {
    const { user, logout } = useAuthContext()
    const toast = useToast()
    const navigate = useNavigate()
    const params = useParams();
    const { colorMode } = useColorMode()

    const { isOpen, onOpen, onClose } = useDisclosure()

    const { data: matriculas, error, errorsList, status } = useData({
        endpoint: EndpointTypes.MATRICULAS_ALUMNOS,
        ignoreRequest: !user?.auth || user?.role?.nombre !== UserRolEnum.ALUMNO,
        query: {
            limit: 100
        }
    })

    const [selectedMatricula, setSelectedMatricula] = useState<MatriculasInt>();

    const { data: notas, loading, Refresh } = useData({
        endpoint: EndpointTypes.NOTAS,
        query: selectedMatricula && selectedMatricula?.grupo?.cursoId
            ? {
                curso: selectedMatricula?.grupo?.cursoId
            }
            : undefined
    })

    useEffect(() => {
        if (error) {
            handleUnauthorized({
                logout: logout,
                navigate: navigate,
                toast: toast
            })
        }
    }, [error])

    const onSelectMatricula = (matricula: MatriculasInt) => {
        navigate("/notas")

        setSelectedMatricula(matricula)
    }

    return (
        <Topbar
            title="Notas"
            searchBar
        >
            <Flex
                justify='center'
                gap="20px"
                px={{ base: "10px", md: "30px" }}
                py="20px"
                direction="column"
                w="100%"
            >
                <Flex
                    direction={{ base: 'column', sm: 'row' }}
                    alignItems="center"
                    gap="20px"
                >
                    <SelectorCurso
                        onSelectMatricula={onSelectMatricula}
                        matriculas={matriculas?.data}
                        selectedMatricula={selectedMatricula}
                        hideBorder
                    />
                    <Button
                        leftIcon={
                            <Icon
                                as={BiPlus}
                                boxSize="22px"
                            />
                        }
                        color="purewhite"
                        fontSize="15px"
                        fontWeight="700"
                        lineHeight="16px"
                        letterSpacing="-0.078"
                        h="fit-content"
                        w="100%"
                        maxW={{ base: "100%", md: "130px" }}
                        px="10px"
                        py="10px"
                        bg="main"
                        rounded="10px"
                        onClick={onOpen}
                    >
                        Crear Nota
                    </Button>

                </Flex>

                <Flex
                    w="100%"
                >
                    <Flex
                        w={{ base: "100%", md: "360px" }}
                        pb={{ base: "20px", sm: "0px" }}
                    >
                        {loading ?
                            <Flex
                                direction="column"
                                gap="20px"
                                h="calc(100vh - 190px)"
                                css={{
                                    '&::-webkit-scrollbar': {
                                        display: 'none',
                                    },
                                    'scrollbarWidth': 'none',
                                    '-ms-overflow-style': 'none',
                                    scrollBehavior: 'smooth',
                                }}
                                overflow="auto"
                            >
                                {Array.from({ length: 4 }, (_, i) => i).map((_, index: number) => (
                                    <Skeleton
                                        key={index}
                                        h="158px"
                                        w="320px"
                                        rounded="10px"
                                        ml="20px"
                                    />
                                ))}
                            </Flex>
                            : (
                                !notas || notas?.meta?.total === 0 ?
                                    <Flex
                                        h="calc(100vh - 190px)"
                                        pr="20px"
                                    >
                                        <CardCreacion
                                            onOpen={onOpen}
                                            buttonText="Crea una Nota"
                                            description="Aún no hay notas disponibles para este curso, puedes crearlas presionando aquí. Las notas de las lecciones o cursos se mostrarán aquí una vez que sean añadidas."
                                        />
                                    </Flex>
                                    :
                                    <ListadoNotas
                                        notas={notas}
                                    />
                            )
                        }
                    </Flex>

                    <Flex
                        display={{ base: "none", md: "flex" }}
                        w="100%"
                        bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                        rounded='10px'
                        border="1px solid"
                        borderColor={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
                    >
                        {!params["*"] &&
                            <Flex
                                display={{ base: "none", md: "flex" }}
                                px="30px"
                                pt="30px"
                                direction="column"
                                border="1px solid"
                                borderColor={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
                                bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                                w="100%"
                                rounded="10px"
                                h="100%"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Image
                                    src={colorMode === "dark" ? notasVacioDark : notasVacio}
                                    w="175px"
                                    h="fit-content"
                                />

                                <Text
                                    mt="40px"
                                    color="font"
                                    fontSize="24px"
                                    fontWeight="600"
                                >
                                    Selecciona una nota
                                </Text>

                                <Text
                                    mt="10px"
                                    color="font"
                                    fontSize="14px"
                                    fontWeight="500"
                                >
                                    Podrás editar y previsualizar las notas que hayas creado
                                </Text>
                            </Flex>
                        }
                        <Routes>
                            <Route
                                path="/:id"
                                element={
                                    <DetalleNota />
                                }
                            />
                        </Routes>
                    </Flex>
                </Flex>

                <ModalNotas
                    isOpen={isOpen}
                    onClose={onClose}
                    matriculas={matriculas}
                    Refresh={Refresh}
                />
            </Flex>
        </Topbar>
    )
}