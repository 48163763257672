import { useEffect, useRef, useState } from 'react';
import { Box, Flex, Icon, Image, Text, List, ListItem, Input, keyframes, useColorMode, useBreakpointValue } from '@chakra-ui/react';
import { BiChevronDown, BiChevronLeft, BiListUl } from 'react-icons/bi';
import { MatriculasInt } from '../../../interfaces/MatriculasInt';
import { truncate } from 'lodash';

interface CustomSelectProps {
  onSelectMatricula: (matricula: MatriculasInt) => void;
  matriculas: MatriculasInt[];
  selectedMatricula: MatriculasInt | undefined;
  hideBorder?: boolean;
  isForo?: boolean;
}

const scaleY = keyframes`
  from { transform: scaleY(0); }
  to { transform: scaleY(1); }
`;

const SelectorCurso = ({ onSelectMatricula, matriculas, selectedMatricula, hideBorder = false, isForo }: CustomSelectProps) => {
  const { colorMode } = useColorMode()
  const [isActive, setIsActive] = useState(false);
  const modalRef = useRef<HTMLDivElement | null>(null);

  const nombreLength = useBreakpointValue({ base: 15, sm: 30 });
  const grupoLength = useBreakpointValue({ base: 5, sm: 30 });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setIsActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleToggle = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsActive(!isActive);
  };

  const handleSelect = (matricula: MatriculasInt) => {
    onSelectMatricula(matricula);
    setIsActive(false);
  };

  return (
    <Box position="relative" w="full" fontSize="16px" color="black" ref={modalRef}>
      <Flex
        as="button"
        width="full"
        fontSize="16px"
        bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
        py={{ base: "8px", sm: "10px" }}
        px={{ base: "16px", sm: "20px" }}
        border={!hideBorder ? "1px solid" : "none"}
        borderColor={isActive ? "main" : colorMode === "dark" ? "border_variant" : "border"}
        borderRadius="16px"
        cursor="pointer"
        justifyContent="space-between"
        alignItems="center"
        onClick={handleToggle}
        transition="border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out"
        boxShadow={!hideBorder ? (isActive ? "0px 0px 10px 0px rgba(134, 148, 255, 0.3)" : "none") : "none"}
        gap="24px"
      >
        <Flex
          gap="10px"
          alignItems="center"
        >
          {isForo ?
            selectedMatricula?.grupo?.curso?.icono?.url ?
              <Image
                src={selectedMatricula?.grupo?.curso?.icono?.url}
                boxSize="50px"
                p="5px"
                rounded="10px"
              />
              :
              <Icon
                as={BiListUl}
                boxSize="24px"
              />
            :
            <Image
              src={selectedMatricula?.grupo?.curso.icono?.url || `data:image/svg+xml;utf8,${defaultIcon}`}
              border="1px solid"
              borderColor={colorMode === "dark" ? "transparent" : "border"}
              bg="transparent"
              boxSize="50px"
              p="5px"
              rounded="10px"
            />
          }


          <Text fontSize="16px" whiteSpace="nowrap" color="font">
            {selectedMatricula
              ? `${truncate(selectedMatricula?.grupo?.curso?.nombre, { length: nombreLength })} - ${truncate(selectedMatricula?.grupo?.nombre, { length: grupoLength })}`
              : 'Todos los cursos'
            }
          </Text>

        </Flex>

        <Icon
          as={BiChevronDown}
          boxSize={!hideBorder ? "24px" : "28px"}
          transform={isActive ? "rotate(90deg)" : "none"}
          transition="transform ease-in-out 0.3s"
          color={colorMode === "dark" ? "grey" : "font"}
        />
      </Flex>

      <List
        position="absolute"
        opacity={isActive ? 1 : 0}
        visibility={isActive ? "visible" : "hidden"}
        transform={isActive ? "scaleY(1)" : "scaleY(0)"}
        transformOrigin="top"
        bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
        border="1px solid"
        borderColor={colorMode === "dark" ? "#576776" : "border"}
        p="10px"
        mt="10px"
        maxHeight="1000px"
        borderRadius="10px"
        transition="0.3s ease"
        width="full"
        zIndex={1000}
        overflowY="auto"
        sx={{
          '&::-webkit-scrollbar': {
            width: '7px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '25px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#ccc',
            borderRadius: '25px',
          },
          animation: isActive ? `${scaleY} 0.3s ease forwards` : 'none',
        }}
      >
        {matriculas && matriculas?.length > 0 ?
          matriculas?.map((matricula: MatriculasInt, index: number) => (
            <ListItem
              key={index}
              onClick={() => handleSelect(matricula)}
              position="relative"
              cursor="pointer"
              _hover={{ bg: colorMode === "dark" ? "#576776" : "#f2f2f2" }}
              _focus={{ bg: colorMode === "dark" ? "#424242" : "#dfdfdf" }}
            >
              <Flex as="label" width="100%" p="8px 10px" gap="10px" alignItems="center">
                <Image
                  src={matricula?.grupo?.curso.icono?.url || `data:image/svg+xml;utf8,${defaultIcon}`}
                  boxSize="28px"
                />
                <Input
                  type="radio"
                  name="label"
                  position="absolute"
                  left={0}
                  opacity={0}
                />
                <Text
                  color={colorMode === "dark" ? "grey" : "font"}
                >{matricula?.grupo?.curso?.nombre} - {matricula?.grupo?.nombre}</Text>
              </Flex>
            </ListItem>
          ))
          :
          <ListItem
            position="relative"
            cursor="pointer"
            _hover={{ bg: '#f2f2f2' }}
            _focus={{ bg: '#dfdfdf' }}
          >
            <Flex as="label" width="100%" p="8px 10px" gap="10px" alignItems="center" justifyContent="center">
              <Input
                type="radio"
                name="label"
                position="absolute"
                left={0}
                opacity={0}
              />
              <Text
                color="font"
              >
                No hay matrículas activas
              </Text>
            </Flex>
          </ListItem>
        }
      </List>
    </Box>
  );
};

export default SelectorCurso;

export const defaultIcon = `<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="17" y="0.0274948" width="24" height="24" rx="4" transform="rotate(45 17 0.0274948)" fill="%233D3F65" stroke="%233D3F65" stroke-width="2"/>
</svg>`