import { Flex, Text } from "@chakra-ui/react"
import { EndpointTypes } from "../../../../shared/utils/types/EndpointTypes"
import { useData } from "../../../../shared/hooks/useData"
import { Topbar } from "../../../../shared/components/Topbar/Topbar"
import { ExamenesSekeleton } from "../../skeletons/Index"
import { CardSinInformacion } from "../../../../shared/components/Cards/CardSinInformacion"
import { TypeCardSinInformacion } from "../../../../shared/components/Cards/CardSinInformacion"
import CardEvaluaciones from "../../components/CardEvaluaciones"
import { EvaluacionesInt } from "../../../../interfaces/EvaluacionesInt"

export const Evaluaciones = () => {
    const { data: evaluacion, loading } = useData({
        endpoint: EndpointTypes.EVALUACIONES
    })

    return (
        <Topbar
            title="Exámenes"
            searchBar
        >
            <Flex
                direction="column"
                px={{ base: "10px", md: "30px" }}
                py="20px"
                gap="30px"
                w="100%"
            >
                {loading
                    ?
                    <>
                        <Text
                            fontSize="18px"
                            fontWeight="700"
                            color="font"
                        >
                            Disponibles
                        </Text>

                        <Flex
                            gap="30px"
                            flexWrap="wrap"
                            h="fit-content"
                        >
                            <ExamenesSekeleton
                                totalCards={evaluacion?.meta?.total}
                            />
                        </Flex>
                    </>
                    :
                    evaluacion?.meta?.total !== 0
                        ?
                        <>
                            <Text
                                fontSize="18px"
                                fontWeight="700"
                                color="font"
                            >
                                Disponibles
                            </Text>

                            <Flex
                                gap="30px"
                                h="fit-content"
                                flexWrap="wrap"
                            >
                                {evaluacion?.data?.map((evaluacion: EvaluacionesInt, index: number) => (
                                    <CardEvaluaciones
                                        key={index}
                                        evaluacion={evaluacion}
                                    />
                                ))}
                            </Flex>
                        </>
                        :
                        <CardSinInformacion
                            type={TypeCardSinInformacion.EVALUACIONES}
                            fullRounded
                            withBorder
                        />
                }
            </Flex>
        </Topbar >
    )
}