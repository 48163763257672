import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Tooltip, useColorMode, useToast } from "@chakra-ui/react";
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from "yup"
import { FormTextEditor } from "../../../shared/components/FormElements/FormTextEditor";
import { FormInput } from "@imaginagroup/bit-components.ui.form-input";
import { FormSelect } from "@imaginagroup/bit-components.ui.form-select";
import { formSelectStyles, formSelectStylesDark } from "../../../ui/Select/formSelectStyles";
import { MatriculasInt } from "../../../interfaces/MatriculasInt";
import { useEffect, useState } from "react";
import { loadData } from "../../../shared/middlewares/middlewares";
import { EndpointTypes } from "../../../shared/utils/types/EndpointTypes";
import { addNota } from "../../../shared/middlewares/notas.middleware";
import { toastNotify } from "../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../shared/utils/types/StatusEnumTypes";
import { useNavigate } from "react-router-dom";

interface Props {
    onClose: () => void;
    isOpen: boolean
    matriculas: {
        data: MatriculasInt[];
    }
    Refresh: () => void;
}

export default function ModalNotas({ onClose, isOpen, matriculas, Refresh }: Props) {
    const { colorMode } = useColorMode()
    const toast = useToast()
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)
    const [cursos, setCursos] = useState<{ label: string; value: string; }[]>([])
    const [cursoSelected, setCursoSelected] = useState<string>()
    const [lecciones, setLecciones] = useState<{ label: string; value: string; }[]>([])

    useEffect(() => {
        if (matriculas) {
            const cursosOpt: { label: string; value: string; }[] = []

            matriculas?.data?.map((m: MatriculasInt) => {
                cursosOpt.push({
                    label: m?.grupo?.curso?.nombre,
                    value: m?.grupo?.cursoId!
                })
            })

            setCursos(cursosOpt)
        }
    }, [matriculas])

    const initialValues = {
        titulo: null,
        contenido: null,
        curso: null,
        leccion: null
    };

    const validationSchema = Yup.object().shape({
        titulo: Yup.string()
            .required("El titulo de la nota es obligatorio")
            .typeError("El titulo de la nota es obligatorio"),
        contenido: Yup.string()
            .required("El contenido de la nota es obligatorio")
            .typeError("El contenido de la nota es obligatorio"),
        curso: Yup.string()
            .notRequired(),
        leccion: Yup.string()
            .required("La lección de la nota es obligatoria")
            .typeError("La lección de la nota es obligatoria")
    });

    const submitForm = async (values: any) => {
        setLoading(true)

        const nota = {
            titulo: values?.titulo,
            contenido: values?.contenido,
            leccionId: values?.leccion
        }

        addNota(nota)
            .then((response) => {
                const id = response?.data?.data?.id

                Refresh()
                navigate(`/notas/${id}`)
                onClose()
            })
            .catch(() => toastNotify(toast, StatusEnumTypes.ERROR, "Error al crear nota"))
            .finally(() => setLoading(false))
    };

    useEffect(() => {
        if (cursoSelected) {
            const loadLecciones = async (cursoId: string) => await loadData({
                endpoint: EndpointTypes.LECCIONES,
                query: "cursoId=",
                value: cursoId!
            })

            loadLecciones(cursoSelected).then((res) => setLecciones(res))
        }
    }, [cursoSelected])

    return (
        <Modal
            size="full"
            onClose={onClose}
            isOpen={isOpen}
            motionPreset='slideInRight'
        >

            <ModalOverlay />
            <ModalContent
                p={{ base: "0px", sm: "20px" }}
                bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
            >
                <ModalHeader
                    flexDirection='column'
                    fontSize={{ base: "20px", md: "24px" }}
                    fontWeight="700"
                    color="font"
                    p="10px"
                >
                    <Button
                        justifyItems='start'
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation()
                            onClose()
                        }}
                        mb='15px'
                    >
                        Volver
                    </Button>
                    <Text
                    >
                        Nueva nota
                    </Text>
                    <Flex
                        bg="border"
                        w="full"
                        h="1px"
                        px="10px"
                        mt="20px"
                    />
                </ModalHeader>


                <Formik
                    onSubmit={submitForm}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {(formik) => {
                        const { handleSubmit, values } = formik;

                        return (
                            <FormikForm
                                onSubmit={handleSubmit}
                            >
                                <ModalBody
                                    py="0px"
                                    px="10px"
                                >
                                    <Flex
                                        direction="column"
                                    >
                                        <Flex
                                            alignItems="center"
                                            flexDirection={{ base: "column", md: "row" }}
                                            gap={{base: "14px", md: "40px"}}
                                        >
                                            <Text
                                                fontSize="16px"
                                                fontWeight="600"
                                                whiteSpace="nowrap"
                                                w={{ base: "100%", md: "150px" }}
                                                textAlign={{ base: "left", md: "end" }}
                                            >
                                                Título nota
                                            </Text>

                                            <FormInput
                                                name="titulo"
                                                placeholder="Escribe un título para la nota"
                                                ui={{
                                                    styleInput: {
                                                        borderRadius: '10px',
                                                        borderColor: colorMode === "dark" ? "border_variant" : "#E2E8F0",
                                                        backgroundColor: colorMode === "dark" ? "bg_dark_light" : "purewhite",
                                                        p: "12px 15px",
                                                        color: colorMode === "dark" ? "#FFFFFF" : "#000000",
                                                    },
                                                }}
                                            />
                                        </Flex>

                                        <Flex
                                            align="center"
                                            flexDirection={{ base: "column", md: "row" }}
                                            gap={{base: "14px", md: "40px"}}
                                        >
                                            <Text
                                                fontSize="16px"
                                                fontWeight="600"
                                                whiteSpace="nowrap"
                                                w={{ base: "100%", md: "150px" }}
                                                textAlign={{ base: "left", md: "end" }}
                                            >
                                                Curso
                                            </Text>

                                            <FormSelect
                                                name="curso"
                                                options={cursos}
                                                onChangeCallback={(e: { label: string; value: string; }) => {
                                                    setCursoSelected(e?.value)
                                                }}
                                                placeholder="Selecciona el curso al cual quieres asociar la nota"
                                                ui={{
                                                    formSelectStyles: colorMode === "dark" ? formSelectStylesDark : formSelectStyles,
                                                    styleLabel: {
                                                        fontSize: "14px",
                                                        color: colorMode === "dark" ? "#FFFFFF" : "#000000",
                                                    }
                                                }}
                                            />
                                        </Flex>

                                        <Flex
                                            align="center"
                                            flexDirection={{ base: "column", md: "row" }}
                                            gap={{base: "14px", md: "40px"}}
                                        >
                                            <Text
                                                fontSize="16px"
                                                fontWeight="600"
                                                whiteSpace="nowrap"
                                                w={{ base: "100%", md: "150px" }}
                                                textAlign={{ base: "left", md: "end" }}
                                            >
                                                Lección
                                            </Text>

                                            <Tooltip
                                                hasArrow
                                                label="Primero debe seleccionar un curso"
                                                color="purewhite"
                                                fontSize="14px"
                                                fontWeight="500px"
                                                rounded="8px"
                                                px="10px"
                                                bg="secondary"
                                                isDisabled={!values?.curso ? false : true}
                                            >
                                                <Flex
                                                    width="100%"
                                                    cursor={!values?.curso ? "not-allowed" : "default"}
                                                >
                                                    <FormSelect
                                                        name="leccion"
                                                        options={lecciones}
                                                        placeholder="Selecciona la lección del curso a la que quieres asociar la nota"
                                                        isDisabled={!values?.curso}
                                                        ui={{
                                                            formSelectStyles: colorMode === "dark" ? formSelectStylesDark : formSelectStyles,
                                                            styleLabel: {
                                                                fontSize: "14px",
                                                                color: colorMode === "dark" ? "#FFFFFF" : "#000000",
                                                            }
                                                        }}
                                                    />
                                                </Flex>
                                            </Tooltip>
                                        </Flex>

                                        <Flex
                                            align="center"
                                            flexDirection={{ base: "column", md: "row" }}
                                            gap={{base: "14px", md: "40px"}}
                                        >
                                            <Text
                                                fontSize="16px"
                                                fontWeight="600"
                                                whiteSpace="nowrap"
                                                w={{ base: "100%", md: "150px" }}
                                                textAlign={{ base: "left", md: "right" }}
                                                alignSelf="flex-start"
                                            >
                                                Contenido
                                            </Text>

                                            <FormTextEditor
                                                height="200px"
                                                name='contenido'
                                                onChange={formik.setFieldValue}
                                                placeholder="Escribe una respuesta"
                                                styles={{
                                                    borderRadius: "8px",
                                                    padding: "0",
                                                    fontSize: "15px",
                                                    fontWeight: "400",
                                                    lineHeight: "150%",
                                                    minHeight: "200px",
                                                }}
                                            />
                                        </Flex>
                                    </Flex>
                                </ModalBody>
                                <ModalFooter
                                    px="10px"
                                >
                                    <Button
                                        h="fit-content"
                                        w="fit-content"
                                        px="16px"
                                        py="10px"
                                        bg="main"
                                        rounded="10px"
                                        color="purewhite"
                                        fontSize="16px"
                                        fontWeight="700"
                                        lineHeight="22px"
                                        letterSpacing="-0.408px"
                                        type="submit"
                                        isLoading={loading}
                                    >
                                        Crear Nota
                                    </Button>
                                </ModalFooter>
                            </FormikForm>
                        );
                    }}
                </Formik>
            </ModalContent>
        </Modal >
    )
}
