import { Box, Flex, useBreakpointValue, useColorMode } from "@chakra-ui/react";
import { Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Icon, Image, useDisclosure } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/user.context";
import { LogoEmpresa } from "./components/LogoEmpresa";
import { useTenantInfo } from "../../hooks/useTenantInfo";
import { EndpointTypes } from "../../utils/types/EndpointTypes";
import { useData } from "../../hooks/useData";
import { UserActions } from "./components/UserActions";
import { MenuLinks } from "./components/MenuLinks";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoMdClose } from "react-icons/io";
import { useRef } from "react";
import SidebarDesktop from "./components/SidebarDesktop";
import SidebarMobile from "./components/SidebarMobile";

export const Sidebar = () => {
    const { handleGetInfo } = useTenantInfo();
    const { user, authToken } = useAuthContext();
    const location = useLocation();
    const hideInPaths = location.pathname.includes("/lecciones") || location.pathname.includes("/public") || location.pathname.includes("/examenes")
    const { colorMode } = useColorMode()
    const isMobile = useBreakpointValue({ base: true, md: false });
    const btnRef = useRef<HTMLButtonElement | null>(null)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { data: rutas } = useData({
        endpoint: EndpointTypes.RUTAS,
        ignoreRequest: !user?.auth || !authToken,
    })
    const icono = handleGetInfo({ key: "icono" });

    const activePath = (text: string): boolean => {
        if (text === "/") {
            if (location.pathname === "/")
                return true
            return false
        } else if (text === "/aprendizaje") {
            if (location.pathname.includes(text) || location.pathname.startsWith("/leccion"))
                return true
            return false
        } else {
            if (location.pathname.includes(text)) { return true }
            else { return false }
        }
    };

    return !hideInPaths ? (
        !isMobile ? (
            <SidebarDesktop
                icono={icono}
                user={user}
                rutas={rutas}
                activePath={activePath}
            />
        ) : (
            <SidebarMobile
                icono={icono}
                user={user}
                rutas={rutas}
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                btnRef={btnRef}
                activePath={activePath}
            />
        )
    ) : (
        <Box display="none" />
    );
};