import _axios from "../services/http.service";
import tenantInstance from "../services/tenantInstance.service";

export const addInscripcion = async ({ data, slug }: {
    data: {
        nombre: string | null;
        apellidos: string | null;
        email: string | null;
        telefono: string | null;
        dni: string | null;
    },
    slug: string;
}) => {
    const { tenant } = await tenantInstance();

    return await _axios.post(
        `${tenant}/public/inscripcion/${slug}`,
        data,
        {}
    );
};

export const addPrematriculas = async ({ data, hashString }: {
    data: {
        status: 'pending' | 'finished' | null;
        prematriculas: {
            nombre: string | null;
            apellidos: string | null;
            email: string | null;
            telefono: string | null;
            dni: string | null;
            nivelEstudios: string | null;
            categoriaProfesional: string | null;
            costeHora: number | null;
            fijoDiscontinuo: boolean | null;
            curso: string | null;
        }[];
    },
    hashString: string;
}) => {
    const { tenant } = await tenantInstance();

    return await _axios.post(`${tenant}/public/grupos/prematriculas/${hashString}`, data, {});
};


export const getGrupo = async ({ hashString }: { hashString: any }) => {
    const { tenant } = await tenantInstance();

    return await _axios.get(`${tenant}/public/grupos/${hashString}`);
};