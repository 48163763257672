import { Flex, Icon, Text } from "@chakra-ui/react"
import { BiArrowFromRight } from "react-icons/bi"
import { AccordionContenido } from "./AccordionContenido"
import { CursosInt } from "../../../../interfaces/CursosInt"

interface Props {
    contenidoOpen: boolean
    setContenidoOpen: (contenidoOpen: boolean) => void
    colorMode: string
    inTop: boolean
    curso: CursosInt
    setInTop: (inTop: boolean) => void
}

export default function AccordionDesktop({ contenidoOpen, setContenidoOpen, colorMode, inTop, curso, setInTop }: Props) {
    return (
        <Flex
            direction="column"
            w={contenidoOpen ? "330px" : "0px"}
            bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
            borderRight="1px solid"
            borderColor={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
            transition="all 0.5s"
            transform={contenidoOpen ? "translateX(0)" : "translateX(-130px)"}
        >
            <Flex
                px="30px"
                h="74px"
                alignItems="center"
                justifyContent="space-between"
                transition="box-shadow 0.3s"
                boxShadow={inTop ? '0px 4px 18px rgba(18, 22, 37, 0.1)' : 'none'}
                gap={{ base: "50px", md: "40px" }}
            >
                <Text
                    fontSize="14px"
                    fontWeight="700"
                    color="font"
                    transition="all 0.2s"
                    opacity={contenidoOpen ? 1 : 0}
                >
                    Contenido
                </Text>

                <Icon
                    color={contenidoOpen ? "#A5A8B3" : colorMode === "dark" ? "purewhite" : "secondary"}
                    as={BiArrowFromRight}
                    boxSize="28px"
                    cursor="pointer"
                    transition="all 0.5s"
                    transform={contenidoOpen ? 'rotate(0deg)' : 'rotate(-180deg)'}
                    onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        setContenidoOpen(!contenidoOpen);
                    }}
                />
            </Flex>

            <AccordionContenido curso={curso} contenidoOpen={contenidoOpen} setInTop={setInTop} />
        </Flex>
    )
}
