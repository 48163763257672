import React, { useRef, useEffect, useState } from 'react';

import { Field } from 'formik';
import { FormLabel, FormControl, Box, Icon, Text, useColorMode } from '@chakra-ui/react';
import { BiInfoCircle } from 'react-icons/bi';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "../../../ui/sass/TextEditor.scss"

type FormTextEditorProps = {
    name: string;
    label?: string;
    placeholder?: string;
    isRequired?: boolean;
    controlStyle?: React.CSSProperties;
    height?: string;
    onChange: (e: any, form: any) => void;
    styles?: React.CSSProperties;
    maxWidth?: string;
};

export const FormTextEditor = ({
    name,
    label,
    placeholder,
    controlStyle = {},
    isRequired = false,
    height = '320px',
    onChange,
    styles,
    maxWidth = '100%'
}: FormTextEditorProps) => {
    const { colorMode } = useColorMode();
    const quillRef = useRef<ReactQuill>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const toolbarOptions = isMobile ?
        [
            ["bold", "italic", "underline", "strike"],
            [{ 'list': "ordered" }, { 'list': "bullet" }],
            ["link"],
            [{ 'indent': "-1" }, { 'indent': "+1" }],
        ]
        :
        [
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link"],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ align: [] }],
            ["code-block"],
            ["image"],
        ];

    const modules = {
        toolbar: toolbarOptions,
        clipboard: {
            matchVisual: false,
        },
    };

    const formats = [
        "header",
        "image",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "code-block",
        "list",
        "bullet",
        "indent",
        "link",
        "align",
    ];

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('paste', handlePaste);
            return () => {
                container.removeEventListener('paste', handlePaste);
            };
        }
    }, []);

    const handlePaste = (e: ClipboardEvent) => {
        e.preventDefault();
        const text = e.clipboardData?.getData('text/plain');
        const quillEditor = quillRef.current?.getEditor();
        if (quillEditor && text) {
            const cleanedText = text.replace(/\n+$/, '').trim();

            // Insertar saltos de línea cada 100 caracteres si no hay espacios
            const processedText = cleanedText.replace(/(.{1,100})/g, '$1\n').trim();

            const range = quillEditor.getSelection(true);
            if (range) {
                quillEditor.insertText(range.index, processedText);
            } else {
                const lastKnownRange = quillEditor.getSelection();
                if (lastKnownRange) {
                    quillEditor.insertText(lastKnownRange.index, processedText);
                } else {
                    const length = quillEditor.getLength();
                    quillEditor.insertText(length - 1, processedText);
                }
            }

            const updatedContent = quillEditor.root.innerHTML;
            onChange(name, updatedContent);
        }
    };

    return (
        <Field name={name}>
            {({ field, form }: { field: any; form: any }) => (
                <FormControl style={controlStyle} isInvalid={form.errors[name] && form.touched[name]}>
                    {label &&
                        <FormLabel
                            htmlFor={name}
                            display="flex"
                            gap="3px"
                            color="font"
                            fontSize="14px"
                            fontWeight="400"
                            textTransform="capitalize"
                        >
                            {label}
                        </FormLabel>
                    }

                    <Box
                        pos="relative"
                        className={colorMode === "dark" ? "form-texteditor-dark" : "form-texteditor"}
                        ref={containerRef}
                        maxWidth={maxWidth}
                        overflow="hidden"
                    >
                        <ReactQuill
                            ref={quillRef}
                            {...field}
                            name={name}
                            theme="snow"
                            value={form?.values?.[field?.name]}
                            onChange={(content: string) => {
                                onChange(field?.name, content);
                            }}
                            onFocus={() => {
                                const quillEditor = quillRef.current?.getEditor();
                                if (quillEditor && !quillEditor.getSelection()) {
                                    quillEditor.setSelection(quillEditor.getLength() - 1, 0);
                                }
                            }}
                            style={{
                                minHeight: height,
                                border: "1px solid",
                                borderColor: colorMode === "dark" ? "#576776" : "#E2E8F0",
                                backgroundColor: colorMode === "dark" ? "bg_dark_light" : "purewhite",
                                color: colorMode === "dark" ? "#FFFFFF" : "#000000",
                                ...styles,
                                overflowWrap: 'break-word',
                                wordWrap: 'break-word',
                                wordBreak: 'break-all',
                            }}
                            modules={{
                                ...modules,
                                clipboard: {
                                    ...modules.clipboard,
                                    matchVisual: false,
                                },
                            }}
                            formats={formats}
                            placeholder={placeholder}
                        />

                        {isRequired &&
                            <Icon
                                as={BiInfoCircle}
                                boxSize="15px"
                                color={(form.errors[name] && form.touched[name]) ? "red" : "font"}
                                pos="absolute"
                                right="-7px"
                                top="-7px"
                            />
                        }
                    </Box>

                    <Box h="18px" w="100%" mt="2px">
                        <Text
                            color="fail"
                            fontSize="13px"
                            fontWeight="400"
                        >
                            {form.touched[name] && form.errors[name] ? form.errors[name] : ""}
                        </Text>
                    </Box>
                </FormControl>
            )}
        </Field>
    );
};