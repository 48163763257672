import { Flex, useColorMode } from '@chakra-ui/react'
import { LogoEmpresa } from './LogoEmpresa'
import { MenuLinks } from './MenuLinks'
import { UserActions } from './UserActions'

interface Props {
    icono: string
    user: any
    rutas: any
    activePath: (text: string) => boolean
}

export default function SidebarDesktop({ icono, user, rutas, activePath }: Props) {
    const { colorMode } = useColorMode()

    return (
        <Flex
            h="100vh"
            bg={colorMode === "dark" ? "bg_dark_variant" : "purewhite"}
            borderRight="1px solid"
            borderColor={colorMode === "dark" ? "#1C1B21" : "#E6E6EA"}
        >
            <Flex
                direction="column"
                gap="20px"
            >
                <LogoEmpresa
                    data={icono}
                />

                <Flex
                    direction="column"
                    w="100%"
                    gap="7px"
                >
                    <MenuLinks user={user} rutas={rutas} activePath={activePath} />
                </Flex>

                <UserActions />
            </Flex>
        </Flex>
    )
}
