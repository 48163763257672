import { Button, Text, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Box, Flex, useColorMode, useToast } from "@chakra-ui/react"
import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { useDataId } from "../../../shared/hooks/useDataId"
import { EndpointTypes } from "../../../shared/utils/types/EndpointTypes"
import { updateNota } from "../../../shared/middlewares/notas.middleware"
import { StatusEnumTypes } from "../../../shared/utils/types/StatusEnumTypes"
import { toastNotify } from "../../../shared/utils/functions/toastNotify"
import { DetalleNota } from "./DetalleNota";


interface Props {
    isOpen: boolean;
    onClose: () => void;
}

function ModalEditarNotaMobile({ isOpen, onClose }: Props) {
    const btnRef = React.useRef<HTMLButtonElement>(null)
    const { colorMode } = useColorMode()
    const { id } = useParams()
    const toast = useToast();

    const { data: nota, loading, Refresh } = useDataId({
        endpoint: EndpointTypes.NOTAS,
        id: id!,
    })

    const handleEdit = async (values: { contenido: string | undefined }) => {

        return updateNota({
            id: id!,
            data: values
        })
            .then(() => Refresh())
            .catch(() => toastNotify(toast, StatusEnumTypes.ERROR, "Error al actualizar"));
    }

    const isExistsChanges = (
        values: { contenido: string | undefined }
    ) => {
        const initContent = nota?.contenido?.replaceAll("<p><br></p>", "")?.replaceAll("\n", "")
        const newContent = values?.contenido?.replaceAll("<p><br></p>", "")?.replaceAll("\n", "")

        if (initContent !== newContent) return true

        return false
    }

    return (
        <Flex
            w='100%'
        >
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                finalFocusRef={btnRef}
                size={{ base: 'full', sm: 'none' }}
            >
                <DrawerOverlay />
                <DrawerContent
                    bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                >
                    <DrawerHeader
                        p="10px"
                    >
                        <Flex>
                            <Button
                                justifyItems='start'
                                onClick={(e: React.MouseEvent) => {
                                    e.stopPropagation()
                                    onClose()
                                }}
                            >
                                Volver
                            </Button>
                        </Flex>
                    </DrawerHeader>
                    <DrawerBody
                        px='0px'
                        py='0px'
                    >
                        <Flex
                            direction="column"
                            mb={{ base: "20px", sm: "0px" }}
                            px="10px"
                        >
                            <Text
                                fontSize="20px"
                                fontWeight="700"
                            >
                                Editar nota
                            </Text>
                            <Box
                                mt="20px"
                                h="1px"
                                w="100%"
                                bg="#E6E6EA"
                            />
                        </Flex>

                        <DetalleNota />
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Flex>
    )
}

export { ModalEditarNotaMobile }