import { isRoleAllowed } from "../../../utils/functions/validateRol"
import { UserRolEnum } from "../../../utils/types/UserRolEnum"
import { HiOutlineBookOpen, HiOutlineCalendar, HiOutlineChat, HiOutlineDocumentDuplicate, HiOutlineTerminal, HiOutlineUserGroup, HiTrendingUp } from "react-icons/hi";
import { TbHome } from "react-icons/tb";
import { BiNote } from "react-icons/bi"
import { GrCertificate } from "react-icons/gr";
import { NavLink } from "./NavLink";
import { useBreakpointValue } from "@chakra-ui/react";
import { UserInt } from "../../../../interfaces/UserInt";

interface Props {
    user: UserInt | null,
    rutas: any,
    activePath: (text: string) => boolean
}


const MenuLinks = ({user, rutas, activePath }: Props) => {
    const isMobile = useBreakpointValue({ base: true, sm: false });
    const fontSizeNavLink = isMobile ? "18px" : "14px";

    return (
        <>
            {isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO], user?.role?.nombre) &&
                <NavLink path={"/dashboard"} icon={TbHome} label={"Resumen"} active={activePath("/dashboard")} fontSize={fontSizeNavLink}/>
            }


            {isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO, UserRolEnum.TUTOR], user?.role?.nombre) &&
                <NavLink path={"/aprendizaje"} icon={HiOutlineBookOpen} label={"Cursos"} active={activePath("/aprendizaje")} fontSize={fontSizeNavLink}/>
            }

            {rutas?.meta?.total > 0 &&
                isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO], user?.role?.nombre) &&
                <NavLink path={"/rutas"} icon={HiOutlineTerminal} label={"Rutas"} active={activePath("/rutas")} fontSize={fontSizeNavLink}/>
            }

            {isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.TUTOR], user?.role?.nombre) &&
                user?.tutorFreelance &&
                <NavLink path={"/facturacion"} icon={HiOutlineDocumentDuplicate} label={"Facturación"} active={activePath("/facturacion")} fontSize={fontSizeNavLink}/>
            }


            {isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.TUTOR], user?.role?.nombre) &&
                <NavLink path={"/matriculas"} icon={HiOutlineUserGroup} label={"Matrículas"} active={activePath("/matriculas")} fontSize={fontSizeNavLink}/>
            }

            {user?.role?.nombre === UserRolEnum.ALUMNO ?
                user?.campusTutorizado &&
                isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO], user?.role?.nombre) &&
                <NavLink path={"/entregables"} icon={HiOutlineDocumentDuplicate} label={"Entregas"} active={activePath("/entregables")} fontSize={fontSizeNavLink}/>
                :
                isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO], user?.role?.nombre) &&
                <NavLink path={"/entregables"} icon={HiOutlineDocumentDuplicate} label={"Entregas"} active={activePath("/entregables")} fontSize={fontSizeNavLink}/>
            }

            {isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.TUTOR], user?.role?.nombre) &&
                <NavLink path={"/correcciones"} icon={HiOutlineCalendar} label={"Correcciones"} active={activePath("/correcciones")} fontSize={fontSizeNavLink}/>
            }

            {user?.role?.nombre === UserRolEnum.ALUMNO ?
                user?.campusTutorizado &&
                isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO, UserRolEnum.TUTOR], user?.role?.nombre) &&
                <NavLink path={"/foro"} icon={HiOutlineUserGroup} label={"Foro"} active={activePath("/foro")} fontSize={fontSizeNavLink}/>
                :
                isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO, UserRolEnum.TUTOR], user?.role?.nombre) &&
                <NavLink path={"/foro"} icon={HiOutlineUserGroup} label={"Foro"} active={activePath("/foro")} fontSize={fontSizeNavLink}/>

            }

            {user?.role?.nombre === UserRolEnum.ALUMNO ?
                user?.campusTutorizado &&
                isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO, UserRolEnum.TUTOR], user?.role?.nombre) &&
                <NavLink path={"/mensajeria"} icon={HiOutlineChat} label={"Mensajería"} active={activePath("/mensajeria")} fontSize={fontSizeNavLink}/>
                :
                isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO, UserRolEnum.TUTOR], user?.role?.nombre) &&
                <NavLink path={"/mensajeria"} icon={HiOutlineChat} label={"Mensajería"} active={activePath("/mensajeria")} fontSize={fontSizeNavLink}/>
            }

            {isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO], user?.role?.nombre) &&
                <NavLink path={"/evaluaciones"} icon={GrCertificate} label={"Evaluaciones"} active={activePath("/evaluaciones")} fontSize={fontSizeNavLink}/>
            }

            {isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO], user?.role?.nombre) &&
                <NavLink path={"/notas"} icon={BiNote} label={"Notas"} active={activePath("/notas")} fontSize={fontSizeNavLink}/>
            }
        </>
    )
}

export { MenuLinks }