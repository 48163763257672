import { Box, Button, Flex, Icon, Image, Text, useColorMode, useDisclosure } from "@chakra-ui/react";
import { LeccionInt } from "../../../../interfaces/CursosInt";
import { useOutWindowContext } from "../../../../shared/context/outWindow.context";
import { useEffect, useRef } from "react";
import { format } from "../../../../shared/utils/functions/formatDate";
import sesionesBg from "../../../../assets/img/bgSesiones.png";
import { BsBroadcast, BsPersonCheckFill } from "react-icons/bs";
import { DateTime } from "luxon";
import { useAuthContext } from "../../../../shared/context/user.context";
import { UserRolEnum } from "../../../../shared/utils/types/UserRolEnum";
import { useData } from "../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../shared/utils/types/EndpointTypes";
import { ModalAsistencias } from "../Modals/ModalAsistencias";
import { ModalFirma } from "../Modals/ModalFirma";

interface Props {
    leccion: LeccionInt | null;
}

export const SesionesViewer = ({ leccion }: Props) => {
    const { colorMode } = useColorMode();
    const { user } = useAuthContext();
    const { outWindow } = useOutWindowContext();
    const { onOpen: onOpenAsistencias, isOpen: isOpenAsistencias, onClose: onCloseAsistencias } = useDisclosure();
    const { onOpen: onOpenFirma, isOpen: isOpenFirma, onClose: onCloseFirma } = useDisclosure();

    const { data: firmas, Refresh } = useData({
        endpoint: EndpointTypes.FIRMAS,
        query: {
            leccion: leccion?.id!
        }
    })

    const exitFullScreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    };

    useEffect(() => {
        if (outWindow)
            exitFullScreen();
    }, [outWindow])

    const validateTimeFirma = () => {
        if (!leccion?.sesionVivo?.fechaInicio || !leccion?.sesionVivo?.fechaFin) return false;

        const ahora = DateTime.now();
        const fechaInicio = DateTime.fromJSDate(new Date(leccion.sesionVivo.fechaInicio));
        const fechaFin = DateTime.fromJSDate(new Date(leccion.sesionVivo.fechaFin));

        const diezMinutosAntes = fechaInicio.minus({ minutes: 10 });
        const dosDiasDespues = fechaFin.plus({ days: 2 });

        return ahora >= diezMinutosAntes && ahora <= dosDiasDespues;
    }

    return (
        <Flex
            h="calc(100vh - 193px)"
            w="100%"
            px={{base: "0px", sm: "10px"}}
            justify='center'
            align='center'
        >
            <Flex
                border="1px solid"
                borderColor={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
                direction="column"
                rounded="20px"
                bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                h="fit-content"
                w="100%"
            >
                <Flex
                    direction="column"
                >
                    <Image
                        src={sesionesBg}
                        h="70px"
                        roundedTopRight="10px"
                        roundedTopLeft="10px"
                    />

                    <Flex
                        // justifyContent="space-between"
                        w="100%"
                        py="40px"
                    >
                        <Flex
                            direction="column"
                            width='100%'
                        >
                            {
                                ((leccion?.sesionVivo?.fechaInicio && leccion?.sesionVivo?.fechaFin) &&
                                    new Date() > new Date(leccion.sesionVivo.fechaInicio) && new Date() < new Date(leccion.sesionVivo.fechaFin)
                                ) &&
                                <Flex
                                    alignItems="center"
                                    gap="5px"
                                    mb="20px"
                                >
                                    <Box
                                        w="17px"
                                        h="17px"
                                        bg="main"
                                        rounded="full"
                                    />

                                    <Text
                                        color="main"
                                        textTransform="uppercase"
                                        fontWeight="600"
                                        fontSize="12px"
                                    >
                                        En directo
                                    </Text>
                                </Flex>
                            }
                            <Flex
                                justify='space-between'
                                px='30px'
                            >
                                <Flex
                                    direction='column'
                                    fontSize={{ base: '13px', md: '18px' }}
                                >
                                    <Text

                                        fontWeight="700"
                                        color="font"
                                    >
                                        {leccion?.modulo?.curso?.nombre}
                                    </Text>

                                    <Text

                                        fontWeight="700"
                                        color="font"
                                    >
                                        {leccion?.modulo?.nombre} - {leccion?.nombre}
                                    </Text>

                                    {
                                        !leccion?.sesionVivo?.online &&
                                        <Text
                                            mt="auto"
                                            color={"main"}
                                            fontSize="16px"
                                            fontWeight="700"
                                        >
                                            Ubicación: <Text as="span" fontWeight="500">{leccion?.contenido}</Text>
                                        </Text>
                                    }
                                </Flex>

                                <Flex
                                    direction="column"
                                    color="font"
                                    fontSize={{ base: "13px", md: '16px' }}
                                    fontWeight="600"
                                    letterSpacing="-0.09px"
                                >

                                    <Text
                                        alignItems="end"
                                        gap="5px"
                                    >
                                        {format({ date: leccion?.sesionVivo?.fechaInicio, customFormat: "dd 'de' MMMM 'de' yyyy" })}
                                    </Text>

                                    <Flex
                                        fontSize={{ base: "13px", md: '16px' }}
                                        alignItems="center"
                                    >
                                        <Text>
                                            {format({ date: leccion?.sesionVivo?.fechaInicio, customFormat: "HH:mm" })}
                                        </Text>

                                        <Text>
                                            -
                                        </Text>

                                        <Text>
                                            {format({ date: leccion?.sesionVivo?.fechaFin, customFormat: "HH:mm" })}
                                        </Text>
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Flex
                                gap="20px"
                                justify='center'
                                px='10px'
                            >
                                {user?.role?.nombre === UserRolEnum.ALUMNO ?
                                    (firmas && firmas?.length !== 0 ?
                                        <Flex
                                            direction="column"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Image
                                                src={firmas[0].firma?.url}
                                                maxW="200px"
                                                alt="Firma"
                                            />

                                            <Text
                                                fontSize="14px"
                                                fontWeight="600"
                                                color="font"
                                            >
                                                Sesión firmada
                                            </Text>
                                        </Flex>
                                        :
                                        
                                            <Button
                                                mt="40px"
                                                bg={colorMode === "dark" ? "transparent" : "#E6E6EA"}
                                                border={colorMode === "dark" ? "1px solid" : "none"}
                                                borderColor={colorMode === "dark" ? "purewhite" : undefined}
                                                color={colorMode === "dark" ? "purewhite" : "pureblack"}
                                                fontWeight="700"
                                                fontSize={{ base: "13px", md: "16px" }}
                                                lineHeight="22px"
                                                letterSpacing="-0.408px"
                                                rounded="10px"
                                                px="25px"
                                                py="10px"
                                                leftIcon={
                                                    <Icon
                                                        as={BsPersonCheckFill}
                                                        boxSize="24px"
                                                    />
                                                }
                                                onClick={(e: React.MouseEvent) => {
                                                    e.stopPropagation()

                                                    onOpenFirma()
                                                }}
                                            >
                                                Confirmar asistencia
                                            </Button>
                                        
                                    )
                                    :
                                    
                                        <Button
                                            mt="40px"
                                            bg={colorMode === "dark" ? "transparent" : "#E6E6EA"}
                                            border={colorMode === "dark" ? "1px solid" : "none"}
                                            borderColor={colorMode === "dark" ? "purewhite" : undefined}
                                            color={colorMode === "dark" ? "purewhite" : "pureblack"}
                                            fontWeight="700"
                                            fontSize="16px"
                                            lineHeight="22px"
                                            letterSpacing="-0.408px"
                                            rounded="10px"

                                            px="16px"
                                            py="10px"
                                            leftIcon={
                                                <Icon
                                                    as={BsPersonCheckFill}
                                                    boxSize="24px"
                                                />
                                            }
                                            onClick={(e: React.MouseEvent) => {
                                                e.stopPropagation()

                                                onOpenAsistencias()
                                            }}
                                        >
                                            Ver asistencias
                                        </Button>
                                    
                                }

                                {leccion?.sesionVivo?.online &&
                                    (new Date() > new Date(leccion.sesionVivo.fechaInicio) && new Date() < new Date(leccion.sesionVivo.fechaFin)
                                        ?
                                        <Button
                                            mt="40px"
                                            bg="main"
                                            color="purewhite"
                                            fontWeight="700"
                                            fontSize="16px"
                                            lineHeight="22px"
                                            letterSpacing="-0.408px"
                                            rounded="10px"

                                            px="16px"
                                            py="10px"
                                            leftIcon={
                                                <Icon
                                                    as={BsBroadcast}
                                                    boxSize="24px"
                                                />
                                            }
                                            onClick={(e: React.MouseEvent) => {
                                                e.stopPropagation()

                                                window.open(leccion?.contenido, '_blank')
                                            }}
                                        >
                                            Unirse a Sesión en Directo
                                        </Button>
                                        :
                                        (new Date() < new Date(leccion.sesionVivo.fechaFin) &&
                                            <Text
                                                mt="40px"
                                                bg={colorMode === "dark" ? "transparent" : "secondary"}
                                                border={colorMode === "dark" ? "1px solid" : "none"}
                                                borderColor={colorMode === "dark" ? "purewhite" : undefined}
                                                fontWeight="700"
                                                fontSize="16px"
                                                lineHeight="22px"
                                                letterSpacing="-0.408px"
                                                rounded="10px"
                                                px="16px"
                                                py="10px"

                                            >
                                                Empieza{" "}
                                                {leccion?.sesionVivo?.fechaInicio &&
                                                    DateTime.fromJSDate(new Date(leccion?.sesionVivo?.fechaInicio)).toRelative({ unit: 'days' })
                                                }
                                            </Text>
                                        )
                                    )
                                }
                            </Flex>
                        </Flex>
                    </Flex>

                    <Box
                        h="1px"
                        bg="#E6E6EA"
                        w="100%"
                        px="40px" />

                    <Flex
                        px="40px"
                        py="40px"
                        gap="40px"
                        direction="column"
                        overflow="auto"
                        css={{
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                            'scrollbarWidth': 'none',
                            '-ms-overflow-style': 'none',
                            scrollBehavior: 'smooth',
                        }}
                        h={"calc(100vh - 475px)"}
                    >
                        <Text
                            fontSize="15px"
                            fontWeight="400"
                            lineHeight="150%"
                        >
                            {leccion?.descripcion}
                        </Text>
                    </Flex>
                </Flex>
            </Flex>

            {(user?.role?.nombre === UserRolEnum.ALUMNO && validateTimeFirma()) &&
                <ModalFirma
                    isOpen={isOpenFirma}
                    onClose={onCloseFirma}
                    leccion={leccion}
                    Refresh={Refresh}
                />
            }

            {user?.role?.nombre === UserRolEnum.TUTOR &&
                <ModalAsistencias
                    isOpen={isOpenAsistencias}
                    onClose={onCloseAsistencias}
                    leccion={leccion}
                />
            }
        </Flex>
    );
};

