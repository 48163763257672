import { Query, useQuery } from "@tanstack/react-query"
import queryString from 'query-string';
import _axios from "../services/http.service";
import { omit } from "lodash";
import tenantInstance from "../services/tenantInstance.service";
import { getData } from "../middlewares/middlewares";
import { EndpointTypes } from "../utils/types/EndpointTypes";

const { tenant, client } = await tenantInstance();

type AdonisErrorInt = {
  field: string;
  message: string;
  rule: string;
};

declare module '@tanstack/react-query' {
  interface Register {
    defaultError: { response: { status: number, data: { errors: any[] } } };
  }
}

interface UseDataProps {
  endpoint: EndpointTypes;
  specificQuery?: Record<string, string | number | string[]> | undefined;
  query?: Record<string, string | number | string[]>;
  ignoreRequest?: boolean;
  refetchInterval?: number | false | ((query: Query) => number | false | undefined)
  track?: { track: boolean, matricula: string | undefined };
  isPublic?: boolean;
  retry?: number;
}

export const useData = ({
  endpoint,
  specificQuery,
  query = {},
  ignoreRequest = false,
  refetchInterval = false,
  track = { track: false, matricula: undefined },
  isPublic = false,
  retry = 1
}: UseDataProps): any => {
  const cleanQuery = omit(query, ['tabIndex']);

  let finalEndpoint = tenant + "/" + (!isPublic ? client : "public") + endpoint

  if (specificQuery) finalEndpoint += `?${queryString.stringify({ ...specificQuery, ...cleanQuery })}`;
  else if (Object.keys(cleanQuery).length) {
    finalEndpoint += `?${queryString.stringify(cleanQuery)}`;
  }

  const queryKey = !isPublic ? [endpoint, client, { ...cleanQuery, ...specificQuery }] : [endpoint, { ...cleanQuery, ...specificQuery }];

  const {
    data,
    isLoading,
    error,
    refetch,
  }: any = useQuery({
    queryKey,
    queryFn: () => getData(finalEndpoint, track),
    enabled: !ignoreRequest,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchOnMount: true,
    refetchInterval: refetchInterval,
    retry: retry,
    staleTime: 0
  })

  return {
    data: data?.data?.data,
    loading: isLoading,
    error: error,
    Refresh: refetch,
    status: !error ? data?.status : error?.response?.status,
    errorsList: error?.response?.data?.errors as AdonisErrorInt[],
  }
}