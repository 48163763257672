import { Button, Flex, Icon, Input, InputGroup, InputLeftElement, Skeleton, useColorMode, useDisclosure } from '@chakra-ui/react'
import ChatSelectorItem from './ChatSelectorItem'
import { BiSearch } from 'react-icons/bi'
import CardCreacion from '../../../shared/components/Cards/CardCreacion';
import { useParams } from 'react-router-dom';
import { UserRolEnum } from '../../../shared/utils/types/UserRolEnum';
import { useAuthContext } from '../../../shared/context/user.context';
import { BiPlus } from 'react-icons/bi'

interface Props {
    conversaciones: any;
    onOpen: () => void;
}

export default function ChatSelector({ conversaciones, onOpen }: Props) {
    const { user } = useAuthContext();
    const { colorMode } = useColorMode()

    const isTutor = user?.role?.nombre === UserRolEnum.TUTOR
    
    return (
        <Flex
            direction="column"
            bg={{base: (colorMode === "dark" ? "bg_dark" : "purewhite"), md: (colorMode === "dark" ? "bg_dark_light" : "purewhite")}}
            border={{base: "none", md: "1px solid"}}
            borderColor={{base: "none", md: (colorMode === "dark" ? "border_variant" : "border")}}
            borderLeftRadius={{base: "0px", md: "20px"}}
            borderRightRadius="0px"
            h="full"
            w={{base: "100%", md: "400px"}}
            overflow="auto"
        >
            <Flex
                align="end"
                direction="column"
                gap="10px"
                p={{base: "15px 10px", md: "15px 24px"}}
            >
                {user?.role?.nombre !== UserRolEnum.TUTOR &&
                    <Button
                        w={{ base: "100%", sm: "fit-content" }}
                        bg="main"
                        color="purewhite"
                        rounded="10px"
                        fontSize="13px"
                        p="6px 12px"
                        fontWeight="500"
                        onClick={() => onOpen()}
                        leftIcon={
                            <Icon
                                as={BiPlus}
                                boxSize="22px"
                            />
                        }
                    >
                        Nueva Duda
                    </Button>
                }

                <InputGroup
                    rounded="8px"
                    h="fit-content"
                    gap="10px"
                    borderColor={colorMode === "dark" ? "border_variant" : "border"}
                    w="100%"
                >
                    <InputLeftElement>
                        <Icon as={BiSearch} color="font_grey" boxSize="16px" />
                    </InputLeftElement>
                    <Input
                        fontSize="12px"
                        placeholder='Buscar conversación'
                    />
                </InputGroup>
            </Flex>

            {!conversaciones?.data ?
                <Flex
                    direction="column"
                    gap="2px"
                    h="calc(100vh - 165px)"
                    css={{
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        'scrollbarWidth': 'none',
                        '-ms-overflow-style': 'none',
                        scrollBehavior: 'smooth',
                    }}
                    overflow="auto"
                >
                    {Array.from({ length: 4 }, (_, i) => i).map((_, index: number) => (
                        <Skeleton
                            key={index}
                            h="130px"
                            w={{base: "100%", sm: "320px"}}

                        />
                    ))}
                </Flex>
                : (
                    !conversaciones || (conversaciones?.meta?.total === 0 && !isTutor) ?
                        <CardCreacion
                            onOpen={onOpen}
                            noBorder
                            buttonText="Crea una Duda"
                            description="Aún no existen dudas, puedes crearlas presionando aquí. Las dudas que vays abriendo podrán ser accedidas desde esta sección."
                        />
                        :
                        conversaciones?.data?.map((conversacion: any, index: number) => (
                            <ChatSelectorItem
                                conversacion={conversacion}
                                key={index}
                            />
                        ))
                )
            }
        </Flex>
    )
}
