import { Box, Button, Flex, Icon, Skeleton, Text, useColorMode, useToast } from "@chakra-ui/react"
import { BiTime } from "react-icons/bi"
import { format } from "../../../shared/utils/functions/formatDate"
import { useState } from "react";
import { Formik, Form as FormikForm } from "formik";
import { FormTextEditor } from "../../../shared/components/FormElements/FormTextEditor";
import MarkdownPreview from "@uiw/react-markdown-preview"
import { motion, AnimatePresence } from "framer-motion"
import { useDataId } from "../../../shared/hooks/useDataId";
import { useParams } from "react-router-dom";
import { EndpointTypes } from "../../../shared/utils/types/EndpointTypes";
import { toastNotify } from "../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../shared/utils/types/StatusEnumTypes";
import { updateNota } from "../../../shared/middlewares/notas.middleware";

export const DetalleNota = () => {
    const { id } = useParams()
    const toast = useToast();
    const [typeState, setTypeState] = useState<"preview" | "edit">("preview")
    const { colorMode } = useColorMode()

    const { data: nota, loading, Refresh } = useDataId({
        endpoint: EndpointTypes.NOTAS,
        id: id!,
    })

    const handleEdit = async (values: { contenido: string | undefined }) => {

        return updateNota({
            id: id!,
            data: values
        })
            .then(() => Refresh())
            .catch(() => toastNotify(toast, StatusEnumTypes.ERROR, "Error al actualizar"));
    }

    const isExistsChanges = (
        values: { contenido: string | undefined }
    ) => {
        const initContent = nota?.contenido?.replaceAll("<p><br></p>", "")?.replaceAll("\n", "")
        const newContent = values?.contenido?.replaceAll("<p><br></p>", "")?.replaceAll("\n", "")

        if (initContent !== newContent) return true

        return false
    }

    return (
        loading ?
            <Skeleton
                rounded="10px"
                w="100%"
                h="100%"
            />
            :

            <Flex
                px={{base: "10px", sm: "30px"}}
                pt={{ base: "0px", sm: "30px" }}
                direction="column"
                borderColor={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
                w="100%"
                rounded="10px"
                h="100%"
            >
                <Formik
                    onSubmit={handleEdit}
                    enableReinitialize
                    initialValues={{ contenido: nota?.contenido }}
                >
                    {(formik) => {
                        const { handleSubmit } = formik;

                        return (
                            <FormikForm
                                onSubmit={handleSubmit}
                            >
                                <Flex
                                    justifyContent="space-between"
                                    alignItems={{base: "start", md: "center"}}
                                    w="100%"
                                    mb="25px"
                                    gap="25px"
                                    direction={{base: 'column', md: 'row'}}
                                >
                                    <Flex
                                        direction={{ base: "column", sm: "row" }}
                                        gap="20px"
                                        w={{base: "fit-content", sm: "100%"}}
                                        alignItems={{base: "start", md: "center"}}
                                    >
                                        <Text
                                            fontSize="24px"
                                            fontWeight="600"
                                            color="font"
                                            letterSpacing="-0.9px"
                                        >
                                            {nota?.titulo}
                                        </Text>

                                        <Text
                                            fontSize="12px"
                                            fontWeight="400"
                                            color="font"
                                            bg={colorMode === "dark" ? "main" : "main_variant"}
                                            rounded="full"
                                            px="10px"
                                            py="5px"
                                        >
                                            {nota?.leccion?.nombre}
                                        </Text>

                                        <Flex
                                            alignItems="center"
                                            gap="4px"
                                        >
                                            <Icon
                                                as={BiTime}
                                                boxSize="16px"
                                                color={"#959596"}
                                            />


                                            <Text
                                                fontSize="12px"
                                                fontWeight="400"
                                                color="#959596"
                                            >
                                                {format({ date: nota?.createdAt, isDateTime: true, customFormat: "dd/MM HH:mm" })}
                                            </Text>
                                        </Flex>
                                    </Flex>

                                    {isExistsChanges(formik?.values) ?
                                        <Flex
                                            gap="15px"
                                        >
                                            <Button
                                                h="fit-content"
                                                w="fit-content"
                                                py="8px"
                                                px="10px"
                                                rounded="8px"
                                                fontSize="14px"
                                                fontWeight="700"
                                                lineHeight="22px"
                                                letterSpacing="-0.408px"
                                                bg="transparent"
                                                _hover={{ bg: "variant", color: "purewhite" }}
                                                isDisabled={!isExistsChanges(formik?.values)}
                                                onClick={(e: React.MouseEvent) => {
                                                    e.stopPropagation()

                                                    formik.resetForm()
                                                }}
                                            >
                                                Descartar cambios
                                            </Button>

                                            <Button
                                                h="fit-content"
                                                w="fit-content"
                                                py="8px"
                                                px="10px"
                                                rounded="8px"
                                                fontSize="14px"
                                                fontWeight="700"
                                                lineHeight="22px"
                                                letterSpacing="-0.408px"
                                                color={colorMode === "dark" ? "purewhite" : "font"}
                                                bg="main"
                                                type="submit"
                                                isDisabled={!isExistsChanges(formik?.values)}
                                            >
                                                Guardar
                                            </Button>
                                        </Flex>
                                        :
                                        <Flex
                                            gap="15px"
                                        >
                                            <Button
                                                h="fit-content"
                                                w="fit-content"
                                                py="8px"
                                                px="10px"
                                                rounded="8px"
                                                fontSize="14px"
                                                fontWeight="700"
                                                lineHeight="22px"
                                                letterSpacing="-0.408px"
                                                color={typeState === "preview" ? "purewhite" : colorMode === "dark" ? "purewhite" : "font"}
                                                bg={typeState === "preview" ? "main" : "transparent"}
                                                _hover={{ bg: "variant", color: "purewhite" }}
                                                onClick={() => setTypeState("preview")}
                                            >
                                                Previsualizar
                                            </Button>

                                            <Button
                                                h="fit-content"
                                                w="fit-content"
                                                py="8px"
                                                px="10px"
                                                rounded="8px"
                                                fontSize="14px"
                                                fontWeight="700"
                                                lineHeight="22px"
                                                letterSpacing="-0.408px"
                                                color={typeState === "edit" ? "purewhite" : colorMode === "dark" ? "purewhite" : "font"}
                                                bg={typeState === "edit" ? "main" : "transparent"}
                                                _hover={{ bg: "variant", color: "purewhite" }}
                                                onClick={() => setTypeState("edit")}
                                            >
                                                Editar
                                            </Button>
                                        </Flex>
                                    }
                                </Flex>

                                <Box
                                    h="1px"
                                    w="100%"
                                    bg="#E6E6EA"
                                />

                                <AnimatePresence mode="wait">
                                    <Flex
                                        direction="column"
                                        maxH="calc(100vh - 290px)"
                                        css={{
                                            '&::-webkit-scrollbar': {
                                                display: 'none',
                                            },
                                            'scrollbarWidth': 'none',
                                            '-ms-overflow-style': 'none',
                                            scrollBehavior: 'smooth',
                                        }}
                                        overflow="auto"
                                    >
                                        {typeState === "edit" ? (
                                            <motion.div
                                                key="edit"
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0 }}
                                                transition={{ duration: 0.3 }}
                                                style={{ height: "100%" }}
                                            >
                                                <Flex
                                                    direction="column"
                                                    flex="1"
                                                >
                                                    <FormTextEditor
                                                        height="200px"
                                                        name='contenido'
                                                        onChange={formik.setFieldValue}
                                                        placeholder="Empieza a escribir tu nota"
                                                        styles={{
                                                            border: "none",
                                                            padding: "0",
                                                            fontSize: "15px",
                                                            fontWeight: "400",
                                                            lineHeight: "150%",
                                                            minHeight: "400px",
                                                            width: '100%',
                                                            color: colorMode === "dark" ? "#FFFFFF" : "#000000",
                                                            backgroundColor: colorMode === "dark" ? "bg_dark_light" : "purewhite",
                                                        }}
                                                    />
                                                </Flex>
                                            </motion.div>
                                        ) : (
                                            <motion.div
                                                key="preview"
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0 }}
                                                transition={{ duration: 0.3 }}
                                            >
                                                <Flex mt="25px">
                                                    <MarkdownPreview
                                                        source={nota?.contenido}
                                                        style={{
                                                            backgroundColor: "transparent",
                                                            fontFamily: "Inter",
                                                            color: colorMode === "dark" ? "#FFFFFF" : "#000000",
                                                            fontSize: "14px",
                                                            fontWeight: "400",
                                                        }}
                                                        wrapperElement={{ "data-color-mode": colorMode === "dark" ? "dark" : "light" }}
                                                    />
                                                </Flex>
                                            </motion.div>
                                        )}
                                    </Flex>
                                </AnimatePresence >
                            </FormikForm>
                        );
                    }}
                </Formik>
            </Flex >
    )
}