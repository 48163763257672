import { Flex, Icon, Text, Link, useColorMode, useBreakpointValue, useDisclosure } from "@chakra-ui/react"
import { NotasInt } from "../../../interfaces/NotasInt"
import MarkdownPreview from "@uiw/react-markdown-preview"
import { truncate } from "lodash"
import { format } from "../../../shared/utils/functions/formatDate"
import { BiTime } from "react-icons/bi"
import { Route, Link as RouterLink, Routes, useParams } from "react-router-dom"
import { ModalEditarNotaMobile } from "./ModalEditarNotaMobile"

interface Props {
    notas: {
        data: NotasInt[]
    };
}

export const ListadoNotas = ({ notas }: Props) => {
    const params = useParams()
    const { colorMode } = useColorMode()
    const isMobile = useBreakpointValue({ base: true, md: false });
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Flex
            direction="column"
            gap="20px"
            h={{base: "calc(100vh - 250px)", sm: "calc(100vh - 190px)"}}
            css={{
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
                'scrollbarWidth': 'none',
                '-ms-overflow-style': 'none',
                scrollBehavior: 'smooth',
            }}
            overflow="auto"
            w={{ base: "100%", md: "360px" }}
        >
            {notas?.data?.map((n: NotasInt) => (
                <Link
                    key={n?.id}
                    to={`${n?.id}`}
                    as={RouterLink}
                    display="flex"
                    flexDirection="column"
                    gap="20px"
                    mr={{ base: "0px", md: "20px" }}
                    onClick={isMobile ? onOpen: ()=>{}}
                    rounded="10px"
                    border="1px solid"
                    bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                    borderColor={params["*"] && (params["*"] === n?.id) ? "main" : colorMode === "dark" ? "border_variant" : "#E6E6EA"}
                    p="20px"
                    cursor="pointer"
                    _hover={{ textDecoration: "none" }}
                >
                    <Text
                        fontSize="18px"
                        fontWeight="600"
                        letterSpacing="-0.9px"
                        color="font"
                    >
                        {n?.titulo}
                    </Text>

                    <MarkdownPreview
                        source={truncate(n?.contenido, { length: 85 })}
                        style={{
                            backgroundColor: "transparent",
                            fontFamily: "Inter",
                            color: "#959596",
                            fontSize: "14px",
                            fontWeight: "400",
                        }}
                        wrapperElement={{ "data-color-mode": "light" }}
                    />

                    <Flex
                        justify="space-between"
                        alignItems="center"
                    >
                        <Text
                            fontSize="12px"
                            fontWeight="400"
                            color="font"
                            bg={colorMode === "dark" ? "main" : "main_variant"}
                            rounded="full"
                            px="10px"
                            py="5px"
                        >
                            {truncate(n?.leccion?.nombre, { length: 20 })}
                        </Text>

                        <Flex
                            alignItems="center"
                            gap="4px"
                        >
                            <Icon
                                as={BiTime}
                                boxSize="16px"
                                color={"#959596"}
                            />


                            <Text
                                fontSize="14px"
                                fontWeight="400"
                                color="#959596"
                            >
                                {format({ date: n?.createdAt, isDateTime: true, customFormat: "dd/MM HH:mm" })}
                            </Text>
                        </Flex>
                    </Flex>
                </Link>
            ))}
            <Routes>
                <Route
                    path="/:id"
                    element={
                        <ModalEditarNotaMobile isOpen={isOpen} onClose={onClose} />
                    }
                />
            </Routes>
        </Flex>
    )
}