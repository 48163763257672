import { Flex, Skeleton, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { EntregablesInt } from "../../../../interfaces/Entregables";
import { MatriculasInt } from "../../../../interfaces/MatriculasInt";
import { LeccionInt } from "../../../../interfaces/CursosInt";
import { EntregableEstadoEnum, ModeEnumEntregable } from "../../../../shared/utils/types/EntregableEstadoEnum";
import { useData } from "../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../shared/utils/types/EndpointTypes";
import { corrigeEjercicio } from "../../../../shared/middlewares/entregables.middleware";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/types/StatusEnumTypes";
import { ComponenteLeccionEnum, LeccionTipoEnum } from "../../../../shared/utils/types/LeccionTipoEnum";
import { UserRolEnum } from "../../../../shared/utils/types/UserRolEnum";
import { useAuthContext } from "../../../../shared/context/user.context";
import { EnunciadoTutor } from "../EntregableItems/EnunciadoTutor";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { AutocorregibleEnunciado } from "../AutocorregibleItems/AutocorregibleEnunciado";
import { AutocorregibleRealizado } from "../AutocorregibleItems/AutocorregibleRealizado";

interface Props {
  leccion: LeccionInt | undefined;
  setEstadoEntregable: (estado: EntregableEstadoEnum) => void;
  endPrev: boolean;
  matriculaActual: MatriculasInt | undefined;
  componentSubtype: ComponenteLeccionEnum | undefined;
  onNextLeccion: () => void;
}

export const Autocorregible = ({ leccion, setEstadoEntregable, endPrev, matriculaActual, componentSubtype, onNextLeccion }: Props) => {
  const { user } = useAuthContext()
  const toast = useToast();
  const [loadingCorreccion, setLoadingCorreccion] = useState<boolean>(false)

  const { data: entregables, loading, Refresh } = useData({
    endpoint: EndpointTypes.ENTREGABLES,
    query: {
      leccionId: leccion?.id!,
      matriculaId: matriculaActual?.id!,
      sortBy: "createdAt",
      order: "desc"
    },
    ignoreRequest: !leccion?.id || !matriculaActual?.id
  })
  const [entregable, setEntregable] = useState<EntregablesInt | null>(null)
  const [mode, setMode] = useState<ModeEnumEntregable>();

  useEffect(() => {
    if (loading || !entregables?.data || leccion?.tipo !== LeccionTipoEnum.ENTREGABLE) return

    if (entregables?.data?.length !== 0) {
      const _entregable = entregables?.data[0];

      setEntregable(_entregable)
      setEstadoEntregable(_entregable?.estado)
    } else {
      setEntregable(null)
      setMode(ModeEnumEntregable.ENTREGA);
      setEstadoEntregable(EntregableEstadoEnum.PENDIENTE_ENTREGA)
    }

  }, [leccion, entregables])

  useEffect(() => {
    if (entregable) {
      if (entregable?.estado === EntregableEstadoEnum.PENDIENTE_ENTREGA) setMode(ModeEnumEntregable.ENTREGA);
      if (entregable?.estado === EntregableEstadoEnum.PENDIENTE_CORRECCION) setMode(ModeEnumEntregable.CORRECCION);
      if (entregable?.estado === EntregableEstadoEnum.ERROR || entregable?.estado === EntregableEstadoEnum.CORRECTO) setMode(ModeEnumEntregable.FINALIZADO);
    }

  }, [entregable]);

  const realizarCorreccion = async (respuesta: string) => {
    setLoadingCorreccion(true);

    await corrigeEjercicio({
      slug: leccion?.slug!,
      data: {
        matriculaId: matriculaActual?.id!,
        respuesta: respuesta
      }
    })
      .then(() => Refresh())
      .catch((error: any) => {
        const errors = handleErrors(
          error?.response?.data?.errors,
          error?.response?.status
        )

        errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
      })
      .finally(() => setLoadingCorreccion(false))
  }

  return (
    user?.role?.nombre === UserRolEnum.ALUMNO &&
      (mode === undefined || mode === null) ?
      <Skeleton
        w="100%"
        h="calc(100vh - 193px)"
        rounded="20px"
      />
      :
      <Flex
        boxSize="100%"
        direction="column"
      >
        {(user?.role?.nombre === UserRolEnum.TUTOR || user?.role?.nombre === UserRolEnum.ADMIN) &&
          <EnunciadoTutor
            leccion={leccion}
            componentSubtype={componentSubtype}
          />
        }

        {(mode === ModeEnumEntregable.ENTREGA) && user?.role?.nombre === UserRolEnum.ALUMNO &&
          <AutocorregibleEnunciado
            leccion={leccion}
            realizarCorreccion={realizarCorreccion}
            componentSubtype={componentSubtype}
            loading={loadingCorreccion}
          />
        }


        {(mode === ModeEnumEntregable.CORRECCION || mode === ModeEnumEntregable.FINALIZADO) && user?.role?.nombre === UserRolEnum.ALUMNO &&
          <AutocorregibleRealizado
            leccion={leccion}
            entregable={entregable}
            realizarCorreccion={realizarCorreccion}
            componentSubtype={componentSubtype}
            loading={loadingCorreccion}
          />
        }
      </Flex>
  );
};