import { Flex, Icon, Image, Modal, ModalContent, ModalOverlay, Text, useBreakpointValue, useColorMode, useDisclosure } from "@chakra-ui/react";
import { useEffect } from "react";
import { useOutWindowContext } from "../../../../shared/context/outWindow.context";
import { LeccionInt } from "../../../../interfaces/CursosInt";
import { capitalizeFirst } from "../../../../shared/utils/functions/textTransform";
import { HiOutlinePaperClip } from "react-icons/hi";
import { BsArrowsFullscreen } from "react-icons/bs";
import { PreviewTipoEnum } from "../../../../shared/utils/types/LeccionTipoEnum";

interface Props {
    leccion: LeccionInt;
    tipo?: PreviewTipoEnum;
}

export const ImageViewer = ({
    leccion,
    tipo = PreviewTipoEnum.TEORIA
}: Props) => {
    const { colorMode } = useColorMode();
    const { outWindow } = useOutWindowContext()
    const { onOpen: onOpenFullScreen, onClose: onCloseFullScreen, isOpen: isOpenFullScreen } = useDisclosure();
    const isMobile = useBreakpointValue({ base: true, sm: false });

    const exitFullScreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    };

    useEffect(() => {
        if (outWindow)
            exitFullScreen();
    }, [outWindow])

    return (
        <Flex
            p={tipo === PreviewTipoEnum.TEORIA ? (isMobile ? "20px" : "40px") : "0"}
            border={tipo === PreviewTipoEnum.TEORIA ? "1px solid" : "none"}
            w="100%"
            borderColor={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
            direction="column"
            rounded="20px"
            gap="40px"
            bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
            justifyContent="center"
        >
            <Flex
                direction="column"
                display={tipo === PreviewTipoEnum.TEORIA ? "flex" : "none"}
            >
                <Text
                    color="font"
                    fontSize="24px"
                    fontStyle="normal"
                    fontWeight="700"
                >
                    {leccion?.nombre}
                </Text>

                <Flex
                    gap="10px"
                    alignItems="center"
                >
                    <Icon
                        as={HiOutlinePaperClip}
                        color="#A5A8B3"
                        boxSize="20px"
                    />

                    <Text
                        color="#A5A8B3"
                        fontSize="15px"
                        fontWeight="400"
                        lineHeight="160%"
                        letterSpacing="0.075px"
                    >
                        Lección
                    </Text>
                </Flex>
            </Flex>

            <Flex
                w="100%"
                direction="column"
                justifyContent="center"
                gap="5px"
                alignItems="center"
            >
                <Image
                    src={leccion?.contenido}
                    alt="imagen"
                    h="calc(100vh - 410px)"
                    objectFit={"contain"}
                />

                <Flex
                    bg="#444444"
                    w="100%"
                    justifyContent="end"
                    py="5px"
                    px="10px"
                    rounded="4px"
                >
                    <Icon
                        as={BsArrowsFullscreen}
                        boxSize="20px"
                        mr="10px"
                        color="purewhite"
                        cursor="pointer"
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();

                            onOpenFullScreen();
                        }}
                    />
                </Flex>
            </Flex>

            <ModalFullScreen
                isOpen={isOpenFullScreen}
                onClose={onCloseFullScreen}
                leccion={leccion}
            />
        </Flex>
    );
};


interface PropsModal {
    isOpen: boolean;
    onClose: () => void;
    leccion: LeccionInt | undefined;
}

const ModalFullScreen = ({ isOpen, onClose, leccion }: PropsModal) => {

    return (
        <Modal
            size="full"
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />

            <ModalContent>
                <Flex
                    w="100%"
                    direction="column"
                    justifyContent="center"
                    gap="5px"
                    alignItems="center"
                >
                    <Image
                        src={leccion?.contenido}
                        alt="imagen"
                        h={"calc(100vh - 35px)"}
                        objectFit={"contain"}
                    />

                    <Flex
                        bg="#444444"
                        w="100%"
                        justifyContent="end"
                        py="5px"
                        px="10px"
                        rounded="4px"
                    >
                        <Icon
                            as={BsArrowsFullscreen}
                            boxSize="20px"
                            mr="10px"
                            color="purewhite"
                            cursor="pointer"
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation();

                                onClose();
                            }}
                        />
                    </Flex>
                </Flex>
            </ModalContent>
        </Modal>
    )
}