import { Flex, Text, useColorMode } from "@chakra-ui/react"
import { Navigate, Route, Routes, useParams } from "react-router-dom"
import { FormRecovery } from "./views/FormRecovery"
import { SolicitudRecovery } from "./views/SolicitudRecovery"

export const Recovery = () => {
    const params = useParams()
    const { colorMode } = useColorMode();

    if (!params['*']) return (
        <Navigate to={"solicitud"} />
    )
    else {
        <Navigate to={params['*']} />
    }

    return (
        <Flex
            justify="center"
            align="center"
            direction="column"
            w="100%"
            bg={colorMode === "dark" ? "bg_dark" : "purewhite"}
            h="100vh"
        >
            <Flex
            mt='auto'
            >
                <Routes>
                    <Route
                        path="/:hash"
                        element={<FormRecovery />}
                    />

                    <Route
                        path="/solicitud"
                        element={<SolicitudRecovery />}
                    />
                </Routes>
            </Flex>

            <Flex
                mt="auto"
                gap="20px"
                alignItems="center"
                justifyContent="center"
                mb='20px'
            >
                <Text
                    color="#696969"
                    fontSize="14px"
                    fontWeight="400"
                >
                    Política de privacidad
                </Text>

                <Text
                    color="#696969"
                    fontSize="14px"
                    fontWeight="400"
                >
                    Contáctanos
                </Text>
            </Flex>
        </Flex>
    )
}