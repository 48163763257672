import { Flex, Icon, Skeleton, StyleProps, Text, Box, useBreakpointValue, useColorMode } from "@chakra-ui/react"
import { BiChevronRight } from "react-icons/bi"
import { useEffect, useState } from "react"
import { Pagination } from "./components/Pagination"
import { CardSinInformacion, TypeCardSinInformacion } from "../Cards/CardSinInformacion";

export interface CustomColumnProps {
    key: string;
    field: string;
    header: string | JSX.Element;
    sortable?: boolean;
    body?: (data: any) => JSX.Element | undefined;
    style?: StyleProps;
}

interface Props {
    data: any[]
    loading: boolean
    columns: CustomColumnProps[]
    total?: number
    columnsGroup?: CustomColumnProps[]
    groupByKey?: string
    setQuery?: (query: Record<string, string | number | string[]>) => void
    query?: Record<string, string | number | string[]>
    outContainer?: boolean
    showFooter?: boolean
    typeSinInformacion: TypeCardSinInformacion
}

export const Table = ({
    data,
    loading,
    columns,
    total,
    setQuery = () => { },
    query = {},
    columnsGroup,
    groupByKey,
    outContainer = false,
    showFooter = true,
    typeSinInformacion
}: Props) => {
    const [sortBy, setSortBy] = useState<string | undefined>();
    const [sortOrder, setSortOrder] = useState<0 | 1 | -1 | null | undefined>();
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<10 | 15 | 20 | 25 | 50 | 75 | 100 | 200>(10);
    const { colorMode } = useColorMode();
    const isMobile = useBreakpointValue({ base: true, sm: false });

    useEffect(() => {
        let options = Object.fromEntries(Object.entries(query).filter(q => q[0] !== "page" && q[0] !== "limit" && q[0] !== "sortBy" && q[0] !== "order"))
        options = sortOrder === 1 ? { order: "asc", ...options } : sortOrder === -1 ? { order: "desc", ...options } : { ...options };
        options = sortBy !== undefined ? { sortBy: sortBy, ...options } : { ...options };

        setQuery({
            page: page,
            limit: limit,
            ...options
        });

    }, [page, sortBy, sortOrder, limit]);

    const [rowOpen, setRowOpen] = useState<{
        id: string,
        isOpen: boolean
    }[]>([])


    return (
        <Flex
            direction="column"
            w={{ base: "calc(100vw - 20px)", sm: "100%" }}
            p={!outContainer ? "20px" : "0"}
            bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
            borderRadius="14px"
            border={!outContainer ? "1px solid" : "none"}
            borderColor={colorMode === "dark" ? "border_variant" : "#DFE2E6"}
            gap="20px"
        >
            <Box w="100%" overflowX="auto">
                <Flex
                    direction="column"
                    alignItems="start"
                    w="100%"
                    minWidth="400px"
                >
                    <Flex
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                        pt={{ base: "0px", sm: "15px" }}
                        pl={{ base: "16px", md: "0px" }}
                        pb="15px"
                        borderRadius="8px"
                        bg={colorMode === "dark" ? "bg_dark_light" : "#F9FAFB"}
                    >

                        {columns.map((column) => (
                            isMobile && (column?.header === "Observaciones" || column?.header === "Fecha de entrega") ? null : (
                                <Text
                                    px={{ base: "0px", md: "16px" }}
                                    key={column?.key}
                                    fontSize="14px"
                                    fontWeight="500"
                                    h="100%"
                                    w={column?.style?.w || column?.style?.width || `${100 / columns.length}%`}
                                    {...column?.style}
                                    textTransform="capitalize"
                                >
                                    {column?.header}
                                </Text>
                            )
                        ))}
                    </Flex>

                    {!loading ?
                        data?.length !== 0 ?
                            data?.map((item: any, index: number) => (
                                <Flex
                                    key={index}
                                    direction="column"
                                    w="100%"
                                >
                                    <Flex
                                        width="100%"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        px={{ base: "0px", md: "5px" }}
                                        py="12px"
                                        borderBottom="1px dashed"
                                        borderColor={colorMode === "dark" ? "border_variant" : "border"}
                                    >
                                        {groupByKey &&
                                            (item[groupByKey] && item[groupByKey]?.length > 0) &&
                                            <Flex
                                                w={{ base: "0px", md: "40px" }}
                                            >
                                                <Flex
                                                    rounded="100%"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <Icon
                                                        color="#616675"
                                                        _active={{
                                                            scale: 0.9
                                                        }}
                                                        cursor={"pointer"}
                                                        as={BiChevronRight}
                                                        boxSize="24px"
                                                        transition={'transform 0.3s ease'}
                                                        transform={rowOpen.find((i: any) => i.id === item.id)?.isOpen ? 'rotate(90deg)' : 'rotate(0deg)'}
                                                        onClick={() => {
                                                            setRowOpen((prev: any) => {
                                                                const findItem = prev.find((i: any) => i.id === item.id)

                                                                if (findItem) {
                                                                    return prev.filter((i: any) => i.id !== item.id)
                                                                } else {
                                                                    return [...prev, { id: item.id, isOpen: true }]
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </Flex>
                                            </Flex>
                                        }

                                        {columns?.map((column, index) => (
                                            isMobile && (column?.header === "Observaciones" || column?.header === "Fecha de entrega") ? null : (
                                                <Flex
                                                    key={index}
                                                    w={column?.style?.w || column?.style?.width || `${100 / columns.length}%`}
                                                    {...column?.style}
                                                >
                                                    {isMobile && (column?.header === "Observaciones" || column?.header === "Fecha de entrega") ? null :
                                                        column?.body && column?.body(item)}
                                                </Flex>
                                            )
                                        ))}
                                    </Flex>

                                    <Flex
                                        display={rowOpen.find((i: any) => i.id === item.id)?.isOpen ? "block" : "none"}
                                        transition={"all 0.3s ease"}
                                        direction="column"
                                        ml="3%"
                                    >
                                        <Flex
                                            width="100%"
                                            justifyContent={"space-between"}
                                            alignItems="center"
                                            py="7px"
                                            px="45px"
                                            borderRadius="8px"
                                            bg="#F9FAFB"
                                        >
                                            {columnsGroup?.map((column) => (
                                                <Text
                                                    key={column?.key}
                                                    fontSize="14px"
                                                    fontWeight="500"
                                                    h="100%"
                                                    w={column?.style?.w || column?.style?.width || `${100 / columnsGroup.length}%`}
                                                    {...column?.style}
                                                >
                                                    {column?.header}
                                                </Text>
                                            ))}
                                        </Flex>

                                        {groupByKey &&
                                            item[groupByKey]?.map((group: any, iGrupos: number) => (
                                                <Flex
                                                    width="100%"
                                                    justifyContent={"space-between"}
                                                    alignItems="center"
                                                    px="45px"
                                                    key={iGrupos}
                                                    gap="8px"
                                                >
                                                    {columnsGroup?.map((column, index) => (
                                                        <Flex
                                                            key={index}
                                                            w={column?.style?.w || column?.style?.width || `${100 / columnsGroup.length}%`}
                                                            {...column?.style}
                                                        >
                                                            {column?.body && column?.body(group)}
                                                        </Flex>
                                                    ))}
                                                </Flex>
                                            ))}
                                    </Flex>
                                </Flex>
                            ))
                            :
                            <Flex
                                w="100%"
                                justifyContent="center"
                            >
                                <CardSinInformacion
                                    type={typeSinInformacion}
                                />
                            </Flex>
                        :
                        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                            <Flex
                                w="100%"
                                justifyContent="space-between"
                                alignItems="center"
                                key={item}
                                borderBottom={item === 9 ? "none" : colorMode === "dark" ? "1px solid #2E3141" : "1px solid #DFE2E6"}
                            >
                                {columns.map((column, index: number) => (
                                    <Flex
                                        px="5px"
                                        py="16px"
                                        w={column?.style?.w || column?.style?.width || `${100 / columns.length}%`}
                                    >
                                        <Skeleton
                                            key={index}
                                            w="100%"
                                            h="42px"
                                            borderRadius="8px"
                                        />
                                    </Flex>
                                ))}
                            </Flex>
                        ))
                    }
                </Flex>

            </Box>

            {showFooter &&
                <Pagination
                    page={page}
                    setPage={setPage}
                    limit={limit}
                    setLimit={setLimit}
                    total={total || 0}
                    loading={loading}
                    lastPage={total !== 0 ? Math.ceil((total || 0) / limit) : 1}
                    firstPage={1}
                />
            }
        </Flex>
    )
}
