import { Flex, Text, useColorMode } from "@chakra-ui/react"

export const CardFinalizacionSinMatricula = () => {
    const { colorMode } = useColorMode()

    return (
        <Flex
            w={{base: "100%", md: "50%"}}
            p="20px"
            border="1px solid"
            borderColor={colorMode === "dark" ? "border_variant" : "border"}
            rounded="16px"
            bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
            direction="column"
            gap="30px"
            h="255px"
            alignItems="center"
        >
            <Text
                fontSize="20px"
                fontWeight="700"
                color="font"
            >
                Aún no estás matriculado en ningún curso
            </Text>

            <Text
                fontSize="14px"
                fontWeight="400"
                color="font"
            >
                Si no ves ningún curso asignado, por favor <Text as="strong" color="main">contacta con soporte</Text> para resolver esta situación. Estamos aquí para ayudarte a comenzar tu viaje de aprendizaje.
            </Text>
        </Flex>
    )
}