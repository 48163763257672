import { Flex, Icon, Text, useBreakpointValue, useColorMode } from "@chakra-ui/react"
import SelectorCurso from "./SelectorCurso"
import { MatriculasInt } from "../../../interfaces/MatriculasInt";
import { RiBookMarkLine } from "react-icons/ri";
import { BiTime } from "react-icons/bi";

interface Props {
    onSelectMatricula: (matricula: MatriculasInt) => void;
    matriculas: MatriculasInt[];
    selectedMatricula: MatriculasInt | undefined;
    totalEntregables: number;
    entregablesAprobados: number;
}
export const HeaderEntregables = ({
    onSelectMatricula,
    matriculas,
    selectedMatricula,
    totalEntregables,
    entregablesAprobados
}: Props) => {
    const { colorMode } = useColorMode();
    const isMobile = useBreakpointValue({ base: true, sm: false });

    return (
        <Flex
            h="fit-content"
            w="100%"
            alignItems="center"
            justifyContent="space-between"
            gap="10px"
            direction={{ base: "column", md: "row" }}
        >
            <SelectorCurso
                onSelectMatricula={onSelectMatricula}
                matriculas={matriculas}
                selectedMatricula={selectedMatricula}
                hideBorder
            />

            <Flex
                w={{ base: "100%", sm: "fit-content" }}
                alignItems="center"
                gap={{ base: "20px", md: "12px" }}
                mt={{ base: "20px", md: "0px" }}
            >
                <Flex
                    w={{ base: "100%", sm: "275px" }}
                    h="fit-content"
                    bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                    border="1px solid"
                    borderColor={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
                    rounded="20px"
                    py="12px"
                    px={{ base: "12px", sm: "18px" }}
                    gap={{ base: "8px", md: "20px" }}
                >
                    <Flex
                        p="10px"
                        bg={colorMode === "dark" ? "main" : "variant"}
                        rounded="10px"
                        h="fit-content"
                    >
                        <Icon
                            as={RiBookMarkLine}
                            boxSize="32px"
                            color={colorMode === "dark" ? "purewhite" : "main"}
                        />
                    </Flex>

                    <Flex
                        gap="5px"
                        justifyContent="flex-start"
                        direction="column"
                    >
                        <Text
                            color="#8C909C"
                            fontWeight="700"
                            fontSize="14px"
                        >
                            {isMobile ? "Nota Media" : "Nota Media General"}
                        </Text>

                        <Text
                            fontSize="18px"
                            fontWeight="700"
                            color="font"
                        >
                            {
                                selectedMatricula?.puntuacionMedia || selectedMatricula?.puntuacionMedia === 0
                                    ? selectedMatricula?.puntuacionMedia
                                    : "-"
                            }
                        </Text>
                    </Flex>
                </Flex>

                <Flex
                    w={{ base: "100%", sm: "275px" }}
                    h="fit-content"
                    bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                    border="1px solid"
                    borderColor={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
                    rounded="20px"
                    py="12px"
                    px={{ base: "12px", sm: "18px" }}
                    gap={{ base: "8px", md: "20px" }}
                >
                    <Flex
                        p="10px"
                        bg={colorMode === "dark" ? "main" : "variant"}
                        rounded="10px"
                        h="fit-content"
                    >
                        <Icon
                            as={BiTime}
                            boxSize="32px"
                            color={colorMode === "dark" ? "purewhite" : "main"}
                        />
                    </Flex>

                    <Flex
                        gap="5px"
                        justifyContent="flex-start"
                        direction="column"
                    >
                        <Text
                            color="#8C909C"
                            fontWeight="700"
                            fontSize="14px"
                        >
                            {isMobile ? "Entregas Apb." : "Entregables Aprobados"}
                        </Text>

                        <Text
                            fontSize="18px"
                            fontWeight="700"
                            color="font"
                        >
                            {entregablesAprobados}/{totalEntregables}
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}