import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useColorMode } from "@chakra-ui/react";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    enviarAlumnos: () => void;
}

export default function ModalConfirmacion({ isOpen, onClose, enviarAlumnos }: Props) {
    const { colorMode } = useColorMode();

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Confirmar envío</ModalHeader>
                <ModalBody>
                    Una vez se envíen los datos no podrán ser modificados.
                </ModalBody>
                <ModalFooter>
                    <Button
                        bg="transparent"
                        color={colorMode === "dark" ? "white" : "main"}
                        border="2px solid"
                        borderColor={colorMode === "dark" ? "white" : "main"}
                        mr={3}
                        onClick={onClose}
                    >
                        Cancelar
                    </Button>
                    <Button
                        bg="main"
                        color="white"
                        onClick={enviarAlumnos}
                    >
                        Aceptar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
