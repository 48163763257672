import { AllCommunityModule, ModuleRegistry, provideGlobalGridOptions } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useState, useMemo, useEffect } from 'react';
import { ColDef } from 'ag-grid-community';
import { CategoriaProfesionalOptions } from '../../../shared/utils/types/TipoCategoriaProfesional copy';
import { NivelEstudiosOptions } from '../../../shared/utils/types/TipoNivelEstudios';
import { themeQuartz } from 'ag-grid-community';
import { colorSchemeDarkBlue } from 'ag-grid-community';
import { Button, Flex, useColorMode, useToast, HStack, Icon, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from '@chakra-ui/react';
import { addPrematriculas } from '../../../shared/middlewares/public.middleware';
import { useParams } from 'react-router-dom';
import { MdSaveAlt } from "react-icons/md";
import { GridReadyEvent } from 'ag-grid-community';
import { validateDNI } from '../../../shared/utils/functions/ValidateDNI';
import ModalConfirmacion from './ModalConfirmacion';
import { getGrupo } from '../../../shared/middlewares/public.middleware';
import { GrupoPrematriculaInt, ParticipanteInt, PrematriculaInt } from '../../../interfaces/MatriculasInt';

const myTheme = themeQuartz.withPart(colorSchemeDarkBlue);

ModuleRegistry.registerModules([AllCommunityModule]);

provideGlobalGridOptions({ theme: "legacy" });

const darkThemeOverrides = {
    baseTheme: 'ag-theme-quartz-dark',
    defaults: {
        headerBackground: '#1a1a1a',
        backgroundColor: '#242424',
        cellBorderColor: '#303030',
    },
    gridStyle: {
        '--ag-background-color': '#242424',
        '--ag-header-background-color': '#1a1a1a',
        '--ag-odd-row-background-color': '#2d2d2d',
        '--ag-header-foreground-color': '#fff',
        '--ag-foreground-color': '#fff',
        '--ag-border-color': '#303030',
        '--ag-row-hover-color': '#3d3d3d',
        '--ag-selected-row-background-color': '#4a4a4a',
        '--ag-input-focus-border-color': '#4f7fff',
        '--ag-range-selection-border-color': '#4f7fff',
        '--ag-input-border-color': '#303030',
    }
};

interface Props {
    grupoPrematricula: GrupoPrematriculaInt;
    rows: number;
    onMatriculasEnviadas: () => void;
}

export default function NuevasMatriculasTable({ grupoPrematricula, rows, onMatriculasEnviadas }: Props) {
    const { colorMode } = useColorMode();
    const toast = useToast();
    const { hash } = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [matriculasFinalizadas, setMatriculasFinalizadas] = useState(false);

    const filaVacia: ParticipanteInt = {
        nombre: '',
        apellidos: '',
        email: '',
        telefono: '',
        dni: '',
        nivelEstudios: '',
        categoriaProfesional: '',
        costeHora: 0,
        fijoDiscontinuo: false
    };

    const [rowData, setRowData] = useState<ParticipanteInt[]>([]);

    useEffect(() => {
        if (grupoPrematricula?.prematriculas?.prematriculas?.length > 0) {
            const prematriculas = grupoPrematricula.prematriculas.prematriculas.map((prematricula: PrematriculaInt) => ({
                nombre: prematricula.nombre || '',
                apellidos: prematricula.apellidos || '',
                email: prematricula.email || '',
                telefono: prematricula.telefono || '',
                dni: prematricula.dni || '',
                nivelEstudios: prematricula.nivelEstudios || '',
                categoriaProfesional: prematricula.categoriaProfesional || '',
                costeHora: prematricula.costeHora || 0,
                fijoDiscontinuo: prematricula.fijoDiscontinuo || false,
                curso: prematricula.curso || ''
            }));
            setRowData(prematriculas);
        } else {
            setRowData(Array(rows).fill(null).map(() => ({ ...filaVacia })));
        }
    }, [grupoPrematricula, rows]);

    useEffect(() => {
        const cargarDatosGuardados = async () => {
            try {
                const response = await getGrupo({ hashString: hash });
                if (response.data.status === 'finished') {
                    setMatriculasFinalizadas(true);
                } else if (response.data.status === 'pending' && response.data.prematriculas?.prematriculas) {
                    // Cargar los datos guardados
                    const prematriculas = response.data.prematriculas.prematriculas.map((prematricula: any) => ({
                        nombre: prematricula.nombre || '',
                        apellidos: prematricula.apellidos || '',
                        email: prematricula.email || '',
                        telefono: prematricula.telefono || '',
                        dni: prematricula.dni || '',
                        nivelEstudios: prematricula.nivelEstudios || '',
                        categoriaProfesional: prematricula.categoriaProfesional || '',
                        costeHora: prematricula.costeHora || 0,
                        fijoDiscontinuo: prematricula.fijoDiscontinuo || false,
                        curso: prematricula.curso || ''
                    }));
                    setRowData(prematriculas);
                }
            } catch (error) {
                console.error('Error al cargar datos:', error);
                toast({
                    title: "Error",
                    description: "Ha ocurrido un error al cargar los datos guardados",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        };

        cargarDatosGuardados();
    }, [hash, toast]);

    const [savedData, setSavedData] = useState<ParticipanteInt[]>([]);

    const guardarDatos = async () => {
        try {
            const data: { status: "pending" | "finished" | null, prematriculas: any[] } = {
                status: "pending" as const,
                prematriculas: rowData.map(alumno => ({
                    nombre: alumno.nombre || null,
                    apellidos: alumno.apellidos || null,
                    email: alumno.email || null,
                    telefono: alumno.telefono || null,
                    dni: alumno.dni || null,
                    curso: alumno.curso || null,
                    nivelEstudios: alumno.nivelEstudios || null,
                    categoriaProfesional: alumno.categoriaProfesional || null,
                    costeHora: alumno.costeHora || null,
                    fijoDiscontinuo: alumno.fijoDiscontinuo ?? false,
                }))
            };

            await addPrematriculas({
                data,
                hashString: hash || "",
            });

            setSavedData(rowData);
            toast({
                title: "Datos guardados",
                description: "Los datos han sido guardados temporalmente",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Ha ocurrido un error al guardar los datos",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const colDefs = useMemo<ColDef[]>(() =>
        grupoPrematricula?.fundae ? [
            {
                field: "dni",
                headerName: "NIF o NIE",
                editable: true,
                minWidth: 120,
                enableCellChangeFlash: true,
                onCellValueChanged: (params) => {
                    if (params.node) {
                        params.api.refreshCells({
                            force: true,
                            columns: [params.column.getId()],
                            rowNodes: [params.node]
                        });
                    }
                },
                cellStyle: (params) => {
                    if (!params.value) return null;
                    return validateDNI(params.value) ?
                        { backgroundColor: 'transparent' } :
                        { backgroundColor: '#ff000030' };
                }
            },
            {
                field: "nombre",
                headerName: "Nombre",
                editable: true,
                minWidth: 120,
            },
            {
                field: "apellidos",
                headerName: "Apellidos",
                editable: true,
                minWidth: 150,
            },
            {
                field: "email",
                headerName: "Email personal",
                editable: true,
                minWidth: 200,
                cellStyle: (params) => {
                    const value = params.value?.toString().trim();
                    if (!value) return null;
                    const tieneArroba = value.includes('@');
                    const tienePunto = value.split('@')[1]?.includes('.');
                    return (tieneArroba && tienePunto) ? { backgroundColor: 'transparent' } : { backgroundColor: '#ff000030' };
                }
            },
            {
                field: "telefono",
                headerName: "Teléfono personal",
                editable: true,
                minWidth: 140,
                cellStyle: (params) => {
                    if (!params.value) return null;
                    const phoneRegex = /^\d{9}$/;
                    return phoneRegex.test(params.value) ? { backgroundColor: 'transparent' } : { backgroundColor: '#ff000030' };
                }
            },
            {
                field: "nivelEstudios",
                headerName: "Nivel de estudios",
                editable: true,
                minWidth: 150,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: NivelEstudiosOptions.map(option => option.value)
                },
                valueFormatter: (params) => {
                    const option = NivelEstudiosOptions.find(opt => opt.value === params.value);
                    return option ? option.label : params.value;
                }
            },
            {
                field: "categoriaProfesional",
                headerName: "Cat. profesional",
                editable: true,
                minWidth: 150,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: CategoriaProfesionalOptions.map(option => option.value)
                },
                valueFormatter: (params) => {
                    const option = CategoriaProfesionalOptions.find(opt => opt.value === params.value);
                    return option ? option.label : params.value;
                }
            },
            {
                field: "costeHora",
                headerName: "Coste salarial/hora",
                type: 'numericColumn',
                editable: true,
                minWidth: 150,
                valueFormatter: (params) => {
                    if (!params.value) return '';
                    return `${params.value.toFixed(2)} €`;
                }
            },
            {
                field: "fijoDiscontinuo",
                headerName: "Fijo discontinuo",
                editable: true,
                minWidth: 130,
                cellEditor: 'agCheckboxCellEditor',
                cellRenderer: 'agCheckboxCellRenderer',
                cellStyle: { textAlign: 'center' }
            }
        ] : [
            {
                field: "dni",
                headerName: "Nif o Nie",
                editable: true,
                minWidth: 120,
                cellStyle: (params) => {
                    if (params.value) {
                        const dniRegex = /^[0-9]{8}[A-Z]$/;
                        const nieRegex = /^[XYZ][0-9]{7}[A-Z]$/;
                        return (dniRegex.test(params.value) || nieRegex.test(params.value)) ? { backgroundColor: 'transparent' } : { backgroundColor: '#ff000030' };
                    }
                    return null;
                }
            },
            {
                field: "nombre",
                headerName: "Nombre",
                editable: true,
                minWidth: 120,
                valueFormatter: (params) => params.value?.toLowerCase()
            },
            {
                field: "apellidos",
                headerName: "Apellidos",
                editable: true,
                minWidth: 150,
                valueFormatter: (params) => params.value?.toLowerCase()
            },
            {
                field: "email",
                headerName: "Email",
                editable: true,
                minWidth: 200,
                cellStyle: (params) => {
                    const value = params.value?.toString().trim();
                    if (!value) return null;

                    const tieneArroba = value.includes('@');
                    const tienePunto = value.split('@')[1]?.includes('.');

                    return (tieneArroba && tienePunto) ? { backgroundColor: 'transparent' } : { backgroundColor: '#ff000030' };
                }
            },
            {
                field: "telefono",
                headerName: "Teléfono",
                editable: true,
                minWidth: 120,
                cellStyle: (params) => {
                    if (!params.value) return null;
                    const phoneRegex = /^\d{9}$/;
                    return phoneRegex.test(params.value) ? { backgroundColor: 'transparent' } : { backgroundColor: '#ff000030' };
                }
            }
        ]
        , [grupoPrematricula?.fundae]);

    const getTableHeight = (rowCount: number) => {
        const rowHeight = 48;
        const headerHeight = 50;
        const minHeight = 400;
        const maxHeight = 800;

        const calculatedHeight = (rowCount * rowHeight) + headerHeight;
        return Math.min(Math.max(calculatedHeight, minHeight), maxHeight);
    };

    const enviarAlumnos = async () => {
        const alumnosValidos = rowData.every(alumno => {
            const camposBasicos =
                alumno.nombre &&
                alumno.apellidos &&
                alumno.email &&
                alumno.telefono &&
                alumno.dni;

            const camposFundae =
                alumno.nivelEstudios &&
                alumno.categoriaProfesional &&
                alumno.costeHora;

            if (!grupoPrematricula?.fundae) return camposBasicos;
            else return camposBasicos && camposFundae;
        });

        if (!alumnosValidos) {
            toast({
                title: "Error",
                description: "Por favor, complete todos los campos requeridos para cada alumno",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            const data: { status: "pending" | "finished" | null, prematriculas: any[] } = {
                status: "finished" as const,
                prematriculas: rowData.map(alumno => ({
                    nombre: alumno.nombre || null,
                    apellidos: alumno.apellidos || null,
                    email: alumno.email || null,
                    telefono: alumno.telefono || null,
                    dni: alumno.dni || null,
                    curso: alumno.curso || null,
                    nivelEstudios: alumno.nivelEstudios || null,
                    categoriaProfesional: alumno.categoriaProfesional || null,
                    costeHora: alumno.costeHora || null,
                    fijoDiscontinuo: alumno.fijoDiscontinuo ?? false,
                }))
            };

            await addPrematriculas({
                data,
                hashString: hash || "",
            });

            toast({
                title: "Éxito",
                description: "Las matrículas se han enviado correctamente",
                status: "success",
                duration: 5000,
                isClosable: true,
            });

            onClose();
            onMatriculasEnviadas();
        } catch (error) {
            onClose();
            toast({
                title: "Error",
                description: "Ha ocurrido un error al enviar los alumnos",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const onGridReady = (params: GridReadyEvent) => {
        setTimeout(() => {
            const firstCell = params.api.getDisplayedRowAtIndex(0);
            if (firstCell) {
                params.api.setFocusedCell(0, 'dni');
                params.api.startEditingCell({
                    rowIndex: 0,
                    colKey: 'dni'
                });
            }
        }, 100);
    };

    if (matriculasFinalizadas) {
        return (
            <Flex
                direction="column"
                align="center"
                justify="center"
                minHeight="400px"
                p={8}
                textAlign="center"
            >
                <Text fontSize="xl" fontWeight="bold" mb={4}>
                    Las matrículas ya han sido realizadas
                </Text>
                <Text>
                    Este grupo ya tiene las matrículas completadas y finalizadas.
                </Text>
            </Flex>
        );
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            width: '100%',
            height: '100%'
        }}>
            <div
                className={colorMode === 'dark' ? 'ag-theme-quartz-dark' : 'ag-theme-quartz'}
                style={{
                    width: '100%',
                    height: getTableHeight(rowData.length),
                    ...(colorMode === 'dark' ? darkThemeOverrides.gridStyle : {})
                }}
            >
                <AgGridReact
                    rowData={rowData}
                    columnDefs={colDefs}
                    defaultColDef={{
                        sortable: false,
                        filter: false,
                        resizable: true,
                        flex: 1,
                        suppressKeyboardEvent: (params) => {
                            const { event } = params;
                            return event.key === 'Enter' && !event.shiftKey;
                        }
                    }}
                    onGridReady={onGridReady}
                />
            </div>

            <Flex
                direction="column"
                alignItems="center"
            >
                <Text
                    fontSize="12px"
                    fontWeight="500"
                >
                    VALIDACIÓN: El teléfono debe tener 9 dígitos. El email debe tener un formato válido. El NIF o NIE debe tener 9 dígitos y una letra.
                </Text>
                <Text
                    fontSize="12px"
                    fontWeight="500"
                >
                    Fórmula coste hora: (Total devengado + coste empresa)/1800h (o las horas anuales que aparezcan en el convenio)
                    =coste hora alumno.
                </Text>
            </Flex>

            <HStack mt="20px" spacing={4} justify="center">
                <Button
                    px="40px"
                    bg="transparent"
                    color={colorMode === "dark" ? "white" : "main"}
                    onClick={guardarDatos}
                    leftIcon={<Icon color={colorMode === "dark" ? "white" : "main"} as={MdSaveAlt} />}
                    isDisabled={rowData.length === 0}
                    border="2px solid"
                    borderColor={colorMode === "dark" ? "white" : "main"}
                >
                    Guardar
                </Button>

                <Button
                    px="40px"
                    bg="main"
                    color="white"
                    onClick={onOpen}
                    isDisabled={rowData.length === 0}
                >
                    Enviar alumnos
                </Button>
            </HStack>

            <ModalConfirmacion
                isOpen={isOpen}
                onClose={onClose}
                enviarAlumnos={enviarAlumnos}
            />
        </div>
    )
}
