import { Box, Button, Flex, Icon, Progress, Skeleton, Text, useColorMode, useToast } from "@chakra-ui/react"
import { BiExit, BiNote } from "react-icons/bi"
import { CursosInt, LeccionInt } from "../../../interfaces/CursosInt";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { BsGrid, BsX } from "react-icons/bs";
import * as Yup from "yup";
import { FormTextEditor } from "../../../shared/components/FormElements/FormTextEditor";
import { Formik, Form as FormikForm } from "formik";
import { FormInput } from "@imaginagroup/bit-components.ui.form-input";
import { useState } from "react";
import { addNota } from "../../../shared/middlewares/notas.middleware";
import { toastNotify } from "../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../shared/utils/types/StatusEnumTypes";
import { UserRolEnum } from "../../../shared/utils/types/UserRolEnum";
import { useAuthContext } from "../../../shared/context/user.context";


interface Props {
    curso: CursosInt;
    progresoCurso: number;
    loading: boolean;
}

export const HeaderLeccion = ({
    curso,
    progresoCurso,
    loading
}: Props) => {
    const params = useParams()
    const { user } = useAuthContext()
    const navigate = useNavigate();
    const toast = useToast();
    const [notasMenuOpen, setNotasMenuOpen] = useState<boolean>(false);
    const [search] = useSearchParams()
    const addRedirect = search.has("grupo") ? `?grupo=${search.get("grupo")}` : ""
    const { colorMode } = useColorMode()

    const validationSchema = Yup.object().shape({
        titulo: Yup.string()
            .required("Debe establecer un titulo"),
        contenido: Yup.string()
            .required("Debe establecer un contenido")
    });

    const initialValues = {
        titulo: null,
        contenido: null
    };

    const handleSubmit = async (values: any) => {
        if (!params["*"]) return

        const nota = {
            titulo: values?.titulo,
            contenido: values?.contenido,
            leccioneSlug: params["*"]
        }

        addNota(nota)
            .then(() => {
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Nota creada")
                setNotasMenuOpen(false)
            })
            .catch(() => toastNotify(toast, StatusEnumTypes.ERROR, "Error al crear nota"))
    };

    return (
        <Flex
            w="100%"
            direction={{ base: "column", md: "row" }}
        >
            <Flex
                px={{ base: "0px", md: "40px" }}
                py={{ base: "0px", sm: "12px" }}
                alignItems="center"
                justifyContent="space-between"
                w="100%"
                gap={{ base: "5px", md: "20px" }}
            >
                <Button
                    h="fit-content"
                    w="fit-content"
                    color="font"
                    bg="transparent"
                    fontSize="16px"
                    fontWeight="700"
                    px={{ base: "10px", md: "16px" }}
                    py="10px"
                    leftIcon={
                        <Icon
                            as={BiExit}
                            boxSize="24px"
                            transform="rotate(-180deg)"
                        />
                    }
                    onClick={(e: React.MouseEvent) => {
                        e.stopPropagation()

                        navigate(`/aprendizaje/${curso?.slug}${addRedirect}`)
                    }}
                >
                    Salir del curso
                </Button>

                <Flex
                    display={{ base: "none", sm: "flex" }}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    gap="4px"
                    w="50%"
                >
                    {loading ?
                        <Skeleton
                            h="27px"
                            w="70%"
                            rounded="12px"
                        />
                        :
                        <Text
                            color="font"
                            fontSize="18px"
                            fontWeight="700"
                        >
                            Curso de {curso?.nombre}
                        </Text>

                    }

                    {user?.role?.nombre === UserRolEnum.ALUMNO ?
                        ((progresoCurso === undefined || progresoCurso === null || loading) ?
                            <Skeleton
                                h="19.5px"
                                w="100%"
                                rounded="12px"
                            />
                            :
                            <Flex
                                alignItems="center"
                                justifyContent="center"
                                w="100%"
                                gap="12px"
                            >
                                <Text
                                    color="main"
                                    fontSize="13px"
                                    fontWeight="500"
                                >
                                    {(progresoCurso > 100 ? 100 : progresoCurso).toFixed(0)}%
                                </Text>

                                <Progress
                                    w="100%"
                                    bg={"#E6E6EA"}
                                    rounded="full"
                                    max={100}
                                    h={"6px"}
                                    value={progresoCurso || 0}
                                    sx={{ '& > div': { background: "main" } }}
                                />
                            </Flex>
                        )
                        :
                        <Flex
                            h="20px"
                            w="100%"
                        />
                    }
                </Flex>

                {user?.role?.nombre === UserRolEnum.TUTOR ?
                    <Flex
                        w="157px"
                    />
                    :
                    <Flex
                        pos="relative"
                    >
                        <Button
                            h="fit-content"
                            w="fit-content"
                            color="font"
                            bg="transparent"
                            fontSize="16px"
                            fontWeight="700"
                            px={{ base: "10px", md: "16px" }}
                            py="10px"
                            leftIcon={
                                <Icon
                                    as={BiNote}
                                    boxSize="24px"
                                />
                            }
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation()

                                setNotasMenuOpen((prev: boolean) => !prev)
                            }}
                            isDisabled={!params["*"]}
                        >
                            Nueva Nota
                        </Button>

                        <Formik
                            onSubmit={(values, { resetForm }) => {
                                handleSubmit(values);
                                resetForm();
                            }}
                            enableReinitialize
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                        >
                            {(formik) => {
                                const { handleSubmit } = formik;


                                return (
                                    <FormikForm
                                        onSubmit={handleSubmit}
                                    >
                                        <Flex
                                            transition="all 0.3s ease-in-out"
                                            maxHeight={notasMenuOpen ? '600px' : '0'}
                                            maxWidth={notasMenuOpen ? '650px' : '0'}
                                            opacity={notasMenuOpen ? 1 : 0}
                                            overflow="hidden"
                                            zIndex="999"
                                            w={{ base: "calc(100vw - 15px)", sm: "650px" }}
                                            bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                                            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                                            borderRadius="md"
                                            position="absolute"
                                            top="100%"
                                            right={{ base: "5%", sm: "25%" }}
                                            mt="10px"
                                            rounded="14px"
                                            direction="column"
                                        >
                                            <Flex
                                                bg="transparent"
                                                w="100%"
                                                roundedTopLeft="14px"
                                                roundedTopRight="14px"
                                                py="12px"
                                                px="20px"
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                <Flex
                                                    alignItems="center"
                                                    gap="12px"
                                                >
                                                    <Icon
                                                        as={BsGrid}
                                                        boxSize="24px"
                                                        color="purewhite"
                                                    />

                                                    <Text
                                                        color="purewhite"
                                                        fontSize="14px"
                                                        fontWeight="700"
                                                        letterSpacing="-0.14px"
                                                    >
                                                        Notas
                                                    </Text>
                                                </Flex>

                                                <Icon
                                                    as={BsX}
                                                    boxSize="32px"
                                                    color="purewhite"
                                                    cursor="pointer"
                                                    onClick={(e: React.MouseEvent) => {
                                                        e.stopPropagation()

                                                        setNotasMenuOpen(false)
                                                    }}
                                                />
                                            </Flex>

                                            <Flex
                                                py="15px"
                                                px="30px"
                                                alignItems="center"
                                                justifyContent="end"
                                                gap="20px"
                                            >
                                                <Button
                                                    py="10px"
                                                    px="16px"
                                                    fontSize="16px"
                                                    fontWeight="700"
                                                    lineHeight="22px"
                                                    letterSpacing="-0.408px"
                                                    rounded="10px"
                                                    color="purewhite"
                                                    bg="main"
                                                    type="submit"
                                                >
                                                    Guardar
                                                </Button>
                                            </Flex>

                                            <Box h="1px" bg={colorMode === "dark" ? "border_variant" : "#E6E6EA"} w="100%" />

                                            <Flex
                                                p="30px"
                                                direction="column"
                                            >
                                                <FormInput
                                                    name='titulo'
                                                    placeholder='Introduce un título para la nota'
                                                    ui={{
                                                        styleInput: {
                                                            border: "none",
                                                            borderBottom: "1px solid",
                                                            borderColor: colorMode === "dark" ? "border_variant" : "#E6E6EA",
                                                            rounded: "none",
                                                            padding: "0px",
                                                            outline: "none",
                                                            boxShadow: "none",
                                                            fontSize: "18px",
                                                            fontWeight: "600",
                                                            bg: colorMode === "dark" ? "bg_dark_light" : "purewhite",
                                                            color: colorMode === "dark" ? "purewhite" : "font"
                                                        },
                                                        stylePlaceholder: {
                                                            fontSize: "18px",
                                                            fontWeight: "600"
                                                        },
                                                        focusInput: {
                                                            outline: "none",
                                                            boxShadow: "none",
                                                            borderColor: "main"
                                                        }
                                                    }}
                                                />

                                                <FormTextEditor
                                                    height="200px"
                                                    name='contenido'
                                                    onChange={formik.setFieldValue}
                                                    placeholder="Empieza a escribir tu nota"
                                                    styles={{
                                                        border: "none",
                                                        padding: "0",
                                                        fontSize: "15px",
                                                        fontWeight: "400",
                                                        lineHeight: "150%",
                                                        minHeight: "300px"
                                                    }}
                                                />
                                            </Flex>

                                        </Flex>
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    </Flex>
                }
            </Flex>

            {/*Progress Bar  Curso for Mobile */}

            <Flex
                display={{ base: "flex", sm: "none" }}
                direction="column"
                alignItems="center"
                justifyContent="center"
                gap="4px"
                w="100%"
                px="10px"
                mt="0px"
            >
                {loading ?
                    <Skeleton
                        h="27px"
                        w="70%"
                        rounded="12px"
                    />
                    :
                    <Text
                        color="font"
                        fontSize="18px"
                        fontWeight="700"
                    >
                        Curso de {curso?.nombre}
                    </Text>

                }

                {user?.role?.nombre === UserRolEnum.ALUMNO ?
                    ((progresoCurso === undefined || progresoCurso === null || loading) ?
                        <Skeleton
                            h="19.5px"
                            w="100%"
                            rounded="12px"
                        />
                        :
                        <Flex
                            alignItems="center"
                            justifyContent="center"
                            w="100%"
                            gap="12px"
                        >
                            <Text
                                color="main"
                                fontSize="13px"
                                fontWeight="500"
                            >
                                {(progresoCurso > 100 ? 100 : progresoCurso).toFixed(0)}%
                            </Text>

                            <Progress
                                w="100%"
                                bg={"#E6E6EA"}
                                rounded="full"
                                max={100}
                                h={"6px"}
                                value={progresoCurso || 0}
                                sx={{ '& > div': { background: "main" } }}
                            />
                        </Flex>
                    )
                    :
                    <Flex
                        h="20px"
                        w="100%"
                    />
                }
            </Flex>
        </Flex>
    )
}