import { Button, Flex, Icon, useDisclosure } from '@chakra-ui/react'
import { BiPlus } from 'react-icons/bi'
import ModalPregunta from './ModalPregunta'
import SelectorCurso from '../../Entregables/components/SelectorCurso'
import { MatriculasInt } from '../../../interfaces/MatriculasInt';

interface Props {
    onSelectMatricula: (matricula: MatriculasInt) => void;
    matriculas: {
        data: MatriculasInt[]
    };
    selectedMatricula: MatriculasInt | undefined;
    Refresh: () => void;
}

export default function TopForo({
    onSelectMatricula,
    matriculas,
    selectedMatricula,
    Refresh
}: Props) {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <Flex
            alignItems="center"
            justifyContent="space-between"
            gap="20px"
            flexDirection={{base: "column", sm: "row"}}
        >
            <SelectorCurso
                onSelectMatricula={onSelectMatricula}
                matriculas={matriculas?.data}
                selectedMatricula={selectedMatricula}
                hideBorder
            />

            <Button
                leftIcon={
                    <Icon
                        as={BiPlus}
                        boxSize="22px"
                    />
                }
                color="purewhite"
                fontSize="13px"
                fontWeight="700"
                lineHeight="16px"
                letterSpacing="-0.078"
                h="fit-content"
                w="100%"
                maxW={{base: "100%", sm: "150px"}}
                px="10px"
                py="10px"
                bg="main"
                rounded="10px"
                onClick={onOpen}
            >
                Nueva Pregunta
            </Button>

            <ModalPregunta
                isOpen={isOpen}
                onClose={onClose}
                matriculas={matriculas}
                Refresh={Refresh}
            />
        </Flex>
    )
}
