import { useEffect, useState, Dispatch, SetStateAction } from "react";
import { LeccionInt } from "../../../../interfaces/CursosInt";
import { MatriculasInt } from "../../../../interfaces/MatriculasInt";
import { EntregableEstadoEnum } from "../../../../shared/utils/types/EntregableEstadoEnum";
import { categorizarSubtipoLeccion, categorizarTipoLeccion } from "../../../../shared/utils/functions/categorizarLeccion";
import { PDFViewer } from "../../components/PreviewItems/PDFViewer";
import { Video } from "../../components/PreviewItems/Video";
import { IframeViewer } from "../../components/PreviewItems/IframeViewer";
import { ImageViewer } from "../../components/PreviewItems/ImageViewer";
import { MarkdownViewer } from "../../components/PreviewItems/Markdown";
import { UrlViewer } from "../../components/PreviewItems/UrlViewer";
import { Entregable } from "../../components/Entregable";
import { ComponenteLeccionEnum } from "../../../../shared/utils/types/LeccionTipoEnum";
import { Flex, Skeleton } from "@chakra-ui/react";
import { SesionesViewer } from "../../components/PreviewItems/SesionesViewer";
import { Autocorregible } from "../../components/PreviewItems/Autocorregible";
import { CodeiumEditor } from "@codeium/react-code-editor";

interface Props {
    leccion: LeccionInt | undefined;
    onLeccionStarted: (e?: any) => any;
    setEstadoEntregable: Dispatch<SetStateAction<EntregableEstadoEnum>>;
    endPrev: boolean;
    matriculaActual: MatriculasInt | undefined;
    loading: boolean;
    onNextLeccion: () => void;
}

export const PreviewLeccion = ({
    leccion,
    onLeccionStarted,
    setEstadoEntregable,
    endPrev,
    matriculaActual,
    loading,
    onNextLeccion
}: Props) => {
    const [componentType, setComponentType] = useState<ComponenteLeccionEnum>()
    const [componentSubtype, setComponentSubtype] = useState<ComponenteLeccionEnum>()

    useEffect(() => {
        const componenteTipo = categorizarTipoLeccion(leccion)
        setComponentType(componenteTipo)

        if (componenteTipo === ComponenteLeccionEnum.ENTREGABLE) {
            const componenteSubtipo = categorizarSubtipoLeccion(leccion)

            setComponentSubtype(componenteSubtipo)
        }

    }, [leccion])

    return (
        (!componentType || loading) ?
            <Skeleton
                w="100%"
                h="calc(100vh - 193px)"
                rounded="20px"
            />
            :
            <>
                {(componentType === ComponenteLeccionEnum.PDF_VIEWER && leccion?.contenido) &&
                    <PDFViewer
                        leccion={leccion}
                    />
                }

                {(componentType === ComponenteLeccionEnum.VIDEO && leccion?.contenido) &&
                    <Video
                        leccion={leccion}
                        onLeccionStarted={onLeccionStarted}
                    />
                }

                {(componentType === ComponenteLeccionEnum.IFRAME && leccion?.contenido) &&
                    <IframeViewer
                        leccion={leccion}
                    />
                }

                {(componentType === ComponenteLeccionEnum.IMAGEN && leccion?.contenido) &&
                    <ImageViewer
                        leccion={leccion}
                    />
                }

                {(componentType === ComponenteLeccionEnum.MARKDOWN_PREVIEW && leccion?.contenido) &&
                    <MarkdownViewer
                        leccion={leccion}
                    />
                }

                {(componentType === ComponenteLeccionEnum.LINK && leccion?.contenido) &&
                    <UrlViewer
                        leccion={leccion}
                    />
                }

                {(componentType === ComponenteLeccionEnum.SESION_VIVO && leccion?.contenido) &&
                    <SesionesViewer
                        leccion={leccion}
                    />
                }

                {componentType === ComponenteLeccionEnum.ENTREGABLE && (
                    leccion?.AI === null ?
                        <Entregable
                            leccion={leccion}
                            setEstadoEntregable={setEstadoEntregable}
                            endPrev={endPrev}
                            matriculaActual={matriculaActual}
                            componentSubtype={componentSubtype}
                            onNextLeccion={onNextLeccion}
                        />
                        :
                        <Autocorregible
                            leccion={leccion}
                            setEstadoEntregable={setEstadoEntregable}
                            endPrev={endPrev}
                            matriculaActual={matriculaActual}
                            componentSubtype={componentSubtype}
                            onNextLeccion={onNextLeccion}
                        />
                )
                }
            </>
    )
} 