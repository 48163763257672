import { Flex, Grid, Skeleton, useColorMode, useToast } from '@chakra-ui/react'
import { Topbar } from '../../../../shared/components/Topbar/Topbar'
import TopForo from '../../components/TopForo'
import Filter from '../../components/Filter'
import { useData } from '../../../../shared/hooks/useData'
import { EndpointTypes } from '../../../../shared/utils/types/EndpointTypes'
import { PreguntaForoInt } from '../../../../interfaces/ForoInt'
import CardPregunta from '../../components/CardPregunta'
import { MatriculasInt } from '../../../../interfaces/MatriculasInt'
import { UserRolEnum } from '../../../../shared/utils/types/UserRolEnum'
import { useAuthContext } from '../../../../shared/context/user.context'
import { useEffect, useState } from 'react'
import { handleUnauthorized } from '../../../../shared/utils/functions/handleErrors'
import { useNavigate } from 'react-router-dom'
import { CardSinInformacion, TypeCardSinInformacion } from '../../../../shared/components/Cards/CardSinInformacion'

export default function Foro() {
    const { user, logout } = useAuthContext()
    const toast = useToast()
    const navigate = useNavigate()
    const { data: matriculas, error, errorsList, status } = useData({
        endpoint: EndpointTypes.MATRICULAS_ALUMNOS,
        ignoreRequest: !user?.auth || user?.role?.nombre !== UserRolEnum.ALUMNO,
        query: {
            limit: 100
        }
    })

    // const matriculas = { data: data?.data?.filter((matricula: MatriculasInt) => matricula?.meta?.tutorizado) }

    const [selectedMatricula, setSelectedMatricula] = useState<MatriculasInt>();

    const [query, setQuery] = useState<{
        sortBy?: string;
        order?: string;
        titulo?: string;
    }>({
        sortBy: 'createdAt',
        order: 'desc',
    })

    const { data: preguntas, Refresh, loading } = useData({
        endpoint: EndpointTypes.PREGUNTAS,
        query:
            selectedMatricula && selectedMatricula?.grupoId ?
                {
                    grupo: selectedMatricula?.grupoId,
                    ...query
                }
                :
                {
                    ...query
                }
    });

    useEffect(() => {
        if (error) {
            handleUnauthorized({
                logout: logout,
                navigate: navigate,
                toast: toast
            })
        }
    }, [error])


    return (
        <Topbar
            title='Foro'
            searchBar
        >
            <Flex
                direction="column"
                px="10px"
                py="20px"
                w="100%"
            >
                <Flex
                    w="full"
                    gap="30px"
                    px={{base: "0px", md: "10px"}}
                    pb={{base: "20px", md:"0px"}}
                    direction="column"
                    mt={user?.role?.nombre === UserRolEnum?.TUTOR ? "20px" : "0"}
                >
                    {user?.role?.nombre === UserRolEnum?.ALUMNO &&
                        <TopForo
                            matriculas={matriculas}
                            onSelectMatricula={setSelectedMatricula}
                            selectedMatricula={selectedMatricula}
                            Refresh={Refresh}
                        />
                    }

                    <Filter
                        query={query}
                        setQuery={setQuery}
                    />
                </Flex>

                {loading ?
                    <Grid
                        templateColumns={{
                            xl: "repeat(4, 1fr)",
                            md: "repeat(3, 1fr)",
                            sm: "repeat(2, 1fr)",
                            base: "repeat(1, 1fr)"
                        }}
                        gap="25px"
                        w="full"
                        css={{
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                            'scrollbarWidth': 'none',
                            '-ms-overflow-style': 'none',
                            scrollBehavior: 'smooth',
                        }}
                        overflow="auto"
                        pt="35px"
                        pb="40px"
                        px="10px"
                    >
                        {Array.from({ length: 8 }, (_, i) => i).map((_, index: number) => (
                            <Skeleton
                                key={index}
                                h="215px"
                                w="100%"
                                maxW="385px"
                                rounded="10px"
                            />
                        ))}
                    </Grid>
                    : preguntas?.meta?.total === 0 ?
                        <Flex
                            mt="30px"
                            rounded="20px"
                        >
                            <CardSinInformacion
                                type={TypeCardSinInformacion.FORO}
                                fullRounded
                                withBorder
                            />
                        </Flex>
                        :
                        <Grid
                            templateColumns={{
                                xl: "repeat(4, 1fr)",
                                md: "repeat(3, 1fr)",
                                sm: "repeat(2, 1fr)",
                                base: "repeat(1, 1fr)"
                            }}
                            gap="25px"
                            w="full"
                            css={{
                                '&::-webkit-scrollbar': {
                                    display: 'none',
                                },
                                'scrollbarWidth': 'none',
                                '-ms-overflow-style': 'none',
                                scrollBehavior: 'smooth',
                            }}
                            overflow="auto"
                            pt={{base: "20px", sm:"35px"}}
                            pb={{base: "0px", sm:"40px"}}
                            px={{base: "0px", md: "10px"}}
                        >
                            {preguntas?.data?.map((pregunta: PreguntaForoInt, index: number) => (
                                <CardPregunta
                                    key={index}
                                    pregunta={pregunta}
                                />

                            ))}
                        </Grid>
                }
            </Flex>
        </Topbar>

    )
}
